<template>
    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`"
                    data-bs-toggle="tab">Language 
                    <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
            
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`"
                    data-bs-toggle="tab">General Settings 
                    <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
                           
                
                </a>
            </li>
            <li class="nav-item">

                <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme
                    Settings {{ (this.error_theme_tab) ? 'error' : '' }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`"
                    data-bs-toggle="tab">Interpretation Settings {{ (this.error_interpretation_tab) ? 'error' : '' }}</a>
            </li>
        </ul>
        <div class="tab-content">
            <div :id="`language-settings`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Language *</label>
                            <v-select name="language" placeholder="Select language" label="language"
                                @option:selected="updateLaguage" :options="languages" />
                            <ValidationMessages :element="v$.item.translations" />
                        </div>
                        <div class="col-lg-6">
                            <label>Title *</label>

                            <div class="kt-input-icon">
                                <input type="text" class="form-control" name="language_title" maxlength="200"
                                    placeholder="Enter language title" :value="this.selectedLanguageTitle"
                                    @input="updateLanguageTitle" />
                            </div>
                            <ValidationMessages :element="v$.item.translations" />

                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label>Description *</label>
                                <div class="kt-input-icon">
                                    <textarea rows="3" class="form-control" name="language_description"
                                        placeholder="Enter language description" :value="this.selectedLanguageDescirption"
                                        @input="updateLanguageDescription"></textarea>
                                </div>
                                <ValidationMessages :element="v$.item.translations" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :id="`general-settings`" class="tab-pane">
                <div class="snippet general-event-config-snippet">
                    <div class="form-group row">
                        <div class="col-lg-10">
                            <label>Speaker *</label>
                            <v-select placeholder="Select Speaker" label="name" :options="speakers" track-by="id"
                                :modelValue="item.speaker" :reduce="(option) => option.id"
                                @option:selected="updateSpeaker" />
                            <ValidationMessages :element="v$.item.speaker" />
                        </div>
                        <div class="col-lg-2 newspeaker">
                            <v-dialog v-model="dialog" width="auto">
                                <template v-slot:activator="{ props }">
                                    <v-btn color="primary" v-bind="props">
                                        Add Speaker
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <v-form v-model="valid">
                                            <v-container>
                                                <v-row>
                                                    <h4 class="ml-auto mr-auto text-uppercase">{{ createSpeakerBtnTxt }}
                                                    </h4>
                                                    <div class="col-lg-12 mb-2">
                                                        <label>Salutation *</label>
                                                        <v-select name="salutaion" class="" placeholder="Select salutaion"
                                                            label="salutaion" @option:selected="updateNewSpeakerSalutation"
                                                            :options="salutation" v-model="selected_salutation" required />
                                                    </div>

                                                    <div class="col-lg-12 mb-2">
                                                        <label>Email *</label>
                                                        <div class="kt-input-icon">
                                                            <input type="text" class="form-control" name="email"
                                                                maxlength="200" placeholder="Enter Email"
                                                                @input="updateNewSpeakerEmail" />
                                                        </div>
                                                        <span v-if="newEmailExists == true" class="mini_txt">User Already
                                                            Exist. Do you want to add this user in event?</span>
                                                    </div>

                                                    <div class="col-lg-12 mb-2">
                                                        <label>First Name *</label>
                                                        <div class="kt-input-icon">
                                                            <input type="text" class="form-control" name="first_name"
                                                                maxlength="200" placeholder="Enter First Name"
                                                                :value="newSpeaker.first_name"
                                                                @input="updateNewSpeakerFirstName" />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12 mb-2">
                                                        <label>Last Name *</label>
                                                        <div class="kt-input-icon">
                                                            <input type="text" class="form-control" name="last_name"
                                                                maxlength="200" placeholder="Enter Last Name"
                                                                :value="newSpeaker.last_name"
                                                                @input="updateNewSpeakerLastName" />
                                                        </div>
                                                    </div>

                                                </v-row>
                                            </v-container>

                                        </v-form>

                                    </v-card-text>
                                    <v-card-actions class="wrapp">
                                        <!-- <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn> -->
                                        <v-btn type="submit" @click="submitSpeakerForm" class="mt-2 add_spk_btn">{{
                                            createSpeakerBtnTxt }}</v-btn>

                                        <v-btn text @click="dialog = false" class="add_spk_btn mt-2 orangebtn">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>


                            </v-dialog>

                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Start Time *</label>
                            <div class="input-daterange input-group">
                                <span class="input-group-text">
                                    <i class="fas fa-calendar-alt"></i>
                                </span>
                             


                                    <input 
                              :min="getEventDatetime(event.start_date)" 
                              :max="getEventDatetime(event.end_date)" 
                              :value="getEventDatetime(item.start_time)" 
                              type="datetime-local" class="form-control" 
                              name="start_date"  @change="updateStartTime" />
                          
                              
                                <span class="input-group-text rounded-0">from</span>
                            </div>
                            <ValidationMessages :element="v$.item.start_time" />

                        </div>
                        <div class="col-lg-6">
                            <label class>End Time *</label>
                            <div class="input-daterange input-group">
                                <span class="input-group-text">
                                    <i class="fas fa-calendar-alt"></i>
                                </span>
                          
                                    <input 
                              :min="getEventDatetime(event.start_date)" 
                              :max="getEventDatetime(event.end_date)" 
                              :value="getEventDatetime(item.end_time)" 
                              type="datetime-local" class="form-control" 
                              name="start_date"  @change="updateEndTime" />
                              
                                <span class="input-group-text rounded-0">to</span>
                            </div>
                            <ValidationMessages :element="v$.item.end_time" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Exhibitor</label>
                          <!--  <v-select :clearable="true"  placeholder="Select Exhibitor" label="label"
                                :options="exhibitorsList" track-by="id" @option:selected="updateExhibitor" />-->

                                <select class="form-control" @change="updateExhibitor">
                                    <option value="" selected>Select</option>
                                    <option v-for="option in exhibitorsList" :value="option.id" :key="option.id"
                                    :selected="(item.id == option.id)">
                                    {{ option.title }}
                                    </option>
                                </select>
                       


                            <span class="error">{{ err_exhibitor }}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Enable Rating ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx"
                                    class="custom-control-input" @change="UpdateIsRating" />

                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Screen Sharing ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">

                                <input-switch name="is_screen_sharing" :value="item.is_screen_sharing" id="customSwitchesEx"
                                    class="custom-control-input" @change="UpdateIsScreenSharing" />

                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Schedule Status ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="is_schedule_status" :value="item.is_schedule_status"
                                    id="customSwitchesEx" class="custom-control-input" @change="UpdateScheduleStatus" />

                            </div>
                        </div>

                    </div>

                    <div class="form-group row">

                        <div class="col-lg-3">
                            <label>Session Open Or Closed ?</label>
                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Session open will allow all attendees to join and Session closed is for only allowed attendees.
                                      </template>
                                    </VPopper>
                            <br />
                            <div class="switch switch-sm switch-primcontrollanguage_ary">
                                <input-switch name="session_open" :value="item.session_open" id="customSwitchesEx"
                                    class="custom-control-input" @change="UpdateSessionOpen" />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Max. No. Attendees</label>
                            <div class="kt-input-icon">
                                <select :disabled="session_open_disabled == false" class="form-control"
                                    @change="UpdateMaxNoAttendees">
                                    <option disabled selected>Select max attendees</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Attendees</label>
                            <div class="kt-input-icon">
                                <v-select :disabled="session_open_disabled == false" placeholder="Select attendees"
                                    label="name" :options="speakers" track-by="id" :reduce="(option) => option.id"
                                    @option:selected="UpdateAttendees" @option:deselected="RemoveAttendees"
                                    :selectable="() => item.attendees.length < item.max_no_attendees"
                                     multiple
                                    persistent-hint />
                            </div>
                        </div>


                    </div>

                    <div class="form-group row">

                        <div class="col-lg-3">
                            <label>Live Streaming ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="is_live_streaming" :value="item.is_live_streaming" id="customSwitchesEx"
                                    class="custom-control-input" @change="UpdateIsLiveStreaming" />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Stream URL </label>

                            <div class="kt-input-icon">
                                <input type="text" :disabled="item.is_live_streaming?false:true" class="form-control"
                                    name="stream_url" maxlength="200" placeholder="Enter Stream URL"
                                    :value="item.stream_url" @input="updateStreamUrl" />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Restric User to Join before session time ?</label>
                            <br />
                            <div class="switch switch-sm switch-primcontrollanguage_ary">
                                <input-switch name="is_restricted" :value="item.is_restricted" id="customSwitchesEx"
                                    class="custom-control-input" @change="UpdateIsRestricted" />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <label>Minutes</label>
                            <div class="kt-input-icon">
                                <input type="number" :disabled="restric_user_disabled == true" class="form-control"
                                    name="title" min="0" maxlength="200" placeholder="Enter Minutes" :value="item.minutes"
                                    @input="updateMinutes" />
                            </div>
                        </div>

                    </div>

                    <br />

                    <div class="form-group row">
                        <div class="col-lg-6">
                            <DropFiles :file="item.presentation_video" accept="video/mp4" :module_id="this.moduleId"
                                module_type="round-table" :dimmention="null" @onUploaded="updateVideo"
                                @onRemoved="removeVideo" asset_type="presentation_video" @onError="videoErrCb">
                                <template #header>
                                    <label>Presentation Video (Max size 500MB and format mp4.)</label>
                                </template>
                                <template #tooltip>
                                    <VTooltip style="display:inline-block">
                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                        <template #popper>
                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                        </template>
                                    </VTooltip>
                                </template>
                            </DropFiles>
                            <span class="error">{{ err_video }}</span>
                        </div>

                        <div class="col-lg-6">
                            <DropFiles :file="item.presentation_documents" accept="application/pdf"
                                :module_id="this.moduleId" module_type="round-table" :dimmention="null"
                                @onUploaded="updateDocument" @onRemoved="removeDocument" asset_type="presentation_documents"
                                @onError="documentsErrCb">
                                <template #header>
                                    <label>Presentation Document (Max size 30MB and format pdf.)</label>
                                </template>
                                <template #tooltip>
                                    <VTooltip style="display:inline-block">
                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                        <template #popper>
                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                        </template>
                                    </VTooltip>
                                </template>
                            </DropFiles>
                            <span class="error">{{ err_documents }}</span>
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6">
                            <DropFiles :file="item.screen_logo" accept="image/jpeg" :module_id="this.moduleId"
                                module_type="round-table" dimmention="455X256" @onUploaded="updateScreenLogo"
                                @onRemoved="removeScreenLogo" asset_type="screen_logo" @onError="screenLogoErrCb">
                                <template #header>
                                    <label>Screen Logo (Size should be 455x256 pixel and format jpg.)</label>
                                </template>
                                <template #tooltip>
                                    <VTooltip style="display:inline-block">
                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                        <template #popper>
                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                        </template>
                                    </VTooltip>
                                </template>
                            </DropFiles>
                            <ValidationMessages :element="v$.item.screen_logo" />
                            <span class="error">{{ err_screen_logo }}</span>
                        </div>

                        <div class="col-lg-6">
                            <DropFiles :file="item.sesssion_theme_logo" accept="image/jpeg" :module_id="this.moduleId"
                                module_type="round-table" dimmention="512X512" @onUploaded="updateSesssionLogo"
                                @onRemoved="removeSesssionLogo" asset_type="sesssion_theme_logo"
                                @onError="sesssionLogoErrCb">
                                <template #header>
                                    <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                                </template>
                                <template #tooltip>
                                    <VTooltip style="display:inline-block">
                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                        <template #popper>
                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                        </template>
                                    </VTooltip>
                                </template>
                            </DropFiles>
                            <span class="error">{{ err_sesssion_logo }}</span>
                        </div>

                    </div>

                </div>

            </div>
            <div :id="`theme-settings`" class="tab-pane">

                <div class="row">

                    <div class="tabs tabs-vertical tabs-left">

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item active" role="presentation">
                                <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`"
                                    data-bs-toggle="tab">Theme Contents</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div :id="`theme_content`" class="tab-pane active show" role="tabpanel">

                                <div class="snippet general-event-config-snippet">

                                    <div class="form-group row">
                                        <div class="col-lg-4">
                                            <label>Background Color or Picture ?</label>
                                            <br />
                                            <div class="switch switch-sm switch-primary">
                                                <input-switch name="is_background_picture"
                                                    :value="item.is_background_picture" id="customSwitchesEx"
                                                    class="custom-control-input" @change="UpdateIsBackgroundPicture" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="item.is_background_picture">
                                        <div class="col-lg-12">
                                            <DropFiles :file="item.background_picture" accept="image/jpeg"
                                                :module_id="this.moduleId" module_type="round-table" dimmention="1920X470"
                                                @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture"
                                                asset_type="background_picture" @onError="BackgroundPictureErrCb">
                                                <template #header>
                                                    <label>Background Picture (Size should be 1920x470 pixel and format
                                                        JPG.) </label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/pd_bg.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_background_picture }}</span>
                                        </div>
                                    </div>

                                    <div class="form-group row backgroundcolor_option" v-else>
                                        <div class="col-lg-6">
                                            <label>Background Color</label>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="background_color" :value="item.background_color" @input="updateBackgroundColor" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Bottom Background Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_color_bottom" :value="item.background_bottom_color" @input="updateBackgroundColorBottom" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Notes Arc Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="notes_arc_color" :value="item.notes_arc_color" @input="updateNotesArcColor" />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <DropFiles :file="item.banner" accept="image/jpeg" :module_id="this.moduleId"
                                                module_type="round-table" dimmention="552X178"
                                                @onUploaded="updateBannerPicutre" @onRemoved="removeBannerPicutre"
                                                asset_type="banner" @onError="bannerPicutreErrCb">
                                                <template #header>
                                                    <label>Banner (Size should be 552x178 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VTooltip style="display:inline-block">
                                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                        <template #popper>
                                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                        </template>
                                                    </VTooltip>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_banner_picutre }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div :id="`interpretify-settings`" class="tab-pane">

                <div class="snippet general-event-config-snippet">

                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label>Enable "Interprefy" Interpretation</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="interprefy" :value="item.interprefy" id="customSwitchesEx"
                                    class="custom-control-input" @change="updateInterprefy" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label>Interprefy token</label>
                            <div class="kt-input-icon">
                                <input type="text" class="form-control" name="title" maxlength="200"
                                    placeholder="Enter token" :disabled="interprefy_disabled == true"
                                    @input="updateInterprefyToken" />
                            </div>
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label>Enable "AWS" Interpretation</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="interprefy" :value="item.aws_interpretation" id="customSwitchesEx"
                                    class="custom-control-input" @change="updateAwsInterpretation" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label>Enable "Speech to Speech"</label>
                            <div class="kt-input-icon">
                                <input-switch name="speech_to_speech" :value="item.aws_enable_speech_to_speech"
                                    id="enableSpeechToSpeech" class="custom-control-input"
                                    @change="updateAwsEnableSpeechToSpeech" :styleDisable="this.aws_interprefy_disabled" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label>Enable "Speech to Text"</label>
                            <div class="kt-input-icon">
                                <input-switch name="speech_to_text" :value="item.aws_enable_speech_to_text"
                                    id="enableSpeechToText" class="custom-control-input"
                                    @change="updateAwsEnableSpeechToText" :styleDisable="this.aws_interprefy_disabled" />
                            </div>
                        </div>

                    </div>

                    <div class="form-group row">

                        <div class="col-lg-4">
                            <label>Enable "Chat" Interpretation</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                                <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx"
                                    class="custom-control-input" @change="updateChat" />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="card-body text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm(user)">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      
            </div>

        </div>


    </div>
    <FlashMessage group="lp_create" />
</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {
    mapGetters,
    mapActions
} from 'vuex';
import $ from 'jquery';
import {
    upload
} from "@/services/FileUploader";
import {
    v4 as uuidv4
} from 'uuid';
import _ from "lodash";
import moment from 'moment';
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'
const maxVideoFileSize = 524288000; // Max file size
const maxPdfFileSize =  32096088;  // Max file size

export default {
    data() {
        return {
            moduleId: ObjectID(),
            dialog: false,
            translation: [],
            languageKey: '',
            languageTitle: '',
            languageDescription: '',
            stream_disabled: true,
            restric_user_disabled: true,
            session_open_disabled: true,
            interprefy_disabled: true,
            aws_interprefy_disabled: true,
            chat_disabled: true,
            salutation: ['Mr', 'Ms', 'Mrs'],
            selected_salutation: null,
            error_translation_tab: false,
            error_general_tab: false,
            error_theme_tab: false,
            error_interpretation_tab: false,
            err_translations: null,
            err_speaker: null,
            err_exhibitor: null,
            err_start_time: null,
            err_end_time: null,
            err_is_restricted: false,
            err_minutes: null,
            err_is_rating: null,
            err_is_screen_sharing: null,
            err_is_live_streaming: null,
            err_stream_url: null,
            err_schedule_status: null,
            err_video: null,
            err_documents: null,
            err_screen_logo: null,
            err_sesssion_logo: null,
            err_is_background_picture: null,
            err_background_picture: null,
            err_background_color: null,
            err_background_color_bottom: null,
            err_banner_picutre: null,
            err_interprefy: null,
            err_interprefy_token: null,
            err_aws_interpretation: null,
            err_aws_enable_speech_to_speech: null,
            err_aws_enable_speech_to_text: null,
            err_chat: null,
            exhibitorsList: [],

        }

    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            item: {

                translations: {
                    required
                },

                speaker: {
                    required,
                },
                start_time: {
                    required,
                },
                end_time: {
                    required,
                },
                screen_logo: {
                    required,
                }
            }
        };
    },

    props: {
        event: {
            type: Object
        },
        languages: {
            type: Array,
        }

    },
    watch: {
        'newSpeaker.salutation': function (item) {
            if (item !== undefined) {
                this.selected_salutation = item;
            }
        },
        'exhibitors': function (items) {
            this.exhibitorsList = _.map(items, (item) => {
                const translate = _.get(_.head(item.translations), 'name');
                return {
                    title: translate,
                    //_id: item._id,
                    id: item._id
                }
            })
        }
    },
    name: 'LivePresentationCreateForm',
    components: {
        InputSwitch,
        ValidationMessages
    },

    computed: {
        ...mapGetters('RoundTablesSingle', ['error_translation_tab','error_general_tab','selectedLanguageKey', 'selectedLanguageTitle', 'selectedLanguageDescirption', 'item', 'loading', 'rolesAll', 'companiesAll', 'newSpeaker', 'createSpeakerBtnTxt', 'newEmailExists', 'eventID', 'createDialog']),
        ...mapGetters('RoundTablesIndex', ['speakers','event_timezone']),
        ...mapGetters('ExhibitorsIndex', { exhibitors: "all" })
    },
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.lp_create;
        delete this.$flashMessage.groups.lp_create_speaker;


    },
    created() {
        this.setSpeakerEventId(this.event._id);
    },
    mounted() {
        this.checkPermission();
        this.setEventTd(this.event._id);
        let user = JSON.parse(localStorage.getItem('user'));
        this.setCreatedBy(`${user.first_name} ${user.last_name}`)
        this.fetchData();
        this.fetchExhibitorsData(this.event._id);
        const switchers = document.querySelectorAll('.general-event-config-snippet input[data-plugin-ios-switch]')
        switchers.forEach(item => {
            const elem = new window.theme.PluginIOS7Switch($(item))
        });
        this.setStartTime(this.event.start_date);
        this.setEndTime(this.event.end_date);
        this.updateTimeZone();


    },
    methods: {
        ...mapActions("AuthenticationsIndex",["checkRoundTableSubscrptions"]),
        ...mapActions('RoundTablesIndex', {
            fetchAllData: 'fetchData',
        }),
        ...mapActions('RoundTablesSingle', [
            'storeSpeakerData',
            'storeData',
            'fetchData',
            'checkEmailExists',
            'resetState',
            'setTranslations',
            'setEventTd',
            'setSpeaker',
            'setSpeakerSalutation',
            'setSpeakerEmail',
            'setSpeakerEventId',
            'setSpeakerFirstName',
            'setSpeakerLastName',
            'setSpeakerCretedBy',
            'setExhibitor',
            'setIsRestricted',
            'setMinutes',
            'setIsRating',
            'setIsScreenSharing',
            'setIsLiveStreaming',
            'setStreamUrl',
            'setScheduleStatus',
            'setIsBackgroundPicture',
            'setPresentationVideo',
            'setPresentationDocuments',
            'setScreenLogo',
            'setSesssionLogo',
            'setBackgroundPicture',
            'setSpeakerPicutre',
            'setBackgroundColor',
            'setInterprefy',
            'setInterprefyToken',
            'setAwsInterpretation',
            'setAwsEnableSpeechToSpeech',
            'setAwsEnableSpeechToText',
            'setChat',
            'setStartTime',
            'setEndTime',
            'setCreatedBy',
            'setLangKey',
            'setLangTitle',
            'setLangDescirption',
            'setSessionOpen',
            'setMaxNoAttendees',
            'setAttendees',
            'setSesssionThemeLogo',
            'setBackgroundColorBottom',
            'setNotesArcColor',
            'setBanner',
            'setErrorTranslationTab',
            'setErrorGeneralTab',
            'setTimeZone',
            'resetStateSpeaker',
            'setIsNew',
            'setPresentationDocumentsSlides'
         

        ]),
        ...mapActions('ExhibitorsIndex', { fetchExhibitorsData: "fetchData" }),
        updateTimeZone(){
           this.setTimeZone(this.event.time_zone)
          },
        fileLoad(e) {
            const file = e.target.files[0];
            const id = uuidv4();
            const ff = upload(file, id, "users", null, file.type);
            ff.then((resp) => {
                console.log(resp)
            }).catch((e) => {
                console.error(e)
                this.$flashMessage.show({
                    group: 'lp_create',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong.'
                });
            })
        },

        updateSpeaker(value) {
            this.setSpeaker(value.id);
        },
        async submitSpeakerForm() {
            this.storeSpeakerData()
                .then(async () => {
                    this.$flashMessage
                        .show({
                            group   : 'lp_create',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Speaker added successfully.</li></ul>',
                        });
                    
                    await this.fetchAllData(this.eventID);
                    this.dialog = false;
                    this.setSpeakerFirstName(null);
                    this.setSpeakerLastName(null);
        
                   // this.resetStateSpeaker();
                })
                .catch((error) => {
                    let html = gettingErrorMessage(error);
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'lp_create',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }
                })
        },
        async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },
        async submitForm() {
          const isFormCorrect = await this.v$.$validate();
          let validateTranlation = await this.checkLanguageValidation();
          this.error_general_tab = false;
          this.error_translation_tab = false;
          this.error_theme_tab = false;
          this.error_interpretation_tab = false;
          this.setErrorGeneralTab(false)
          this.setErrorTranslationTab(false);

          let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "speaker"){
                generalError.push("<li>Speaker is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }

            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
                if (!_.isEmpty(validateTranlation)) {
           
                  this.error_translation_tab = true;
                  this.setErrorTranslationTab(true);
                  this.$flashMessage.show({
                      group: 'lp_create',
                      type: 'error',
                      time:1000,
                      title: 'Failed',
                      html: `<ul>${validateTranlation.join("")}</ul>`
                  });
                return ;
              }          
            } 
            if (!isFormCorrect ) {
                
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'lp_create',
                        type    : 'error',
                        time    : 1000,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }
       
            this.storeData()
                .then(async (resp) => {
                    this.$flashMessage
                        .show({
                            group: 'lp_index',
                            type: 'success',
                            title: 'Success',
                            html: '<ul><li>Roundtable created successfully.</li></ul>',
                        });

                    await this.fetchAllData(this.event._id);
                }).catch(error => {
                    let html = gettingErrorMessage(error);
                   
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'lp_create',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }

                });
        },
        updateLaguage(value) {
            this.err_translations = null;
            this.setLangKey(value);
            this.languageKey = value;
            this.setLangTitle(null);
            this.setLangDescirption(null);
            if (_.isUndefined(_.find(this.translation, {
                'key': value,
            }))) {
                let obj = {
                    "key": `${value}`,
                    "title": null,
                    "description": null
                }
                this.translation.push(obj);
            } else {
                let selectedLang = _.find(this.item.translations, {
                    'key': this.selectedLanguageKey,
                })
                if (!_.isUndefined(selectedLang)) {
                    this.setLangTitle(selectedLang.title);
                    this.setLangDescirption(selectedLang.description);
                }
            }

        },
        updateLanguageTitle(e) {
            this.err_translations = null;
            if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'title',
                    update_value: e.target.value
                });

            }
            this.setLangTitle(e.target.value);
        },
        updateLanguageDescription(e) {
            if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'description',
                    update_value: e.target.value
                });
            }
            this.setLangDescirption(e.target.value);
        },
        updateNewSpeakerEmail(e) {

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                this.setSpeakerEmail(e.target.value);
                this.setSpeakerEventId(this.eventID);
                this.setSpeakerCretedBy("63da6ecb083ba9aebc3b35f5");
                this.checkEmailExists()
                    .then()
                    .catch((error) => {
                        console.error(error)
                        this.$flashMessage.show({
                            group: 'lp_create',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong.'
                        });
                    })
            } else {
                this.setSpeakerFirstName(null);
                this.setSpeakerLastName(null);
            }
        },
        updateNewSpeakerSalutation(value) {
            this.setSpeakerSalutation(value);
        },
        updateNewSpeakerFirstName(e) {
            this.setSpeakerFirstName(e.target.value);
        },
        updateNewSpeakerLastName(e) {
            this.setSpeakerLastName(e.target.value);
        },
        updateExhibitor(e) {
            this.setExhibitor(e.target.value);
        },
        UpdateIsRestricted(e) {
            this.restric_user_disabled = true;
            if (e.target.checked) {
                this.restric_user_disabled = false;
            }

            this.setIsRestricted(e.target.checked);
        },
        UpdateIsRating(e) {
            this.setIsRating(e.target.checked);
        },
        UpdateIsScreenSharing(e) {
            this.setIsScreenSharing(e.target.checked);
        },
        UpdateIsLiveStreaming(e) {
            this.stream_disabled = true;
            if (e.target.checked) {
                this.stream_disabled = false;
            }
            this.setIsLiveStreaming(e.target.checked);
        },
        UpdateScheduleStatus(e) {
            this.setScheduleStatus(e.target.checked);
        },
        UpdateIsBackgroundPicture(e) {
            this.setIsBackgroundPicture(e.target.checked);
        },
        updateVideo(info, data) {
            this.err_video = null;
            const extension = data.data.Key.split('.').pop()
            const key = _.get(this.item.presentation_video, 'key', null);
            const size = _.get(info, 'file.size', 0)
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
              }
            this.setPresentationVideo({
                old_key: data.data.Key,
                temp_key: info.id,
                key: data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeVideo(e) {
            this.err_video = null;
            this.setPresentationVideo(null);
        },
        updateDocument(info, data) {
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key   = _.get(this.item.presentation_documents, 'key', null);
            const size  = _.get(info, 'file.size', 0);
           
           if(size>maxPdfFileSize){
                this.showCbError();
                return;
            }
            this.setPresentationDocuments({
                old_key     :  data.data.key?data.data.key:data.data.Key,
                temp_key    : info.id,
                key         : data.data.key?data.data.key:data.data.Key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt  : '2023.01.01',
            });


            this.$forceUpdate();
        },
        removeDocument(e) {
            this.err_documents = null;
            this.setPresentationDocuments(null);
            this.setPresentationDocumentsSlides([]);
        },
        updateScreenLogo(info, data) {
            this.err_screen_logo = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.screen_logo, 'key', null);
            const size = _.get(info, 'file.size', 0)
            this.setScreenLogo({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });
            this.$forceUpdate();
        },
        removeScreenLogo(e) {
            this.err_screen_logo = null;
            this.setScreenLogo(null);
        },
        updateSesssionLogo(info, data) {
            this.err_sesssion_logo = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.session_logo, 'key', null);
            const size = _.get(info, 'file.size', 0)

            this.setSesssionThemeLogo({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeSesssionLogo(e) {
            this.err_sesssion_logo = null;
            this.setSesssionThemeLogo(null);
        },
        updateBackgroundPicture(info, data) {
            this.err_background_picture = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.background_picture, 'key', null);
            const size = _.get(info, 'file.size', 0)

            this.setBackgroundPicture({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeBackgroundPicture(e) {
            this.err_background_picture = null;
            this.setBackgroundPicture(null);
        },
        updateBannerPicutre(info, data) {
            this.err_banner_picutre = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.banner, 'key', null);
            const size = _.get(info, 'file.size', 0)

            this.setBanner({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeBannerPicutre(e) {
            this.err_banner_picutre = null;
            this.setBanner(null);
        },
        updateStreamUrl(e) {
            this.setStreamUrl(e.target.value);
        },
        updateMinutes(e) {
            this.setMinutes(e.target.value);
        },
        updateBackgroundColor(e) {
            this.setBackgroundColor(e.target.value);
        },
        updateBackgroundColorBottom(e) {
            this.setBackgroundColorBottom(e.target.value);
        },
        updateNotesArcColor(e) {
            this.setNotesArcColor(e.target.value);
        },
        updateInterprefy(e) {

            this.interprefy_disabled = true;
            if (e.target.checked) {
                this.interprefy_disabled = false;
                this.setAwsInterpretation(false)
                this.aws_interprefy_disabled =true;
                this.setAwsEnableSpeechToSpeech(false);
                this.setAwsEnableSpeechToText(false);
            }
            this.setInterprefy(e.target.checked);
         
        },
        updateInterprefyToken(e) {
            this.setInterprefyToken(e.target.value);
        },
        updateAwsInterpretation(e) {
            this.aws_interprefy_disabled = true;
            if (e.target.checked) {
                this.aws_interprefy_disabled = false;
            }
            if(e.target.checked){
              this.setInterprefy(false);
              this.interprefy_disabled= true
            }
            //interprefy
            this.setAwsInterpretation(e.target.checked);
        },
        updateAwsEnableSpeechToSpeech(e) {
            this.setAwsEnableSpeechToSpeech(e.target.checked);
        },
        updateAwsEnableSpeechToText(e) {
            this.setAwsEnableSpeechToText(e.target.checked);
        },
        updateChat(e) {
            this.setChat(e.target.checked);
        },
        updateStartTime(e) {
            this.err_start_time = null;
            this.setStartTime(e.target.value);
        },
        updateEndTime(e) {
            this.err_end_time = null;
            this.setEndTime(e.target.value);
        },
        showCbError() {
            this.$flashMessage.show({
                group   :   'lp_create',
                type    :   'error',
                title   :   'Failed',
                time    :   1000,
                html    :   "<ul><li>Invalid File/Doc format.Please use described valid format and size</li></ul>"
            });
        },
        videoErrCb(errList) {
            this.showCbError();
        },
        documentsErrCb(errList) {
            this.showCbError();
        },
        screenLogoErrCb(errList) {
            this.showCbError();
        },
        BackgroundPictureErrCb(errList) {
            this.showCbError();
        },
        sesssionLogoErrCb(errList) {
            this.showCbError();
        },
        bannerPicutreErrCb(errList) {
            this.showCbError();
        },
        UpdateSessionOpen(e) {
            this.session_open_disabled = true;
            if (e.target.checked) {
                this.session_open_disabled = false;
            }
            this.setSessionOpen(e.target.checked);
        },
        UpdateMaxNoAttendees(e) {
            this.setMaxNoAttendees(e.target.value);
        },
        
        UpdateAttendees(e) {
            let attenArr = [];
            _.forEach(e, function (value) {
                attenArr.push(value.id)
            });
            this.setAttendees(attenArr);
           
                       
        },
        RemoveAttendees(e) {
            let cloneAttendees = _.cloneDeep(this.item.attendees);
            const index = cloneAttendees.indexOf(e.id);
            if (index > -1) { // only splice array when item is found
                cloneAttendees.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.setAttendees(cloneAttendees);
        },
        cancelClick() {
            this.resetState()
        },
        getEventDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm');
        },
      
        checkPermission(){
            this.checkRoundTableSubscrptions(this.$route.params.event_id)
            .then((res)=>{
            }) .catch((error) => {
                console.log("Error",error);
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group : 'lp_index',
                    type  : 'error',
                    title : 'Error',
                    time  : 1500,
                    text  : html,
                    html  : html
                });

                this.setIsNew(false);
                this.fetchAllData(this.$route.params.event_id);
            
                    
            });
        },

    },

}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
    border-bottom: 0px solid #ccc;
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }
}

.setting_wrapper {
    float: right;

    a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;
    }
}

.toggle label {
    color: #000 !important;
}

.drop-files {
    margin-top: 10px;
}

.newspeaker {
    margin-top: 20px;
}

.newspeaker button {
    width: 100%;
}

.add_spk_btn {
    background-color: #0088CC;
    color: #fff;
}

.mini_txt {
    font-size: 0.8em;
    color: #585252;
    font-style: italic;
    margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
    border-radius: 15px;
    padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
    border-left-color: #0088CC;
}

.orangebtn {
    background-color: orange;
}

.wrapp {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.flash-content {
    z-index: 10;
}

.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
    position: absolute;
    top: -53px;
    left: 50px;
    width: 300px;
    height: auto;
}
</style>
