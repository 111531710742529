<template v-slot:default>
             
<div class="form-group row">
    <div class="col-lg-4">
        <label>Host * </label>
        <v-select placeholder="Select Host" label="name" 
            :options="attendeeList" 
            track-by="id" 
            @option:selected="updateHost" />
    </div>
</div>

<div class="form-group row">


    <div class="col-lg-6">
        <label>Owner ?</label>
        <br />
        <div class="switch switch-sm switch-primary">
            <input-switch name="is_owner" :value="item.is_owner" id="customSwitchesEx" class="custom-control-input" @change="updateOwner" />

          <label class="custom-control-label" for="customEETT"></label>
        </div>
      </div>

    
</div>

<div class="form-group row">
    <div class="col-lg-6">
        <label>Is Moderator</label>
        <br />
        <div class="switch switch-sm switch-primary">
            <input-switch name="is_moderator" :value="item.is_moderator" id="customSwitchesEx" class="custom-control-input" @change="updateModerator" />
            <label class="custom-control-label" for="customEETT1"></label>
        </div> 
    </div>
</div>

<div class="form-group row">
    <label>Presentation File</label>
    <DropFiles :file="item.presentation_file" accept="application/pdf" :module_id="this.moduleId" module_type="meetings"  
    @onUploaded="updatePresentationFile" @onRemoved="removePresentationFile" 
    asset_type="documents" 
    @onError="showCbError">
        <template #header>
          <label>Max size 30MB and format PDF</label>
        </template>
        <template #tooltip>
          <VTooltip style="display:inline-block">
            <i class="fa fa-info-circle" />
            <template #popper>
              <img src="/admin_assets/img/event_hall_latest.png" alt="img"/>
            </template>
          </VTooltip>
        </template>
      </DropFiles>

</div>

<div class="form-group row">
    <label>Presentation Video</label>
    <DropFiles 
    :file="item.presentation_video" accept="video/mp4" :module_id="this.moduleId" module_type="meetings"  
    @onUploaded="updatePresentationVideo" @onRemoved="removePresentationVideo" 
    asset_type="video" 
    @onError="showCbError">
        <template #header>
          <label>(Max size 500MB and format MP4.)</label>
        </template>
        <template #tooltip>
          <VTooltip style="display:inline-block">
            <i class="fa fa-info-circle" />
            <template #popper>
              <img src="/admin_assets/img/event_hall_latest.png" alt="img"/>
            </template>
          </VTooltip>
        </template>
      </DropFiles>

</div>

<div class="card-body text-right mt-3 mr-5">
    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"  @click="createHost()">Create</button>
    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger"  @click="cancelClick()">Cancel</button>

</div>

<FlashMessage group="meeting_host_create" />
</template>
<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters,mapActions,mapState} from 'vuex'
import TogglePrimary from "@/components/Commons/TogglePrimary";
import InputSwitch from "@/components/Commons/InputSwitch";
import moment from 'moment';
import _ from "lodash";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import ObjectID from 'bson-objectid';

import constants from "@/config/constants";
const maxVideoFileSize = 524288000; // Max file size
const maxPdfFileSize =  32096088;  // Max file size
  
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
name: "MeetingsCreate",
components: {
  InputSwitch
},
setup() {
      return {
          v$: useVuelidate()
      }
  },
 
data() {
  return {
      moduleId: ObjectID(),
      IMAGE_URL: constants.IMAGE_URL
  
  }
},
  props: {
      meetingId:null
    },
  validations() {
      return {
          item: {
             
          }
      }
  },
  mounted() {
      this.setEventId(this.$route.params.event_id)
      this.fetchAttendeeList(this.$route.params.event_id);
     // this.fetchMeetingById(this.meetingId);
    },
  beforeUnmount() {
      this.resetState()
      delete this.$flashMessage.groups.meeting_host_create;
      
  },
  computed:{
    ...mapGetters('MeetingHostsSingle', ['is_new', "is_editing","item"]),
    ...mapGetters('RoomSessionsIndex', ["attendeeList"]),
    ...mapGetters('MeetingHostsIndex', ["all"]),
  },
  methods: {
            ...mapActions('RoomSessionsIndex', {fetchAttendeeList:"fetchAttendeeList"}),
            ...mapActions('MeetingHostsIndex', {fetchMeetingById:"fetchMeetingById"}),
            ...mapActions('MeetingHostsSingle',
        [
            "storeData",
            "setIsNew",
            "setIsEditing",
            "updateData",
            "resetState",
            "destroyData",
            "setItem",
            "setHostId",
            "setIsOwner",
            "setIsModerator",
            "setPresentationFile",
            "setPresentationSlides",
            "setPresentationVideo",
            "fetchSingleHost",
            "setEventId"
        ]),
        updatePresentationFile(info, data) {
          console.log("dataaa**********",data);
            if (!_.has(data.data, 'Key')) {
                    return;
            }
            const extension     = data.data.Key.split('.').pop();
            const key           = _.get(this.item.document1, 'Key', null);
            const size          = _.get(info, 'file.size', 0);
            if(size>maxPdfFileSize){
                this.showCbError();
                return;
            }
            this.setPresentationFile({
                old_key   : key,
                key       : data.data.Key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
            this.$forceUpdate();
        },
        removePresentationFile(e) {
            this.setPresentationFile(null);
            this.setPresentationSlides([]);
        },
        updatePresentationVideo(info, data) {
            const extension = data.data.Key.split('.').pop()
            const key = _.get(this.item.video, 'key', null);
            const size = _.get(info, 'file.size', 0)
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
            }
            this.setPresentationVideo({
                old_key: data.data.Key,
                temp_key: info.id,
                key: data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        showCbError(){
            this.$flashMessage.show({
                    group: 'meeting_host_index',
                    type: 'error',
                    title: 'Failed',
                    html: "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
                  });
        },
        removePresentationVideo(e) {
            this.setPresentationVideo(null);
        },
        updateModerator(e){
            this.setIsModerator(e.target.checked);
        },
        updateOwner(e){
            this.setIsOwner(e.target.checked);
        },
        updateHost(e){
            this.setHostId(e.id); 
        },
        getHostById(host) {
            return _.has(host, '_id') ?  host.first_name+" "+host.last_name: host;
        },
        createClick() {
            this.setIsNew(true);
            this.setIsEditing(false);
        },
        cancelClick() {
            this.resetState();
        },
        editHost(hostData){
            this.setItem(hostData);
            this.setIsNew(false);
            this.setIsEditing(true);
            this.$forceUpdate();
        },
        
        async createHost(){
            this.storeData(this.meetingId)
                .then(async (resp) => {
                this.$flashMessage.show({
                            group   : 'meetings_index',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Meeting Host created successfully.</li></ul>',
                            });
                this.resetState();
                this.fetchMeetingById(this.meetingId)
                
                })
                .catch(error => {
                console.log("Error store",error);
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group: 'meeting_host_create',
                    type: 'error',
                    title: 'Failed',
                    time:1000,
                    text: html || 'Something went wrong',
                    html: html
                });
                        
                });
        }
  }
 
}
</script>
<style lang="scss" scoped>
.labelwrapper {
  display: flex;
  justify-content: space-around;
}

.labelwrapper label {
  display: block;
}

.toggle.active i.fa-minus {
  display: none !important;
}

.toggle>label {
  background: #fff !important;
}

.fas .fa-minus {
  display: none !important;
}

.v-dialog>.v-overlay__content>.v-card {
  border-radius: 15px;
  padding-top: 20px;
}

html .toggle-primary .toggle.active>label .labelwrapper span label {
  color: #ffffff !important;
}

.question_wrapper {
  background: #efefef;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.question {
  font-weight: bold;
  margin-bottom: 10px;
}

.question_action {
  position: absolute;
  top: 50%;
  right: 50px;
}

.question_edit {
  background: #1896e3;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;
}

.question_delete {
  background: #fff;
  color: #5a5757;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;

}

.newbtn {
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 5px;
}
.force-overflow {
min-height: 260px;
}

.booth_data_wrapper {
border: 1px solid #efefef;
background: #efefef85;
padding: 0 15px;
}

.content_left_panel {
position: relative;

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    text-decoration: none;
    color: inherit;
    display: block;
    font-size: 15px;

    &:first-child {
      padding-top: 0;
    }

    span {
      color: inherit;
      font-weight: bold;
    }
  }
}
}
</style>