//import timeZoness from 'countries-and-timezones'
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import $ from "jquery";
import moment from "moment"


function initialState(id) {
    return {
        event_id: null,
        create_dialog: false,
        exhibitors: [],
        lang_key : null,
        lang_title : null,
        lang_descirption : null,
        time_zone:null,
        error_translation_tab :false,
        error_general_tab :false,
  
        item: {
            event_id:null,
            translations: [],
            speaker: null,
            exhibitor: null,
            start_time : null, 
            end_time : null,
            is_restricted: false,
            minutes :  0,
            location : null,
            qa_type: false,
            is_rating: false,
            is_screen_sharing: false,
            is_live_streaming: false,
            stream_url: null,
            schedule_status: false,
            video:null,
            documents:null,
            documents_slides:[],
            screen_logo:null,
            sesssion_logo:null,
            is_background_picture: false,
            background_picture: null,
            background_color: '#ccc',
            background_stripes_color: "#fff",
            stage_color1: "#fff",
            stage_color2: "#fff",
            background_color_bottom: "#fff",
            speaker_picutre: null,
            pillar_left1_logo1: null,
            pillar_left1_logo2: null,
            pillar_left1_logo3: null,
            left1_pillar_header_color: "#fff",
            left1_pillar_bottom_color: "#fff",
            pillar_left2_logo1: null,
            pillar_left2_logo2: null,
            pillar_left2_logo3: null,
            left2_pillar_color: "#fff",
            left2_pillar_color_bottom: "#fff",
            pillar_right1_logo1: null,
            pillar_right1_logo2: null,
            pillar_right1_logo3: null,
            right1_pillar_color: "#fff",
            right1_pillar_color_bottom: "#ccc",
            pillar_right2_logo1: null,
            pillar_right2_logo2: null,
            pillar_right2_logo3: null,
            right2_pillar_color: "#fff",
            right2_pillar_color_bottom: "#ccc",
            interprefy: false,
            interprefy_token: null,
            aws_interpretation: false,
            aws_enable_speech_to_speech: null,
            aws_enable_speech_to_text: null,
            chat: false,
            created_by : null,
            status: true,
        },
        new_speaker: {
            salutation: null,
            email: null,
            first_name: null,
            last_name: null,
            event_id: "",
            is_verified:true
            //created_by: null
        },
        new_email_exists: null,
        create_speaker_btn_txt: 'Create NEW SPEAKER',
        loading: false,
        is_new: false,
        is_editing: false,
        
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    newSpeaker: state => state.new_speaker,
    createSpeakerBtnTxt: state => state.create_speaker_btn_txt,
    newEmailExists: state => state.new_email_exists,
    eventID: state => state.event_id,
    createDialog: state => state.create_dialog,
    listExhibitors: state => state.exhibitors,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
    selectedLanguageKey : state => state.lang_key,
    selectedLanguageTitle : state => state.lang_title,
    selectedLanguageDescirption : state => state.lang_descirption,
    
    error_translation_tab       : state => state.error_translation_tab,
    error_general_tab           : state => state.error_general_tab,

}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    storeSpeakerData({ commit, state, dispatch }) {
       //  commit('setLoading', true)
        //dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            //By Default Create New speaker params set
            let url = `${constants.API_EVENT_USERS_URL}/api/v1/event-user/create/front`;
            //delete state.new_speaker.created_by;
           // state.new_speaker.event_id = `${state.event_id}`;
            let params = state.new_speaker;
            console.log("paramsparams",params);
            //If user already exists just need add event information in user
            if (state.new_email_exists === true) {
                url = `${constants.API_EVENT_USERS_URL}/api/v1/event-user/introduce/event`
                params = {
                    "email": `${state.new_speaker.email}`,
                    "event_id": `${state.event_id}`
                }
            }
            axios.post(`${url}`, params)
                .then(response => {
                    let UId = response.data.data._id
                    commit('setSpeaker', UId);
                    commit('setCreateDialog', false)
                    commit('setLoading', false)
                    //commit('resetState')
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');


        const params = _.cloneDeep(state.item);
        params.event_id = state.event_id

      
        
        //params.start_time   =  state.item.start_time;
       // params.end_time     =  state.item.end_time;
     
        delete params.time_zone;
     
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       return  axios.post(constants.API_URL + '/api/live-presentation/', params, {headers: headers})
            .then((response) => {
                commit('resetState')
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            });
     },
    checkEmailExists({ commit, state, dispatch }) {
        commit('setLoading', true)
        // dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            axios.post(`${constants.API_EVENT_USERS_URL}/api/v1/event-user/exists/email`, { email: state.new_speaker.email })
                .then(response => {
                    commit('setLoading', false)
      
                    let user = response.data.data;
                    if (_.isNull(user)) {
                        commit('setNewEmailExists', false);
                        commit('setSpeakerFirstName', null);
                        commit('setSpeakerLastName', null);
                        commit('setCreateSpeakerBtnTxt', "Create NEW SPEAKER");
                        resolve();
                    } else {
                        commit('setSpeakerSalutation', user.salutation);
                        commit('setSpeakerFirstName', user.first_name);
                        commit('setSpeakerLastName', user.last_name);
                        commit('setNewEmailExists', true);
                        commit('setCreateSpeakerBtnTxt', "ADD SPEAKER");
                        resolve();
                    }
                })
                .catch(error => {
                    commit('setLoading', false)
      
                    console.log("Error One",error);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                });
        })
    },
    updateData({ commit, state, dispatch }) {


        commit('setLoading', true)
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');


        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.created_by;

    //    delete params.event_id;
        delete params.time_zone;
        _.map(params.translations, i => delete i._id);

        params.minutes = Number(params.minutes);

        if (_.isObject(params.speaker)) {
            params.speaker = _.get(params.speaker, '_id', null);
           
        }
     
       // params.start_time   =  state.item.start_time;
       // params.end_time     =  state.item.end_time;
     

        //clear files fields
      /*  if (!_.has(params.video, 'key')) {
            delete params.video;
        }
        if (!_.has(params.documents, 'key')) {
            delete params.documents;
        }
        if (!_.has(params.screen_logo, 'key')) {
            delete params.screen_logo;
        }
        if (!_.has(params.sesssion_logo, 'key')) {
            delete params.sesssion_logo;
        }
        if (!_.has(params.background_picture, 'key')) {
            delete params.background_picture;
        }
        if (!_.has(params.speaker_picutre, 'key')) {
            delete params.speaker_picutre;
        }
        if (!_.has(params.pillar_left1_logo1, 'key')) {
            delete params.pillar_left1_logo1;
        }
        if (!_.has(params.pillar_left1_logo2, 'key')) {
            delete params.pillar_left1_logo2;
        }
        if (!_.has(params.pillar_left1_logo3, 'key')) {
            delete params.pillar_left1_logo3;
        }
        if (!_.has(params.pillar_left2_logo1, 'key')) {
            delete params.pillar_left2_logo1;
        }
        if (!_.has(params.pillar_left2_logo2, 'key')) {
            delete params.pillar_left2_logo2;
        }
        if (!_.has(params.pillar_left2_logo3, 'key')) {
            delete params.pillar_left2_logo3;
        }
        if (!_.has(params.pillar_right1_logo1, 'key')) {
            delete params.pillar_right1_logo1;
        }
        if (!_.has(params.pillar_right1_logo2, 'key')) {
            delete params.pillar_right1_logo2;
        }
        if (!_.has(params.pillar_right1_logo3, 'key')) {
            delete params.pillar_right1_logo3;
        }
        if (!_.has(params.pillar_right2_logo1, 'key')) {
            delete params.pillar_right2_logo1;
        }
        if (!_.has(params.pillar_right2_logo2, 'key')) {
            delete params.pillar_right2_logo2;
        }
        if (!_.has(params.pillar_right2_logo3, 'key')) {
            delete params.pillar_right2_logo3;
        }*/

        if (params.video) {
            params.video = {
                key: params.video.key,
                old_key: params.video.key,
                size: params.video.size,
                extension: params.video.extension,
                uploadedBy: params.video.uploadedBy,
                uploadedAt: params.video.uploadedAt,
            }
        }

        if (params.documents) {
            params.documents = {
                key:  params.documents.key,
                old_key: params.documents.key,
                size: params.documents.size,
                extension: params.documents.extension,
                uploadedBy: params.documents.uploadedBy,
                uploadedAt: params.documents.uploadedAt,
            }
        }

        if (params.screen_logo) {
            params.screen_logo = {
                old_key: params.screen_logo.key,
                key:  params.screen_logo.key,
                size: params.screen_logo.size,
                extension: params.screen_logo.extension,
                uploadedBy: params.screen_logo.uploadedBy,
                uploadedAt: params.screen_logo.uploadedAt,
            }
        }

        if (params.sesssion_logo) {
            params.sesssion_logo = {
                key: params.sesssion_logo.key,
                old_key: params.sesssion_logo.key,
                size: params.sesssion_logo.size,
                extension: params.sesssion_logo.extension,
                uploadedBy: params.sesssion_logo.uploadedBy,
                uploadedAt: params.sesssion_logo.uploadedAt,
            }
        }

        if (params.background_picture) {
            params.background_picture = {
                key: params.background_picture.key,
                old_key: params.background_picture.key,
                size: params.background_picture.size,
                extension: params.background_picture.extension,
                uploadedBy: params.background_picture.uploadedBy,
                uploadedAt: params.background_picture.uploadedAt,
            }
        }

        if (params.speaker_picutre) {
            params.speaker_picutre = {
                key: params.speaker_picutre.key,
                old_key: params.speaker_picutre.key,
                size: params.speaker_picutre.size,
                extension: params.speaker_picutre.extension,
                uploadedBy: params.speaker_picutre.uploadedBy,
                uploadedAt: params.speaker_picutre.uploadedAt,
            }
        }

        if (params.pillar_left1_logo1) {
            params.pillar_left1_logo1 = {
                key: params.pillar_left1_logo1.key,
                old_key: params.pillar_left1_logo1.key,
                size: params.pillar_left1_logo1.size,
                extension: params.pillar_left1_logo1.extension,
                uploadedBy: params.pillar_left1_logo1.uploadedBy,
                uploadedAt: params.pillar_left1_logo1.uploadedAt,
            }
        }

        if (params.pillar_left1_logo2) {
            params.pillar_left1_logo2 = {
                key: params.pillar_left1_logo2.key,
                old_key: params.pillar_left1_logo2.key,
                size: params.pillar_left1_logo2.size,
                extension: params.pillar_left1_logo2.extension,
                uploadedBy: params.pillar_left1_logo2.uploadedBy,
                uploadedAt: params.pillar_left1_logo2.uploadedAt,
            }
        }

        if (params.pillar_left1_logo3) {
            params.pillar_left1_logo3 = {
                key: params.pillar_left1_logo3.key,
                old_key: params.pillar_left1_logo3.key,
                size: params.pillar_left1_logo3.size,
                extension: params.pillar_left1_logo3.extension,
                uploadedBy: params.pillar_left1_logo3.uploadedBy,
                uploadedAt: params.pillar_left1_logo3.uploadedAt,
            }
        }

        if (params.pillar_left2_logo1) {
            params.pillar_left2_logo1 = {
                key: params.pillar_left2_logo1.key,
                old_key: params.pillar_left2_logo1.key,
                size: params.pillar_left2_logo1.size,
                extension: params.pillar_left2_logo1.extension,
                uploadedBy: params.pillar_left2_logo1.uploadedBy,
                uploadedAt: params.pillar_left2_logo1.uploadedAt,
            }
        }

        if (params.pillar_left2_logo2) {
            params.pillar_left2_logo2 = {
                key: params.pillar_left2_logo2.key,
                old_key: params.pillar_left2_logo2.key,
                size: params.pillar_left2_logo2.size,
                extension: params.pillar_left2_logo2.extension,
                uploadedBy: params.pillar_left2_logo2.uploadedBy,
                uploadedAt: params.pillar_left2_logo2.uploadedAt,
            }
        }

        if (params.pillar_left2_logo3) {
            params.pillar_left2_logo3 = {
                key: params.pillar_left2_logo3.key,
                old_key: params.pillar_left2_logo3.key,
                size: params.pillar_left2_logo3.size,
                extension: params.pillar_left2_logo3.extension,
                uploadedBy: params.pillar_left2_logo3.uploadedBy,
                uploadedAt: params.pillar_left2_logo3.uploadedAt,
            }
        }

        if (params.pillar_right1_logo1) {
            params.pillar_right1_logo1 = {
                key: params.pillar_right1_logo1.key,
                old_key: params.pillar_right1_logo1.key,
                size: params.pillar_right1_logo1.size,
                extension: params.pillar_right1_logo1.extension,
                uploadedBy: params.pillar_right1_logo1.uploadedBy,
                uploadedAt: params.pillar_right1_logo1.uploadedAt,
            }
        }

        if (params.pillar_right1_logo2) {
            params.pillar_right1_logo2 = {
                key: params.pillar_right1_logo2.key,
                old_key: params.pillar_right1_logo2.key,
                size: params.pillar_right1_logo2.size,
                extension: params.pillar_right1_logo2.extension,
                uploadedBy: params.pillar_right1_logo2.uploadedBy,
                uploadedAt: params.pillar_right1_logo2.uploadedAt,
            }
        }

        if (params.pillar_right1_logo3) {
            params.pillar_right1_logo3 = {
                key: params.pillar_right1_logo3.key,
                old_key: params.pillar_right1_logo3.key,
                size: params.pillar_right1_logo3.size,
                extension: params.pillar_right1_logo3.extension,
                uploadedBy: params.pillar_right1_logo3.uploadedBy,
                uploadedAt: params.pillar_right1_logo3.uploadedAt,
            }
        }

        if (params.pillar_right2_logo1) {
            params.pillar_right2_logo1 = {
                key: params.pillar_right2_logo1.key,
                old_key: params.pillar_right2_logo1.key,
                size: params.pillar_right2_logo1.size,
                extension: params.pillar_right2_logo1.extension,
                uploadedBy: params.pillar_right2_logo1.uploadedBy,
                uploadedAt: params.pillar_right2_logo1.uploadedAt,
            }
        }

        if (params.pillar_right2_logo2) {
            params.pillar_right2_logo2 = {
                key: params.pillar_right2_logo2.key,
                old_key: params.pillar_right2_logo2.key,
                size: params.pillar_right2_logo2.size,
                extension: params.pillar_right2_logo2.extension,
                uploadedBy: params.pillar_right2_logo2.uploadedBy,
                uploadedAt: params.pillar_right2_logo2.uploadedAt,
            }
        }

        if (params.pillar_right2_logo3) {
            params.pillar_right2_logo3 = {
                key: params.pillar_right2_logo3.key,
                old_key: params.pillar_right2_logo3.key,
                size: params.pillar_right2_logo3.size,
                extension: params.pillar_right2_logo3.extension,
                uploadedBy: params.pillar_right2_logo3.uploadedBy,
                uploadedAt: params.pillar_right2_logo3.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        console.log("params to submit",params)
       return  axios.patch(constants.API_URL + '/api/live-presentation/' + state.item._id, params, {headers: headers})
            .then(response => {
                console.log(response)
                commit('setItem', response.data.data)
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, state, dispatch }) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        console.log("State",state.item);
        return axios.get(`${constants.API_URL}/api/events/${state.event_id}/exhibitors`, {headers: headers})
            .then(response => {
                 let listExhibitors=[{id:null, title:" Select Exhibitor"}];
                 
                _.forEach(response.data.data, function (value, key) {
                    const translate = _.get(_.head(value.translations), 'name');
                    listExhibitors.push({  title: translate,id: value._id});
                    /*console.log("$$$$$$",value,key);
                    let event = _.find(value.events, { 'event_id': state.event_id });
                    console.log("Exhbitor list ***********",event)
                    if (!_.isUndefined(event)) {
                        listExhibitors.push({ "event_id": event.event_id, title: event.languages[0]['key'], id: event._id });
                    }*/
                });
                console.log("listExhibitors",listExhibitors);
                commit('setExhibitors', listExhibitors)
               // commit('setLocalTime', 'start_time')
               // commit('setLocalTime', 'end_time')
            })


    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setSpeaker({ commit }, value) {
        commit('setSpeaker', value)
    },
    setStartTime({ commit }, value) {
        commit('setStartTime', value)
    },
    setEndTime({ commit }, value) {
        commit('setEndTime', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },
    
    setSpeakerEmail({ commit }, value) {
        commit('setSpeakerEmail', value)
    },
    setSpeakerSalutation({ commit }, value) {
        commit('setSpeakerSalutation', value)
    },
    setSpeakerFirstName({ commit }, value) {
        commit('setSpeakerFirstName', value)
    },
    setSpeakerLastName({ commit }, value) {
        commit('setSpeakerLastName', value)
    },
    setCreateSpeakerBtnTxt({ commit }, value) {
        commit('setCreateSpeakerBtnTxt', value)
    },
    setEventTd({ commit }, value) {
        commit('setEventTd', value)
    },
    setNewEmailExists({ commit }, value) {
        commit('setNewEmailExists', value)
    },
    setSpeakerEventId({ commit }, value) {
        commit('setSpeakerEventId', value)
    },
    setSpeakerCretedBy({ commit }, value) {
        commit('setSpeakerCretedBy', value)
    },
    setCreateDialog({ commit }, value) {
        commit('setCreateDialog', value)
    },
    setExhibitors({ commit }, value) {
        commit('setExhibitors', value)
    },
    setExhibitor({ commit }, value) {
        commit('setExhibitor', value)
    },
    setIsRestricted({ commit }, value) {
        commit('setIsRestricted', value)
    },
    setMinutes({ commit }, value) {
        commit('setMinutes', value)
    },
    setQaType({ commit }, value) {
        commit('setQaType', value)
    },
    setIsRating({ commit }, value) {
        commit('setIsRating', value)
    }, 
    setIsScreenSharing({ commit }, value) {
        commit('setIsScreenSharing', value)
    },
    setIsLiveStreaming({ commit }, value) {
        commit('setIsLiveStreaming', value)
    },
    setStreamUrl({ commit }, value) {
        commit('setStreamUrl', value)
    },
    setScheduleStatus({ commit }, value) {
        commit('setScheduleStatus', value)
    },
    setVideo({ commit }, value) {
        commit('setVideo', value)
    },
    setDocument({ commit }, value) {
        commit('setDocument', value)
    },
    setScreenLogo({ commit }, value) {
        commit('setScreenLogo', value)
    },
    setSesssionLogo({ commit }, value) {
        commit('setSesssionLogo', value)
    },
    setIsBackgroundPicture({ commit }, value) {
        commit('setIsBackgroundPicture', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setSpeakerPicutre({ commit }, value) {
        commit('setSpeakerPicutre', value)
    },
    setPillarLeft1Logo1({ commit }, value) {
        commit('setPillarLeft1Logo1', value)
    },
    setPillarLeft1Logo2({ commit }, value) {
        commit('setPillarLeft1Logo2', value)
    },
    setPillarLeft1Logo3({ commit }, value) {
        commit('setPillarLeft1Logo3', value)
    },
    setPillarLeft2Logo1({ commit }, value) {
        commit('setPillarLeft2Logo1', value)
    },
    setPillarLeft2Logo2({ commit }, value) {
        commit('setPillarLeft2Logo2', value)
    },
    setPillarLeft2Logo3({ commit }, value) {
        commit('setPillarLeft2Logo3', value)
    },
    setPillarRight1Logo1({ commit }, value) {
        commit('setPillarRight1Logo1', value)
    },
    setPillarRight1Logo2({ commit }, value) {
        commit('setPillarRight1Logo2', value)
    },
    setPillarRight1Logo3({ commit }, value) {
        commit('setPillarRight1Logo3', value)
    },
    setPillarRight2Logo1({ commit }, value) {
        commit('setPillarRight2Logo1', value)
    },
    setPillarRight2Logo2({ commit }, value) {
        commit('setPillarRight2Logo2', value)
    },
    setPillarRight2Logo3({ commit }, value) {
        commit('setPillarRight2Logo3', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundStripesColor({ commit }, value) {
        commit('setBackgroundStripesColor', value)
    },
    setStageColor1({ commit }, value) {
        commit('setStageColor1', value)
    },
    setStageColor2({ commit }, value) {
        commit('setStageColor2', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setLeft1PillarHeaderColor({ commit }, value) {
        commit('setLeft1PillarHeaderColor', value)
    },
    setLeft1PillarBottomColor({ commit }, value) {
        commit('setLeft1PillarBottomColor', value)
    },
    setLeft2PillarColor({ commit }, value) {
        commit('setLeft2PillarColor', value)
    },
    setLeft2PillarColorBottom({ commit }, value) {
        commit('setLeft2PillarColorBottom', value)
    },
    setRight1PillarColor({ commit }, value) {
        commit('setRight1PillarColor', value)
    },
    setRight1PillarColorBottom({ commit }, value) {
        commit('setRight1PillarColorBottom', value)
    },
    setRight2PillarColor({ commit }, value) {
        commit('setRight2PillarColor', value)
    },
    setRight2PillarColorBottom({ commit }, value) {
        commit('setRight2PillarColorBottom', value)
    },
    setInterprefy({ commit }, value) {
        commit('setInterprefy', value)
    },
    setInterprefyToken({ commit }, value) {
        commit('setInterprefyToken', value)
    },
    setAwsInterpretation({ commit }, value) {
        commit('setAwsInterpretation', value)
    },
    setAwsEnableSpeechToSpeech({ commit }, value) {
        commit('setAwsEnableSpeechToSpeech', value)
    },
    setAwsEnableSpeechToText({ commit }, value) {
        commit('setAwsEnableSpeechToText', value)
    },
    setChat({ commit }, value) {
        commit('setChat', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setCreatedBy({ commit }, value) {
        commit('setCreatedBy', value)
    },

    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescirption({ commit }, value) {
        commit('setLangDescirption', value)
    },
    setLocation({ commit }, value) {
        commit('setLocation', value)
    },
    setTimeZone({ commit }, value){
        commit('setTimeZone', value)
    },
    setErrorTranslationTab({commit},value){
        commit('setErrorTranslationTab', value)
    },
    setErrorGeneralTab({commit},value){
        commit('setErrorGeneralTab', value)
    },
    setDocumentsSlides({ commit }, value) {
        commit('setDocumentsSlides', value)
    }
  
}

const mutations = {
    setErrorGeneralTab(state,value){
        state.error_general_tab = value
    },
    setErrorTranslationTab(state, value) {
        state.error_translation_tab = value
    },
    setItem(state, item) {
        state.item = item
    },
    setTitle(state, value) {
        state.item.agenda_title = value

    },
    setSpeaker(state, value) {
        state.item.speaker = value
    },
    setSlug(state, value) {
        state.item.event_slug = value

    },
    setStartTime(state, value) {
        state.item.start_time = value

    },
    setEndTime(state, value) {
        state.item.end_time = value

    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setTranslations(state, value) {
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null, "description": null }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;

    },

    setSpeakerEmail(state, value) {
        state.new_speaker.email = value;
    },
    setSpeakerSalutation(state, value) {
        state.new_speaker.salutation = value
    },
    setSpeakerFirstName(state, value) {
        state.new_speaker.first_name = value
    },
    setSpeakerLastName(state, value) {
        state.new_speaker.last_name = value
    },
    setNewEmailExists(state, value) {
        state.new_email_exists = value
    },
    setCreateSpeakerBtnTxt(state, value) {
        state.create_speaker_btn_txt = value
    },
    setEventTd(state, value) {
        state.event_id = value
        state.item.event_id = value;
    },
    setSpeakerEventId(state, value) {
        state.new_speaker.event_id = value
    },
    setSpeakerCretedBy(state, value) {
        state.new_speaker.created_by = value
    },
    setCreateDialog(state, value) {
        state.create_dialog = value
    },
    setExhibitors(state, value) {
        state.exhibitors = value
    },
    setExhibitor(state, value) {
        state.item.exhibitor = value
    },
    setIsRestricted(state, value) {
        state.item.is_restricted = value
    },
    setMinutes(state, value) {
        state.item.minutes = value
    },
    setQaType(state, value) {
        state.item.qa_type = value
    },
    setIsRating(state, value) {
        state.item.is_rating = value
    },
    setIsScreenSharing(state, value) {
        state.item.is_screen_sharing = value
    },
    setIsLiveStreaming(state, value) {
        state.item.is_live_streaming = value
    },
    setStreamUrl(state, value) {
        state.item.stream_url = value
    },
    setScheduleStatus(state, value) {
        state.item.schedule_status = value
    },
    setIsBackgroundPicture(state, value) {
        state.item.is_background_picture = value
    },
    setVideo(state, value) {
        state.item.video = value
    },
    setDocument(state, value) {
        state.item.documents = value
    },
    setScreenLogo(state, value) {
        state.item.screen_logo = value
    },
    setSesssionLogo(state, value) {
        state.item.sesssion_logo = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setSpeakerPicutre(state, value) {
        state.item.speaker_picutre = value
    },
    setPillarLeft1Logo1(state, value) {
        state.item.pillar_left1_logo1 = value
    },
    setPillarLeft1Logo2(state, value) {
        state.item.pillar_left1_logo2 = value
    },
    setPillarLeft1Logo3(state, value) {
        state.item.pillar_left1_logo3 = value
    },
    setPillarLeft2Logo1(state, value) {
        state.item.pillar_left2_logo1 = value
    },
    setPillarLeft2Logo2(state, value) {
        state.item.pillar_left2_logo2 = value
    },
    setPillarLeft2Logo3(state, value) {
        state.item.pillar_left2_logo3 = value
    },
    setPillarRight1Logo1(state, value) {
        state.item.pillar_right1_logo1 = value
    },
    setPillarRight1Logo2(state, value) {
        state.item.pillar_right1_logo2 = value
    },
    setPillarRight1Logo3(state, value) {
        state.item.pillar_right1_logo3 = value
    },
    setPillarRight2Logo1(state, value) {
        state.item.pillar_right2_logo1 = value
    },
    setPillarRight2Logo2(state, value) {
        state.item.pillar_right2_logo2 = value
    },
    setPillarRight2Logo3(state, value) {
        state.item.pillar_right2_logo3 = value
    },
    setBackgroundColor(state, value) {
        state.item.background_color = value
    },
    setBackgroundStripesColor(state, value) {
        state.item.background_stripes_color = value
    },
    setStageColor1(state, value) {
        state.item.stage_color1 = value
    },
    setStageColor2(state, value) {
        state.item.stage_color2 = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setLeft1PillarHeaderColor(state, value) {
        state.item.left1_pillar_header_color = value
    },
    setLeft1PillarBottomColor(state, value) {
        state.item.left1_pillar_bottom_color = value
    },
    setLeft2PillarColor(state, value) {
        state.item.left2_pillar_color = value
    },
    setLeft2PillarColorBottom(state, value) {
        state.item.left2_pillar_color_bottom = value
    },
    setRight1PillarColor(state, value) {
        state.item.right1_pillar_color = value
    },
    setRight1PillarColorBottom(state, value) {
        state.item.right1_pillar_color_bottom = value
    },
    setRight2PillarColor(state, value) {
        state.item.right2_pillar_color = value
    },
    setRight2PillarColorBottom(state, value) {
        state.item.right2_pillar_color_bottom = value
    },
    setInterprefy(state, value) {
        state.item.interprefy = value
    },
    setInterprefyToken(state, value) {
        state.item.interprefy_token = value
    },
    setAwsInterpretation(state, value) {
        state.item.aws_interpretation = value
    },
    setAwsEnableSpeechToSpeech(state, value) {
        state.item.aws_enable_speech_to_speech = value
    },
    setAwsEnableSpeechToText(state, value) {
        state.item.aws_enable_speech_to_text = value
    },
    setChat(state, value) {
        state.item.chat = value
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setCreatedBy(state, value) {
        state.item.created_by = value
    },
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    },
    setLangDescirption(state, value) {
        state.lang_descirption = value
    },
    setLocation(state,value) {
        state.item.location = value
    },
    setTimeZone(state,value) {
        state.item.time_zone = value
    },
    setUTCTime(state, type) {
        if (type == 'start_time') {
           // var m = moment.tz(state.item.start_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
            // convert it to utc
           // var utcTime = m.utc().format('YYYY-MM-DD HH:mm');

           let dateInTimezone = moment.tz(state.item.start_time, state.item.time_zone);

           // Step 2: Convert the moment object to UTC
           let dateInUTC = dateInTimezone.utc();
        
           // Step 3: Save the UTC time and the original timezone
           var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
    
        }
        if (type == 'end_time') {
         //  var m = moment.tz(state.item.end_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
          // var utcTime = m.utc().format('YYYY-MM-DD HH:mm');

          let dateInTimezone = moment.tz(state.item.end_time, state.item.time_zone);

          // Step 2: Convert the moment object to UTC
          let dateInUTC = dateInTimezone.utc();
    
          // Step 3: Save the UTC time and the original timezone
          var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
         
         
        }
        if (type == 'start_time') {
            state.item.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setLocalTime(state, type) {
    console.log("state.item.time_zone",state.item.time_zone);
    console.log("state.item",state.item);
        if (type == 'end_time') {
           let dateFormat = moment.utc(state.item.end_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm:ss');
            state.item.end_time = dateFormat;
        }
        if (type == 'start_time') {
            let dateFormat = moment.utc(state.item.start_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
            state.item.start_time = dateFormat;
           
        }
    },
    setDocumentsSlides(state, value){
        state.item.documents_slides = value;
    }

    
    
   
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
