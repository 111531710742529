<template>
<div class="tabs">
    <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
            <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language
                <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
            
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme Settings</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`" data-bs-toggle="tab">Interpretation Settings</a>
        </li>
    </ul>
    <div class="tab-content">
        <div :id="`language-settings`" class="tab-pane active">
            <div class="snippet general-event-config-snippet">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Language *</label>
                        <v-select name="language"  placeholder="Select language" label="language" @option:selected="updateLaguage" :options="languages" />
                            <ValidationMessages :element="v$.item.translations"/>
                          

                    </div>
                    <div class="col-lg-6">
                        <label>Title *</label>
                        
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="this.selectedLanguageTitle" @input="updateLanguageTitle" />
                        </div>
                        <ValidationMessages :element="v$.item.translations"/>
 
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Description *</label>
                            <div class="kt-input-icon">
                                <textarea rows="3" class="form-control" name="language_description" placeholder="Enter language description" :value="this.selectedLanguageDescirption" @input="updateLanguageDescription"></textarea>
                            </div>
                            <ValidationMessages :element="v$.item.translations"/>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :id="`general-settings`" class="tab-pane">
            <div class="snippet general-event-config-snippet">
                <div class="form-group row">
                    <div class="col-lg-10">
                        <label>Speaker *</label>
                            <v-select placeholder="Select Speaker" label="name" :options="speakers" track-by="id" :modelValue="item.speaker" :reduce="(option) => option.id" @option:selected="updateSpeaker" />
                                <ValidationMessages :element="v$.item.speaker"/>
                        </div>
                    <div class="col-lg-2 newspeaker">
                        <v-dialog v-model="dialog" width="auto" >
                            <template v-slot:activator="{ props }">
                                <v-btn color="primary" v-bind="props">
                                    Add Speaker
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-form v-model="valid">
                                        <v-container>
                                            <v-row>
                                                <h4 class="ml-auto mr-auto text-uppercase">{{ createSpeakerBtnTxt }}</h4>
                                                <div class="col-lg-12 mb-2">
                                                    <label>Salutation *</label>
                                                    <v-select name="salutaion" class="" placeholder="Select salutaion" label="salutaion" @option:selected="updateNewSpeakerSalutation" :options="salutation" v-model="selected_salutation" required/>
                                                </div>

                                                <div class="col-lg-12 mb-2">
                                                    <label>Email *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="email" maxlength="200" placeholder="Enter Email" @input="updateNewSpeakerEmail" />
                                                    </div>
                                                    <span v-if="newEmailExists == true" class="mini_txt">User Already Exist. Do you want to add this user in event?</span>
                                                </div>

                                                <div class="col-lg-12 mb-2">
                                                    <label>First Name *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="first_name" maxlength="200" placeholder="Enter First Name" :value="newSpeaker.first_name" @input="updateNewSpeakerFirstName" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 mb-2">
                                                    <label>Last Name *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="last_name" maxlength="200" placeholder="Enter Last Name" :value="newSpeaker.last_name" @input="updateNewSpeakerLastName" />
                                                    </div>
                                                </div>

                                            </v-row>
                                        </v-container>
   
                                    </v-form>
                             
                                </v-card-text>
                                <v-card-actions class="wrapp">
                                    <!-- <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn> -->
                                    <v-btn type="submit" @click="submitSpeakerForm" class="mt-2 add_spk_btn">{{createSpeakerBtnTxt }}</v-btn>

                                    <v-btn text @click="dialog = false" class="add_spk_btn mt-2 orangebtn">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            
   
                        </v-dialog>
                 
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Start Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                       

                            <input 
                              :min="getEventDatetime(event.start_date)" 
                              :max="getEventDatetime(event.end_date)" 
                              :value="getEventDatetime(item.start_time)" 
                              type="datetime-local" class="form-control" 
                              name="start_date"  @change="updateStartTime" />

                            <span class="input-group-text rounded-0">from</span>
                        </div>
                        <ValidationMessages :element="v$.item.start_time"/>
                       
                    </div>
                    <div class="col-lg-6">
                        <label class>End Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <input 
                              :min="getEventDatetime(event.start_date)" 
                              :max="getEventDatetime(event.end_date)" 
                              :value="getEventDatetime(item.end_time)" 
                              type="datetime-local" class="form-control" 
                              name="start_date"  @change="updateEndTime" />
                            <span class="input-group-text rounded-0">to</span>
                        </div>
                        <ValidationMessages :element="v$.item.end_time"/>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Exhibitor</label>
                        <v-select :clearable="true" class="form-control" placeholder="Select Exhibitor" label="label" :options="exhibitorsList" track-by="id"  @option:selected="updateExhibitor" />
      
                            <span class="error">{{ err_exhibitor }}</span>
                        </div>

                    <div class="col-lg-6">
                        <label>Location</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="location" :value="item.location" maxlength="200" placeholder="Enter event location" @input="updateLocation"  />
                        </div>
                        <span class="error">{{ err_location }}</span>
                    </div>
                </div>

                <div class="form-group row">

                    <div class="col-lcontrollanguage_g-3">
                        <label>Q&A public or private for audience?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="qa_type" :value="item.qa_type" id="customSwitchesEx" class="custom-control-input" @change="UpdateQaType" />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Enable Rating ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsRating" />

                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Screen Sharing ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">

                            <input-switch name="is_screen_sharing" :value="item.is_screen_sharing" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsScreenSharing" />

                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Schedule Status ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="schedule_status" :value="item.schedule_status" id="customSwitchesEx" class="custom-control-input" @change="UpdateScheduleStatus" />

                        </div>
                    </div>

                </div>

                <div class="form-group row">

                    <div class="col-lg-3">
                        <label>Live Streaming ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_live_streaming" :value="item.is_live_streaming" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsLiveStreaming" />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Stream URL</label>
                        <VPopper hover style="display:inline-block">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/live_stream_url.jpg"/>
                            </template>
                          </VPopper>
                        
                        <div class="kt-input-icon">
                            <input type="text" :disabled="stream_disabled == true" class="form-control" name="stream_url" maxlength="200" placeholder="Enter Stream URL" :value="item.stream_url" @input="updateStreamUrl" />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Restric User to Join before session time ?</label>
                        <br />
                        <div class="switch switch-sm switch-primcontrollanguage_ary">
                            <input-switch name="is_restricted" :value="item.is_restricted" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsRestricted" />
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <label>Minutes</label>
                        <div class="kt-input-icon">
                            <input type="number" :disabled="restric_user_disabled == true" class="form-control" name="title" min="0" maxlength="200" placeholder="Enter Minutes" :value="item.minutes" @input="updateMinutes" />
                        </div>
                    </div>

                </div>

                <br />

                <div class="form-group row">
                    <div class="col-lg-6">
                        <DropFiles :file="item.video" accept="video/mp4" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateVideo" @onRemoved="removeVideo" asset_type="video"  @onError="videoErrCb">
                            <template #header>
                                <label>Presentation Video (Max size 500MB and format mp4.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                        <span class="error">{{ err_video }}</span>
                    </div>

                    <div class="col-lg-6">
                        <DropFiles :file="item.documents" accept="application/pdf" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateDocument" @onRemoved="removeDocument" asset_type="documents" @onError="documentsErrCb">
                            <template #header>
                                <label>Presentation Document (Max size 30MB and format pdf.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                        <span class="error">{{ err_documents }}</span>
                    </div>

                </div>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <DropFiles :file="item.screen_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="455X256" @onUploaded="updateScreenLogo" @onRemoved="removeScreenLogo" asset_type="screen_logo"  @onError="screenLogoErrCb">
                            <template #header>
                                <label>Screen Logo * (Size should be 455x256 pixel and format jpg.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                        <span class="error">{{ err_screen_logo }}</span>
                        <ValidationMessages :element="v$.item.screen_logo"/>
                    </div>

                    <div class="col-lg-6">
                        <DropFiles :file="item.sesssion_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="512X512" @onUploaded="updateSesssionLogo" @onRemoved="removeSesssionLogo" asset_type="sesssion_logo" @onError="sesssionLogoErrCb">
                            <template #header>
                                <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                        <span class="error">{{ err_sesssion_logo}}</span>
                    </div>

                </div>

            </div>

        </div>
        <div :id="`theme-settings`" class="tab-pane">
            <div class="row">

                <div class="tabs tabs-vertical tabs-left">

                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item active" role="presentation">
                            <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`" data-bs-toggle="tab">Theme Contents</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_left_1`" :href="`#pillar_left_1`" data-bs-toggle="tab">Pillar Left 1</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_left_2`" :href="`#pillar_left_2`" data-bs-toggle="tab">Pillar Left 2</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_right_1`" :href="`#pillar_right_1`" data-bs-toggle="tab">Pillar Right 1</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_right_2`" :href="`#pillar_right_2`" data-bs-toggle="tab">Pillar Right 2</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div :id="`theme_content`" class="tab-pane active show" role="tabpanel">

                            <div class="snippet general-event-config-snippet">

                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Background Color or Picture ?</label>
                                        <br />
                                        <div class="switch switch-sm switch-primary">
                                            <input-switch name="is_background_picture" :value="item.is_background_picture" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsBackgroundPicture" />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="item.is_background_picture">
                                    <div class="col-lg-12">
                                        <DropFiles :file="item.background_picture" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="1920X470" @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture" asset_type="background_picture" @onError="BackgroundPictureErrCb">
                                            <template #header>
                                                <label>Background Picture (Size should be 1920x470 pixel and format
                                                    JPG.) </label>
                                                    <VPopper hover style="display:inline-block">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/live_stream_url.jpg"/>
                            </template>
                          </VPopper>
                                            </template>
                                            <template #tooltip>
                                                <VTooltip style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #popper>
                                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                    </template>
                                                </VTooltip>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_background_picture}}</span>
                                    </div>
                                </div>

                                <div class="form-group row backgroundcolor_option" v-else>
                                    <div class="col-lg-6">
                                        <label>Background Color</label>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_color" :value="item.background_color" @input="updateBackgroundColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Background Stripes Color</label>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_stripes_color" :value="item.background_stripes_color" @input="updateBackgroundStripesColor" />

                                        </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Stage Color 1</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="stage_color1" :value="item.stage_color1" @input="updateStageColor1" />

                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Stage Color 2</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="stage_color2" :value="item.stage_color2" @input="updateStageColor2" />

                                        </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Bottom Background Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_color_bottom" :value="item.background_color_bottom" @input="updateBackgroundColorBottom" />

                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <DropFiles :file="item.speaker_picutre" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="59X214" @onUploaded="updateSpeakerPicutre" @onRemoved="removeSpeakerPicutre" asset_type="speaker_picutre" @onError="SpeakerPicutreErrCb">
                                            <template #header>
                                                <label>Speaker Picture (Size should be 59x214 pixel and format
                                                    PNG.)</label>
                                                    <VPopper hover style="display:inline-block">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_speaker_picture.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                            <template #tooltip>
                                                <VTooltip style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #popper>
                                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                    </template>
                                                </VTooltip>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_speaker_picutre}}</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div :id="`pillar_left_1`" class="tab-pane" role="tabpanel">
                            <p>Pillar Left 1</p>

                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                        <input type="color" class="form-control" name="left1_pillar_header_color" :value="item.left1_pillar_header_color" @input="updateLeft1PillarHeaderColor" />


                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                        <input type="color" class="form-control" name="left1_pillar_bottom_color" :value="item.left1_pillar_bottom_color" @input="updateleft1PillarBottomColor" />

                                    </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-12">

                                        <DropFiles :file="item.pillar_left1_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo1" @onRemoved="removePillarLeft1Logo1" asset_type="pillar_left1_logo1" @onError="pillarLeft1Logo1ErrCb">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_left1_logo1}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_left1_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo2" @onRemoved="removePillarLeft1Logo2" asset_type="pillar_left1_logo2" @onError="pillarLeft1Logo2ErrCb">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        
                                        <span class="error">{{ err_pillar_left1_logo2}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_left1_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo3" @onRemoved="removePillarLeft1Logo3" asset_type="pillar_left1_logo3" @onError="pillarLeft1Logo3ErrCb">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_left1_logo3}}</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div :id="`pillar_left_2`" class="tab-pane" role="tabpanel">
                            <p>Pillar Left 2</p>

                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                        <input type="color" class="form-control" name="left2_pillar_color" :value="item.left2_pillar_color" @input="updateLeft2PillarColor" />
                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                        <input type="color" class="form-control" name="left2_pillar_color_bottom" :value="item.left2_pillar_color_bottom" @input="updateLeft2PillarColorBottom" />

                                    </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_left2_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo1" @onRemoved="removePillarLeft2Logo1" asset_type="pillar_left2_logo1" @onError="pillarLeft2Logo1ErrCb">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_left2_logo1}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_left2_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo2" @onRemoved="removePillarLeft2Logo2" asset_type="pillar_left2_logo2" @onError="pillarLeft2Logo2ErrCb">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_left2_logo2}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_left2_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo3" @onRemoved="removePillarLeft2Logo3" asset_type="pillar_left2_logo3" @onError="pillarLeft2Logo3ErrCb">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_left2_logo3}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div :id="`pillar_right_1`" class="tab-pane" role="tabpanel">
                            <p>Pillar Right 1</p>

                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right1_pillar_color" :value="item.right1_pillar_color" @input="updateRight1PillarColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right1_pillar_color_bottom" :value="item.right1_pillar_color_bottom" @input="updateRight1PillarColorBottom" />
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_right1_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo1" @onRemoved="removePillarRight1Logo1" asset_type="pillar_right1_logo1" @onError="pillarRight1Logo1ErrCb">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right1_logo1}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_right1_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo2" @onRemoved="removePillarRight1Logo2" asset_type="pillar_right1_logo2" @onError="pillarRight1Logo2ErrCb">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right1_logo2}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_right1_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo3" @onRemoved="removePillarRight1Logo3" asset_type="pillar_right1_logo3" @onError="pillarRight1Logo3ErrCb">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right1_logo3}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div :id="`pillar_right_2`" class="tab-pane" role="tabpanel">
                            <p>Pillar Right 2</p>
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right2_pillar_color" :value="item.right2_pillar_color" @input="updateRight2PillarColor" />

                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right2_pillar_color_bottom" :value="item.right2_pillar_color_bottom" @input="updateRight2PillarColorBottom" />

                                        </div>
                                    </div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_right2_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo1" @onRemoved="removePillarRight2Logo1" asset_type="pillar_right2_logo1" @onError="pillarRight2Logo1ErrCb">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right2_logo1}}</span>
                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="item.pillar_right2_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo2" @onRemoved="removePillarRight2Logo2" asset_type="pillar_right2_logo2" @onError="pillarRight2Logo2ErrCb">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right2_logo2}}</span>
                                    </div>

                                    <div class="col-lg-12">

                                        <DropFiles :file="item.pillar_right2_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo3" @onRemoved="removePillarRight2Logo3" asset_type="pillar_right2_logo3" @onError="pillarRight2Logo3ErrCb">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                            </template>
                                        </DropFiles>
                                        <span class="error">{{ err_pillar_right2_logo3}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div :id="`interpretify-settings`" class="tab-pane">

            <div class="snippet general-event-config-snippet">

                <div class="form-group row">

                    <div class="col-lg-4">
                        <label>Enable "Interprefy" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.interprefy" id="customSwitchesEx" class="custom-control-input" @change="updateInterprefy" />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label>Interprefy token</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter event title" :disabled="interprefy_disabled == true" @change="updateInterprefyToken"/>
                        </div>
                    </div>

                </div>

                <div class="form-group row">

                    <div class="col-lg-4">
                        <label>Enable "AWS" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.aws_interpretation" id="customSwitchesEx" class="custom-control-input" @change="updateAwsInterpretation" />
                       </div>
                    </div>

                    <div class="col-lg-4">
                        <label>Enable "Speech to Speech"</label>
                        <div class="kt-input-icon">
                          <input-switch name="speech_to_speech" :value="item.aws_enable_speech_to_speech" id="enableSpeechToSpeech" class="custom-control-input" @change="updateAwsEnableSpeechToSpeech" :styleDisable="this.aws_interprefy_disabled" />
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <label>Enable "Speech to Text"</label>
                        <div class="kt-input-icon">
                          <input-switch name="speech_to_text" :value="item.aws_enable_speech_to_text" id="enableSpeechToText" class="custom-control-input" @change="updateAwsEnableSpeechToText" :styleDisable="this.aws_interprefy_disabled" />
                        </div>
                    </div>

                </div>

                <div class="form-group rcontrollanguage_ow">

                    <div class="col-lg-4">
                        <label>Enable "Chat" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx" class="custom-control-input" @change="updateChat" />
                       </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="card-body text-right mt-3 mr-5">
                     
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm(user)">Save</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      
        </div>

    </div>
    

</div>
<FlashMessage group="lp_create"/>

</template>

<script>
const maxVideoFileSize = 524288000; // Max file size

import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {
    mapGetters,
    mapActions
} from 'vuex';
import $ from 'jquery';
import {
    upload
} from "@/services/FileUploader";
import {
    v4 as uuidv4
} from 'uuid';
import _ from "lodash";
import moment from 'moment';
import Exhibitors from '@/store/modules/Exhibitors';
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'


export default {
    data() {
        return {
            moduleId: ObjectID(),
            dialog: false,
            translation: [],
            languageKey: '',
            languageTitle: '',
            languageDescription: '',
            stream_disabled: true,
            restric_user_disabled: true,
            interprefy_disabled: true,
            aws_interprefy_disabled: true,
            chat_disabled: true,
            salutation : ['Mr', 'Ms', 'Mrs'],
            selected_salutation : null,

            error_translation_tab:false,
            error_general_tab:false,
            error_theme_tab:false,
            error_interpretation_tab:false,

            err_translations  :null,
            err_speaker: null,
            err_exhibitor: null,
            err_start_time : null, 
            err_end_time : null,
            err_location: null,
            err_is_restricted: false,
            err_minutes :  null,
            err_qa_type: null,
            err_is_rating: null,
            err_is_screen_sharing: null,
            err_is_live_streaming: null,
            err_stream_url: null,
            err_schedule_status: null,
            err_video:null,
            err_documents:null,
            err_screen_logo:null,
            err_sesssion_logo:null,
            err_is_background_picture: null,
            err_background_picture: null,
            err_background_color: null,
            err_background_stripes_color: null,
            err_stage_color1: null,
            err_stage_color2: null,
            err_background_color_bottom: null,
            err_speaker_picutre: null,
            err_pillar_left1_logo1: null,
            err_pillar_left1_logo2: null,
            err_pillar_left1_logo3: null,
            err_left1_pillar_header_color: null,
            err_left1_pillar_bottom_color: null,
            err_pillar_left2_logo1: null,
            err_pillar_left2_logo2: null,
            err_pillar_left2_logo3: null,
            err_left2_pillar_color: null,
            err_left2_pillar_color_bottom: null,
            err_pillar_right1_logo1: null,
            err_pillar_right1_logo2: null,
            err_pillar_right1_logo3: null,
            err_right1_pillar_color: null,
            err_right1_pillar_color_bottom: null,
            err_pillar_right2_logo1: null,
            err_pillar_right2_logo2: null,
            err_pillar_right2_logo3: null,
            err_right2_pillar_color: null,
            err_right2_pillar_color_bottom: null,
            err_interprefy: null,
            err_interprefy_token: null,
            err_aws_interpretation: null,
            err_aws_enable_speech_to_speech: null,
            err_aws_enable_speech_to_text: null,
            err_chat: null,
            exhibitorsList: [],
            selectedLanguage: null,
   
        }
        
    },
   
  setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            item: {
               
                translations: {
                    required
                },
                
                speaker: {
                    required,
                },
                start_time: {
                    required,
                },
                end_time: {
                    required,
                },
                screen_logo:{
                    required,
                }
            }
        };
    },

    props: {
        event: {
            type: Object,
            default () {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                    langauge:null
                }
            }
        },
        languages: {
            type: Array,
        }

    },
    watch: {
        'newSpeaker.salutation': function(item) {
            if (item !== undefined) {
                this.selected_salutation = item;
            }
        },
      'exhibitors': function (items) {
          this.exhibitorsList = _.map(items, (item) => {
            const translate = _.get(_.head(item.translations), 'name');
            return {
              label: translate,
              //_id: item._id,
              id:item._id
            }
          })
      }
    },
    name: 'LivePresentationCreateForm',
    components: {
        InputSwitch,
        ValidationMessages
    },
   
    computed: {
        ...mapGetters('LivePresentationsSingle', ['error_translation_tab','error_general_tab','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading', 'rolesAll', 'companiesAll',  'newSpeaker', 'createSpeakerBtnTxt', 'newEmailExists', 'eventID', 'createDialog']),
        ...mapGetters('LivePresentationsIndex', ['speakers','event_timezone']),
        ...mapGetters('ExhibitorsIndex', {exhibitors: "all"})
    },
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.lp_create;
        delete this.$flashMessage.groups.lp_create_speaker;
  
        
    },
    created(){
        this.setSpeakerEventId(this.event._id);
    },
    mounted() {

        this.setEventTd(this.event._id);
        this.checkPermission();
        let user = JSON.parse(localStorage.getItem('user'));
        this.setCreatedBy(`${user.first_name} ${user.last_name}`)
        this.fetchData();
        this.fetchExhibitorsData(this.event._id);
        const switchers = document.querySelectorAll('.general-event-config-snippet input[data-plugin-ios-switch]')
        switchers.forEach(item => {
            const elem = new window.theme.PluginIOS7Switch($(item))
        })
        this.setStartTime(this.event.start_date);
        this.setEndTime(this.event.end_date);
        this.updateTimeZone();


    },
    methods: {
        ...mapActions("AuthenticationsIndex",["checkLivePresentationsSubscrptions"]),
   
        ...mapActions('LivePresentationsIndex', {
            fetchAllData: 'fetchData',
        }),
        ...mapActions('LivePresentationsSingle', [
            'storeSpeakerData',
            'storeData',
            'fetchData',
            'checkEmailExists',
            'resetState',
            'setTranslations',
            'setEventTd',
            'setSpeaker',
            'setSpeakerSalutation',
            'setSpeakerEmail',
            'setSpeakerEventId',
            'setSpeakerFirstName',
            'setSpeakerLastName',
            'setSpeakerCretedBy',
            'setExhibitor',
            'setIsRestricted',
            'setMinutes',
            'setQaType',
            'setIsRating',
            'setIsScreenSharing',
            'setIsLiveStreaming',
            'setStreamUrl',
            'setScheduleStatus',
            'setIsBackgroundPicture',
            'setVideo',
            'setDocument',
            'setScreenLogo',
            'setSesssionLogo',
            'setBackgroundPicture',
            'setSpeakerPicutre',
            'setPillarLeft1Logo1',
            'setPillarLeft1Logo2',
            'setPillarLeft1Logo3',
            'setPillarLeft2Logo1',
            'setPillarLeft2Logo2',
            'setPillarLeft2Logo3',
            'setPillarRight1Logo1',
            'setPillarRight1Logo2',
            'setPillarRight1Logo3',
            'setPillarRight2Logo1',
            'setPillarRight2Logo2',
            'setPillarRight2Logo3',
            'setBackgroundColor',
            'setStageColor1',
            'setStageColor2',
            'setBackgroundStripesColor',
            'setLeft1PillarHeaderColor',
            'setLeft1PillarBottomColor',
            'setLeft2PillarColor',
            'setLeft2PillarColorBottom',
            'setRight1PillarColor',
            'setRight1PillarColorBottom',
            'setRight2PillarColor',
            'setRight2PillarColorBottom',
            'setInterprefy',
            'setInterprefyToken',
            'setAwsInterpretation',
            'setAwsEnableSpeechToSpeech',
            'setAwsEnableSpeechToText',
            'setChat',
            'setStartTime',
            'setEndTime',
            'setCreatedBy',
            'setLangKey',
            'setLangTitle',
            'setLangDescirption',
            'setLocation',
            'setTimeZone',
            'setIsNew',
            'setErrorTranslationTab',
            'setErrorGeneralTab',
            'setBackgroundColorBottom',
            'setDocumentsSlides'
       

        ]),
      ...mapActions('ExhibitorsIndex', {fetchExhibitorsData: "fetchData"}),
      showFlashMessage(message) {
      this.flashMessage = message;
      this.snackbar = true;
    },
    updateEventId(e){
        console.log("set event value",e);
        this.setEventTd(e.target.value);
    },
        fileLoad(e) {
            const file = e.target.files[0];
            const id = uuidv4();
            const ff = upload(file, id, "users", null, file.type);
            ff.then((resp) => {
                console.log(resp)
            }).catch((e) => {
                console.error(e)
                this.$flashMessage.show({
                    group: 'lp_create',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong.'
                });
            })
        },
      
        updateSpeaker(value) {
            this.setSpeaker(value.id);
        },
        async submitSpeakerForm() {
    
//             const isFormCorrect = await this.v$.$validate()

            // // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
  //          if (!isFormCorrect) return

            this.storeSpeakerData()
                .then(async () => {
                    this.$flashMessage
                            .show({
                                    group   : 'lp_create',
                                    type    : 'success',
                                    title   : 'Success',
                                    time    : 1200,
                                    html    : '<ul><li>Speaker added successfully.</li></ul>',
                                });
                    await  this.fetchAllData(this.eventID);
                    this.dialog = false;
                })
                .catch((error) => {
                    let html =  gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'lp_create',
                                type: 'error',
                                title: 'Failed',
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                })
        },
        
        async  submitForm() {
            const isFormCorrect = await this.v$.$validate();
          let validateTranlation = await this.checkLanguageValidation();
          this.error_general_tab = false;
          this.error_translation_tab = false;
          this.error_theme_tab = false;
          this.error_interpretation_tab = false;
          this.setErrorGeneralTab(false)
          this.setErrorTranslationTab(false);

          let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "speaker"){
                generalError.push("<li>Speaker is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }

            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
                if (!_.isEmpty(validateTranlation)) {
           
                  this.error_translation_tab = true;
                  this.setErrorTranslationTab(true);
                  this.$flashMessage.show({
                      group: 'lp_create',
                      type: 'error',
                      time:1200,
                      title: 'Failed',
                      html: `<ul>${validateTranlation.join("")}</ul>`
                  });
                return ;
              }          
            } 
            if (!isFormCorrect ) {
                
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'lp_create',
                        type    : 'error',
                     //   time    : 1200,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }
               // if (!isFormCorrect) return
                this.storeData()
                    .then(async (resp) => {
                        this.$flashMessage
                            .show({
                                    group   : 'lp_index',
                                    type    : 'success',
                                    title   : 'Success',
                                    time    : 1200,
                                    html    : '<ul><li>Live Presentations created successfully.</li></ul>',
                                });
                        
                        await  this.fetchAllData(resp.data.data.event_id);
                    }) .catch(error => {
                    let html =  gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'lp_create',
                            type: 'error',
                            title: 'Failed',
                           // time:1200,
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }
                    
                });
        },
        
       
        updateLaguage(value) {
            this.err_translations = null;
            this.setLangKey(value);
            this.languageKey = value;
            this.setLangTitle(null);
            this.setLangDescirption(null);
            if (_.isUndefined(_.find(this.translation, {
                'key': value,
            }))) {
                let obj = {
                    "key": `${value}`,
                    "title": null,
                    "description": null
                }
                this.translation.push(obj);
            } else {
                let selectedLang = _.find(this.item.translations, {
                    'key': this.selectedLanguageKey,
                })
                if (!_.isUndefined(selectedLang)) {
                    this.setLangTitle(selectedLang.title);
                    this.setLangDescirption(selectedLang.description);
                }
            }

        },
        updateLanguageTitle(e) {
            this.err_translations = null;
            if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'title',
                    update_value: e.target.value
                });

            }
            this.setLangTitle(e.target.value);
        },
        updateLanguageDescription(e) {
            if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'description',
                    update_value: e.target.value
                });
            }
            this.setLangDescirption(e.target.value);
        },
        updateNewSpeakerEmail(e) {

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                this.setSpeakerEmail(e.target.value);
                this.setSpeakerEventId(this.eventID);
                this.setSpeakerCretedBy("63da6ecb083ba9aebc3b35f5");
                this.checkEmailExists()
                    .then()
                    .catch((error) => {
                        console.error(error)
                        this.$flashMessage.show({
                            group: 'user_create',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong.'
                        });
                    })
            } else {
                this.setSpeakerFirstName(null);
                this.setSpeakerLastName(null);
            }
        },
        updateNewSpeakerSalutation(value) {
            this.setSpeakerSalutation(value);
        },
        updateNewSpeakerFirstName(e) {
            this.setSpeakerFirstName(e.target.value);
        },
        updateNewSpeakerLastName(e) {
            this.setSpeakerLastName(e.target.value);
        },
        updateExhibitor(value) {
            this.setExhibitor(value.id);
        },
        UpdateIsRestricted(e) {
            this.restric_user_disabled = true;
            if (e.target.checked) {
                this.restric_user_disabled = false;
            }

            this.setIsRestricted(e.target.checked);
        },
        UpdateQaType(e) {
            this.setQaType(e.target.checked);
        },
        UpdateIsRating(e) {
            this.setIsRating(e.target.checked);
        },
        UpdateIsScreenSharing(e) {
            this.setIsScreenSharing(e.target.checked);
        },
        UpdateIsLiveStreaming(e) {
            this.stream_disabled = true;
            if (e.target.checked) {
                this.stream_disabled = false;
            }
            this.setIsLiveStreaming(e.target.checked);
        },
        UpdateScheduleStatus(e) {
            this.setScheduleStatus(e.target.checked);
        },
        UpdateIsBackgroundPicture(e) {
            this.setIsBackgroundPicture(e.target.checked);
        },
        updateVideo(info, data) {
            this.err_video = null;
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key = _.get(this.item.video, 'key', null);
            const size = _.get(info, 'file.size', 0)
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
              }
            this.setVideo({
                old_key:  data.data.key?data.data.key:data.data.Key,
                temp_key: info.id,
                key: data.data.key?data.data.key:data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeVideo(e) {
            this.err_video = null;
            this.setVideo(null);
        },
        updateDocument(info, data) {
            this.err_documents = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0);
            console.log("Size****",size);
            this.setDocument({
                old_key:    data.data.key,
                key:        data.data.key,
                temp_key:   info.id,
                size:       size.toString(),
                extension:  extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeDocument(e) {
            this.err_documents = null;
            this.setDocument(null);
            this.setDocumentsSlides([]);
        },
        updateScreenLogo(info, data) {
            this.err_screen_logo = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.screen_logo, 'key', null);
            const size = _.get(info, 'file.size', 0)
            this.setScreenLogo({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeScreenLogo(e) {
            this.err_screen_logo = null;
            this.setScreenLogo(null);
        },
        updateSesssionLogo(info, data) {
            this.err_sesssion_logo = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.session_logo, 'key', null);
            const size = _.get(info, 'file.size', 0)
        
            this.setSesssionLogo({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeSesssionLogo(e) {
            this.err_sesssion_logo = null;
            this.setSesssionLogo(null);
        },
        updateBackgroundPicture(info, data) {
            this.err_background_picture = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.background_picture, 'key', null);
            const size = _.get(info, 'file.size', 0)
       
            this.setBackgroundPicture({
                old_key     : data.data.key,
                temp_key    : info.id,
                key         : data.data.key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt  : '2023.01.01',
            });

            this.$forceUpdate();
        },
        removeBackgroundPicture(e) {
            this.err_background_picture = null;
            this.setBackgroundPicture(null);
        },
        updateSpeakerPicutre(info, data) {
            this.err_speaker_picutre = null;
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.speaker_picutre, 'key', null);
            const size = _.get(info, 'file.size', 0)
       
            this.setSpeakerPicutre({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });
            
            this.$forceUpdate();
        },
        removeSpeakerPicutre(e) {
            this.err_speaker_picutre = null;
            this.setSpeakerPicutre(null);
        },
        updatePillarLeft1Logo1(info, data) {
            this.err_pillar_left1_logo1 = null;
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0)
       

            this.setPillarLeft1Logo1({
                old_key     : data.data.key,
                temp_key    : info.id,
                key         : data.data.key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  :  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt  : '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo1(e) {
            this.err_pillar_left1_logo1 = null;
            this.setPillarLeft1Logo1(null);
        },
        updatePillarLeft1Logo2(info, data) {
            this.err_pillar_left1_logo2 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
       
            this.setPillarLeft1Logo2({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo2(e) {
            this.err_pillar_left1_logo2 = null;
            this.setPillarLeft1Logo2(null);
        },
        updatePillarLeft1Logo3(info, data) {
            this.err_pillar_left1_logo3 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarLeft1Logo3({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo3(e) {
            this.err_pillar_left1_logo3 = null;
            this.setPillarLeft1Logo3(null);
        },
        updatePillarLeft2Logo1(info, data) {
            this.err_pillar_left2_logo1 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarLeft2Logo1({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo1(e) {
            this.err_pillar_left2_logo1 = null;
            this.setPillarLeft2Logo1(null);
        },
        updatePillarLeft2Logo2(info, data) {
            this.err_pillar_left2_logo2 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarLeft2Logo2({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo2(e) {
            this.err_pillar_left2_logo2 = null;
            this.setPillarLeft2Logo2(null);
        },
        updatePillarLeft2Logo3(info, data) {
            this.err_pillar_left2_logo3 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarLeft2Logo3({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo3(e) {
            this.err_pillar_left2_logo3 = null;
            this.setPillarLeft2Logo3(null);
        },
        updatePillarRight1Logo1(info, data) {

            this.err_pillar_right1_logo1 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight1Logo1({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo1(e) {
            this.err_pillar_right1_logo1 = null;
            this.setPillarRight1Logo1(null);
        },
        updatePillarRight1Logo2(info, data) {
            this.err_pillar_right1_logo2 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight1Logo2({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo2(e) {
            this.err_pillar_right1_logo2 = null;
            this.setPillarRight1Logo2(null);
        },
        updatePillarRight1Logo3(info, data) {
            this.err_pillar_right1_logo3 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight1Logo3({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo3(e) {
            this.err_pillar_right1_logo3 = null;
            this.setPillarRight1Logo3(null);
        },
        updatePillarRight2Logo1(info, data) {
            this.err_pillar_right2_logo1 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight2Logo1({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight2Logo1(e) {
            this.err_pillar_right2_logo1 = null;
            this.setPillarRight2Logo1(null);
        },
        updatePillarRight2Logo2(info, data) {
            this.err_pillar_right2_logo2 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight2Logo2({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        updateLocation(e){
            this.setLocation(e.target.value)
        },
        removePillarRight2Logo2(e) {
            this.err_pillar_right2_logo2 = null;
            this.setPillarRight2Logo2(null);
        },
        updatePillarRight2Logo3(info, data) {

            this.err_pillar_right2_logo3 = null;
            const extension = data.data.key.split('.').pop()
            const size = _.get(info, 'file.size', 0)
            this.setPillarRight2Logo3({
                old_key: data.data.key,
                temp_key: info.id,
                key: data.data.key,
                size : size.toString(),
                extension: extension,
                uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight2Logo3(e) {
            this.err_pillar_right2_logo3 = null;
            this.setPillarRight2Logo3(null);
        },
        updateStreamUrl(e) {
            this.setStreamUrl(e.target.value);
        },
        updateMinutes(e) {
            this.setMinutes(e.target.value);
        },
        updateBackgroundColor(e) {
            this.setBackgroundColor(e.target.value);
        },
        updateBackgroundStripesColor(e) {
            this.setBackgroundStripesColor(e.target.value);
        },
        updateStageColor1(e) {
            this.setStageColor1(e.target.value);
        },
        updateStageColor2(e) {
            this.setStageColor2(e.target.value);
        },
        updateBackgroundColorBottom(e) {
            this.setBackgroundColorBottom(e.target.value);
        },
        updateLeft1PillarHeaderColor(e) {
            this.setLeft1PillarHeaderColor(e.target.value);
        },
        updateleft1PillarBottomColor(e) {
            this.setLeft1PillarBottomColor(e.target.value);
        },
        updateLeft2PillarColor(e) {
            this.setLeft2PillarColor(e.target.value);
        },
        updateLeft2PillarColorBottom(e) {
            this.setLeft2PillarColorBottom(e.target.value);
        },
        updateRight1PillarColor(e) {
            this.setRight1PillarColor(e.target.value);
        },
        updateRight1PillarColorBottom(e) {
            this.setRight1PillarColorBottom(e.target.value);
        },
        updateRight2PillarColor(e) {
            this.setRight2PillarColor(e.target.value);
        },
        updateRight2PillarColorBottom(e) {
            this.setRight2PillarColorBottom(e.target.value);
        },
        updateInterprefy(e) {
            
            this.interprefy_disabled = true;
            if (e.target.checked) {
                this.interprefy_disabled = false;
                this.setAwsInterpretation(false)
                this.aws_interprefy_disabled =true;
                this.setAwsEnableSpeechToSpeech(false);
                this.setAwsEnableSpeechToText(false);
            }
            this.setInterprefy(e.target.checked);

        },
        updateInterprefyToken(e) {
            this.setInterprefyToken(e.target.value);
        },
        updateAwsInterpretation(e) {
            this.aws_interprefy_disabled = true;
            if (e.target.checked) {
                this.aws_interprefy_disabled = false;
            }
            if(e.target.checked){
              this.setInterprefy(false);
              this.interprefy_disabled= true
            }
            //interprefy
            this.setAwsInterpretation(e.target.checked);
        },
        updateAwsEnableSpeechToSpeech(e) {
            this.setAwsEnableSpeechToSpeech(e.target.checked);
        },
        updateAwsEnableSpeechToText(e) {
            this.setAwsEnableSpeechToText(e.target.checked);
        },
        
        updateChat(e) {
            this.setChat(e.target.checked);
        },
        updateStartTime(e) {
            this.err_start_time = null;
            this.setStartTime(e.target.value);
        },
        updateEndTime(e) {
            this.err_end_time = null;
            this.setEndTime(e.target.value);
        },
        showCbError(){
            this.$flashMessage.show({
                    group   : 'lp_create',
                    type    : 'error',
                    title   : 'Failed',
                    time    : 1000,
                    html    : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
                  });
        },          
        videoErrCb(errList) {
            this.err_video = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        documentsErrCb(errList) {
            this.err_documents = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        screenLogoErrCb(errList) {
            this.err_screen_logo = _.toString(_.map(errList[0]['errors'], 'message')) 
            this.showCbError();
        },
        BackgroundPictureErrCb(errList) {
            this.err_background_picture = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        sesssionLogoErrCb(errList) {
            this.err_sesssion_logo = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        SpeakerPicutreErrCb(errList) {
            this.err_speaker_picutre = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft1Logo1ErrCb(errList) {
            this.err_pillar_left1_logo1 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft1Logo2ErrCb(errList) {
            this.err_pillar_left1_logo2 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft1Logo3ErrCb(errList) {
            this.err_pillar_left1_logo3 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft2Logo1ErrCb(errList) {
            this.err_pillar_left2_logo1 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft2Logo2ErrCb(errList) {
            this.err_pillar_left2_logo2 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarLeft2Logo3ErrCb(errList) {
            this.err_pillar_left2_logo3 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight1Logo1ErrCb(errList) {
            this.err_pillar_right1_logo1 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight1Logo2ErrCb(errList) {
            this.err_pillar_right1_logo2 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight1Logo3ErrCb(errList) {
            this.err_pillar_right1_logo3 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight2Logo1ErrCb(errList) {
            this.err_pillar_right2_logo1 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight2Logo2ErrCb(errList) {
            this.err_pillar_right2_logo2 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        pillarRight2Logo3ErrCb(errList) {
            this.err_pillar_right2_logo3 = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },
        updateTimeZone(){
         this.setTimeZone(this.event.time_zone)
        },
        checkPermission(){
            this.checkLivePresentationsSubscrptions(this.$route.params.event_id)
            .then((res)=>{
            }) .catch((error) => {
                console.log("Error",error);
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group : 'lp_index',
                    type  : 'error',
                    title : 'Error',
                    time  : 1500,
                    text  : html,
                    html  : html
                });

                this.setIsNew(false);
                this.fetchData(this.$route.params.event_id);
            
                    
            });
        },
        async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },
        getEventDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm');
        },
        cancelClick() {
            this.resetState()
        }

    },

}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
    border-bottom: 0px solid #ccc;
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }
}

.setting_wrapper {
    float: right;

    a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;
    }
}

.toggle label {
    color: #000 !important;
}

.drop-files {
    margin-top: 10px;
}

.newspeaker {
    margin-top: 20px;
}

.newspeaker button {
    width: 100%;
}

.add_spk_btn {
    background-color: #0088CC;
    color: #fff;
}

.mini_txt {
    font-size: 0.8em;
    color: #585252;
    font-style: italic;
    margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
    border-radius: 15px;
    padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
    border-left-color: #0088CC;
}

.orangebtn {
    background-color: orange;
}

.wrapp {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}
.flash-content {
    z-index: 10;
}

.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
    position: absolute;
    top: -53px;
    left: 50px;
    width: 300px;
    height: auto;
}


</style>
