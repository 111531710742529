import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import $ from "jquery";
import moment from "moment"

function initialState() {
    return {
        eventusersAll   :[],
        boothsAll       :[],
        event_timezone  : null,
        meetingId       :null,
        room_seetings   : [],
        lang_key: null,
        lang_title: null,
        lang_descirption: null,
        error_translation_tab :false,
        error_general_tab :false,
        item: {
            translations: [],
            event_id: null,
            booth: null,
            hours_per_day: null,
            time_slot: null,
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            is_no_break: false,
            break_start_time: null,
            break_end_time: null,
            logo: null,
            room_logo: null,
            banner: null,
            screen_sharing: false,
            limited_sessions: false,
            no_of_sessions: null,
            show_text: false,
            room_description: null,
            document1: null,
            document2: null,
            document3: null,
            background_color: '#999999',
            background_stripes_color: '#14ac35',
            is_background_picture: true,
            background_picture: null,
            interprefy: false,
            interprefy_token: null,
            aws_interpretation: false,
            aws_enable_speech_to_speech: null,
            aws_enable_speech_to_text: null,
            room_days: [],
            is_live_stream: false,
            stream_url: null,
            room_settings: [],
            settings: [],
            has_meetings: false,
            chat: false,
            created_by:"",
            document1_slides:[],
            document2_slides:[],
            document3_slides:[],
           
        },
        
        eventsAll: {
            start_date: null,
            end_date: null,
            language: [],
            plan: {
                is_aws_interpretation_active: false,
                is_chat_interpretation_active: false,
                is_interprefy_interpretation_active: false
            }
        },
        loading: false,
        is_new: false,
        is_editing: false,
        event_days: []
   
  
       
    }
}

const getters = {
    item                        : state => state.item,
    eventsAll                   : state => state.eventsAll,
    eventusersAll               : state => state.eventusersAll,
    boothsAll                   : state => state.boothsAll,
    event_timezone              : state => state.event_timezone,
    skyboxes                    : state => state.skyboxes,
    environments                : state => state.environments,
    event_type                  : state => state.event_type,
    event_days                  : state => state.event_days,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
    loading                     : state => state.loading,
    selectedLanguageKey         : state => state.lang_key,
    selectedLanguageTitle       : state => state.lang_title,
    selectedLanguageDescirption : state => state.lang_descirption,
    error_translation_tab       : state => state.error_translation_tab,
    error_general_tab           : state => state.error_general_tab,
    meetingId                   : state => state.meetingId
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        const params = _.cloneDeep(state.item);
            if (!_.isEmpty(state.item.room_settings)) {
                for (let index in state.item.room_settings) {
                    for (let settings in state.item.room_settings[index]) {
                        if (settings === 'start_time') {
                            var start_time = state.item.room_settings[index].room_day + " " + state.item.room_settings[index][settings];
                            var localTime = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm');

                            
                            var utcTime = moment(localTime).tz('UTC').format('HH:mm');
                            console.log("startime",utcTime," state.event_timezone", state.event_timezone);
                            params.room_settings[index][settings] = utcTime;

                            let formatRoomDay = moment(localTime).tz('UTC').format('YYYY-MM-DD');
                            console.log("formatRoomDayformatRoomDay",formatRoomDay);
                            params.room_settings[index].room_day = formatRoomDay;
                            
                        } else if (settings === 'end_time') {
                            var end_time = state.item.room_settings[index].room_day + " " + state.item.room_settings[index][settings];
                            var localTime = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
                            var utcTime = moment(localTime).tz('UTC').format('HH:mm');
                            params.room_settings[index][settings] = utcTime;
                            console.log("end time",utcTime," state.event_timezone", state.event_timezone);


                           // let formatRoomDay = moment(localTime).tz('UTC').format('YYYY-MM-DD');
                           // console.log("formatRoomDayformatRoomDay",formatRoomDay);
                            //params.room_settings[index].room_day = formatRoomDay;

                       
                        
                        } else {
                            params.room_settings[index][ settings ] =  state.item.room_settings[index][settings]
                            
                        }

                    }
                }
            }
            commit('setUTCTime', 'start_time');
            commit('setUTCTime', 'end_time');
            commit('setUTCTime', 'break_start_time');
            commit('setUTCTime', 'break_end_time');

            params.start_time       = state.item.db_start_time;
            params.end_time         = state.item.db_end_time;
            params.break_start_time = state.item.db_break_start_time;
            params.break_end_time   = state.item.db_break_end_time;
            params.start_date       = state.item.start_date;
            params.end_date         = state.item.end_date;
        

            if (params.logo) {
                params.logo = {
                    key         : params.logo.key,
                    old_key     : params.logo.key,
                    temp_key    : params.logo.temp_key,
                    size        : params.logo.size,
                    extension   : params.logo.extension,
                    uploadedBy  : params.logo.uploadedBy,
                    uploadedAt  : params.logo.uploadedAt,
                }
            }
            if (params.background_picture) {
                params.background_picture = {
                    key         : params.background_picture.key,
                    old_key     : params.background_picture.key,
                    temp_key    : params.background_picture.temp_key,
                    size        : params.background_picture.size,
                    extension   : params.background_picture.extension,
                    uploadedBy  : params.background_picture.uploadedBy,
                    uploadedAt  : params.background_picture.uploadedAt,
                }
            }
            if (params.banner) {
                params.banner = {
                    key         : params.banner.key,
                    old_key     : params.banner.key,
                    temp_key    : params.banner.temp_key,
                    size        : params.banner.size,
                    extension   : params.banner.extension,
                    uploadedBy  : params.banner.uploadedBy,
                    uploadedAt  : params.banner.uploadedAt,
                }
            }
            if (params.room_logo) {
                params.room_logo = {
                    key         : params.room_logo.key,
                    old_key     : params.room_logo.key,
                    temp_key    : params.room_logo.temp_key,
                    size        : params.room_logo.size,
                    extension   : params.room_logo.extension,
                    uploadedBy  : params.room_logo.uploadedBy,
                    uploadedAt  : params.room_logo.uploadedAt,
                }
            }
            if (params.document1) {
                params.document1 = {
                    key         : params.document1.key,
                    old_key     : params.document1.key,
                    temp_key    : params.document1.temp_key,
                    size        : params.document1.size,
                    extension   : params.document1.extension,
                    uploadedBy  : params.document1.uploadedBy,
                    uploadedAt  : params.document1.uploadedAt,
                }
            }
            if (params.document2) {
                params.document2 = {
                    key         : params.document2.key,
                    old_key     : params.document2.key,
                    temp_key    : params.document2.temp_key,
                    size        : params.document2.size,
                    extension   : params.document2.extension,
                    uploadedBy  : params.document2.uploadedBy,
                    uploadedAt  : params.document2.uploadedAt,
                }
            }
            if (params.document3) {
                params.document3 = {
                    key         : params.document3.key,
                    old_key     : params.document3.key,
                    temp_key    : params.document3.temp_key,
                    size        : params.document3.size,
                    extension   : params.document3.extension,
                    uploadedBy  : params.document3.uploadedBy,
                    uploadedAt  : params.document3.uploadedAt,
                }
            }
            
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };
            return axios.post(constants.API_URL + '/api/meetings/', params, {headers: headers})
            .then((response) => {
                commit('resetState')
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.created_by;
        delete params.hosts;
        delete params.is_deleted;
        delete params.time_zone;
        delete params.session_days;
        delete params.room_ses;
        console.log("PARAMS:***",params);
       
        _.map(params.translations, i => delete i._id);

            if (!_.isEmpty(state.item.room_settings)) {
                for (let index in state.item.room_settings) {
                    for (let settings in state.item.room_settings[index]) {
                        if (settings === 'start_time' ) {
                            let ftime = state.item.room_settings[index][settings];
                            console.log("ftimeftime",ftime)
                            let stime = state.item.room_settings[index][settings].split(':');
                            if (stime[0] < 10 && stime[0].toString().length < 2) {
                                ftime = '0' + stime[0] + ':' + stime[1]
                            }

                            var start_time = state.item.room_settings[index].room_day + " " + ftime;

                            console.log("start_time",start_time);
                            var localTime = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
                            console.log("localTime",localTime);
                            var utcTime = moment(localTime).tz('UTC').format('HH:mm');
                            params.room_settings[index][settings] = utcTime;

                            let formatRoomDay = moment(localTime).tz('UTC').format('YYYY-MM-DD');
                            console.log("formatRoomDayformatRoomDay",formatRoomDay);
                            params.room_settings[index].room_day = formatRoomDay;
                        

                        } else if (settings === 'end_time') {
                            let dtime = state.item.room_settings[index][settings]

                            let etime = state.item.room_settings[index][settings].split(':');

                            if (etime[0] < 10 && etime[0].toString().length < 2) {
                                dtime = '0' + etime[0] + ':' + etime[1]
                            }

                            var end_time = state.item.room_settings[index].room_day + " " + dtime;
                            var localTime = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
                            var utcTime = moment(localTime).tz('UTC').format('HH:mm');
                            params.room_settings[index][settings] = utcTime;
                        
                        } else {
                            params.room_settings[index][ settings ] =  state.item.room_settings[index][settings]
                            
                        }

                    }
                }
            }
            console.log("params.room_settingsparams.room_settings",params.room_settings);
            commit('setUTCTime', 'start_time');
            commit('setUTCTime', 'end_time');
            commit('setUTCTime', 'break_start_time');
            commit('setUTCTime', 'break_end_time');
            params.start_time   =  state.item.db_start_time;
            params.end_time     = state.item.db_end_time
            if (!state.item.is_no_break) {
                params.break_start_time =state.item.db_break_start_time;
                params.break_end_time = state.item.db_break_end_time;
            }
            params.start_date   =  state.item.start_date
            params.end_date     = state.item.end_date

            if (params.logo) {
                params.logo = {
                    key         : params.logo.key,
                    old_key     : params.logo.key,
                    temp_key    : params.logo.temp_key,
                    size        : params.logo.size,
                    extension   : params.logo.extension,
                    uploadedBy  : params.logo.uploadedBy,
                    uploadedAt  : params.logo.uploadedAt,
                }
            }
            if (params.background_picture) {
                params.background_picture = {
                    key         : params.background_picture.key,
                    old_key     : params.background_picture.key,
                    temp_key    : params.background_picture.temp_key,
                    size        : params.background_picture.size,
                    extension   : params.background_picture.extension,
                    uploadedBy  : params.background_picture.uploadedBy,
                    uploadedAt  : params.background_picture.uploadedAt,
                }
            }
            if (params.banner) {
                params.banner = {
                    key         : params.banner.key,
                    old_key     : params.banner.key,
                    temp_key    : params.banner.temp_key,
                    size        : params.banner.size,
                    extension   : params.banner.extension,
                    uploadedBy  : params.banner.uploadedBy,
                    uploadedAt  : params.banner.uploadedAt,
                }
            }
            if (params.room_logo) {
                params.room_logo = {
                    key         : params.room_logo.key,
                    old_key     : params.room_logo.key,
                    temp_key    : params.room_logo.temp_key,
                    size        : params.room_logo.size,
                    extension   : params.room_logo.extension,
                    uploadedBy  : params.room_logo.uploadedBy,
                    uploadedAt  : params.room_logo.uploadedAt,
                }
            }
            /*if (params.document1) {
                params.document1 = {
                    key         : params.document1.key,
                    old_key     : params.document1.key,
                    temp_key    : params.document1.temp_key,
                    size        : params.document1.size,
                    extension   : params.document1.extension,
                    uploadedBy  : params.document1.uploadedBy,
                    uploadedAt  : params.document1.uploadedAt,
                }
            }*/

             if (_.has(params.document1, 'key')) {
                        let imgPath = _.replace(params.document1.key, '/draft', '');
                        params.document1 = {
                            key: imgPath,
                            old_key: (!_.isNull(state.item.document1.key) && !_.isNull(state.item.document1.key.match(/\/draft\//g))) ? state.item.document1.key : null,
                            size: params.document1.size,
                            extension: params.document1.extension,
                            uploadedBy: params.document1.uploadedBy,
                            uploadedAt: params.document1.uploadedAt,
                        }
            }
            
            /*if (params.document2) {
                params.document2 = {
                    key         : params.document2.key,
                    old_key     : params.document2.key,
                    temp_key    : params.document2.temp_key,
                    size        : params.document2.size,
                    extension   : params.document2.extension,
                    uploadedBy  : params.document2.uploadedBy,
                    uploadedAt  : params.document2.uploadedAt,
                }
            }
            if (params.document3) {
                params.document3 = {
                    key         : params.document3.key,
                    old_key     : params.document3.key,
                    temp_key    : params.document3.temp_key,
                    size        : params.document3.size,
                    extension   : params.document3.extension,
                    uploadedBy  : params.document3.uploadedBy,
                    uploadedAt  : params.document3.uploadedAt,
                }
            }*/

            if (_.has(params.document2, 'key')) {
                let imgPath = _.replace(params.document2.key, '/draft', '');
                params.document2 = {
                    key: imgPath,
                    old_key: (!_.isNull(state.item.document2.key) && !_.isNull(state.item.document2.key.match(/\/draft\//g))) ? state.item.document2.key : null,
                    size: params.document2.size,
                    extension: params.document2.extension,
                    uploadedBy: params.document2.uploadedBy,
                    uploadedAt: params.document2.uploadedAt,
                }
            }
            if (_.has(params.document3, 'key')) {
                let imgPath = _.replace(params.document3.key, '/draft', '');
                params.document3 = {
                    key: imgPath,
                    old_key: (!_.isNull(state.item.document3.key) && !_.isNull(state.item.document3.key.match(/\/draft\//g))) ? state.item.document3.key : null,
                    size: params.document3.size,
                    extension: params.document3.extension,
                    uploadedBy: params.document3.uploadedBy,
                    uploadedAt: params.document3.uploadedAt,
                }
            }

            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };
            return axios.patch(constants.API_URL + '/api/meetings/'+state.item._id, params, {headers: headers})
            .then((response) => {
                commit('resetState')
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },

    fetchEventsAllEdit({ state, commit,dispatch }, event_id){
        commit('setEvent', event_id);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(`${constants.API_URL}/api/events/event-id/${event_id}`, {headers: headers})
            .then(response => {
                commit('setEventTimezone', response.data.data.time_zone)
                // debugger
                moment.tz.setDefault(state.event_timezone);
                commit('setEventsAll', response.data.data);
                commit('setEventDaysEdit', response.data.data);
                commit('setLocalTime', 'start_date')
                commit('setLocalTime', 'end_date')
                commit('setLocalTime', 'start_time')
                commit('setLocalTime', 'end_time')
                commit('setLocalTime', 'break_start_time')
                commit('setLocalTime', 'break_end_time');
                //dispatch('fetchData',state.meetingId)
            })
    },
    
    fetchEventsAll({ state, commit,dispatch }, event_id) {
        commit('setEvent', event_id);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(`${constants.API_URL}/api/events/event-id/${event_id}`, {headers: headers})
            .then(response => {
                commit('setEventTimezone', response.data.data.time_zone)
                // debugger
                moment.tz.setDefault(state.event_timezone);
                commit('setEventsAll', response.data.data);
                commit('setEventDaysAdd', response.data.data);
                commit('setLocalTime', 'start_date')
                commit('setLocalTime', 'end_date')
                commit('setLocalTime', 'start_time')
                commit('setLocalTime', 'end_time')
                commit('setLocalTime', 'break_start_time')
                commit('setLocalTime', 'break_end_time');
                
            })

    },
   
    fetchData({ state, commit,dispatch }){
        console.log("Fetch data call*************,",state);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let meetingId = state.meetingId;
        axios.get(`${constants.API_URL}/api/meetings/${meetingId}`, {headers: headers})
            .then(response => {
                let roomData = response.data.data;
                console.log("roomData*********",roomData,"state.event_timezone",state.event_timezone);
                let room_settings = {}
                //debugger;
                roomData.room_settings.map((setting, i) => {
                        console.log(setting);
                        var value = setting.room_day + " " + setting.start_time;
                        console.log("value to format",value);
                        var st_date = moment.utc(value).tz(state.event_timezone).format("H:mm");
                        setting.start_time = st_date
                        var value_end = setting.room_day + " " + setting.end_time
                        var date_end = moment.utc(value_end).tz(state.event_timezone).format('HH:mm');
                        //var formatedDateEnd = moment(String(date_end)).tz(res.event.time_zone).format('HH:mm')
                        setting.end_time = date_end
                        
                        room_settings[setting.room_day] = setting
                    
                })
                console.log("room_settings",room_settings);
                const params = _.cloneDeep(state.item);
                params.room_settings =room_settings;
                commit('setItem',params) 
                //commit('setRoomSettings', room_settings)
                


            })

    },
   
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        axios.get('/api/v1/event_details/' + event_id)
            .then(response => {
                //state.event_timezone = response.data.data[0].time_zone
                commit('setEventType', response.data.data[0].event_type)
                state.event_type = response.data.data[0].event_type
                // if(state.event_type == 'virtual_exhibition' && state.item.is_live && state.item.location == "" ){
                //     state.item.location = 'Auditorium Live'
                // }
                // moment.tz.setDefault(state.event_timezone);
                // console.log(state.event_timezone)

            })

    },
    fetchEventusersAll({ commit }, event_id) {
        axios.get('/api/v1/get_event_hosts/' + event_id)
            .then(response => {
                let eventUsers = response.data.data
                //debugger;
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })
                //debugger;
                //res.event_type = usersFullname;
                commit('setEventusersAll', eventUsers)
            })
    },
    fetchBoothsAll({ commit }, event_id) {
        commit('setEvent', event_id)

        axios.get('/api/v1/event/' + event_id + '/vrbooths')
            .then(response => {

                commit('setBoothsAll', response.data.data)
            })
    },
    setBooth({commit},value){
        commit('setBooth',value)
    },
    setIsLiveStream({ commit }, value) {
        commit('setIsLiveStream', value)
    },
    setStreamUrl({ commit }, value) {
        commit('setStreamUrl', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setNoOfSessions({ commit }, value) {
        commit('setNoOfSessions', value)
    },
    setNoOfSessionsDay({ commit }, value) {
        commit('setNoOfSessionsDay', value)
    },
    setScreenSharing({ commit }, value) {
        commit('setScreenSharing', value)
    },
    setLimitedSessions({ commit }, value) {
        commit('setLimitedSessions', value)
    },
    setRoomType({ commit }, value) {
        commit('setRoomType', value)
    },
    setLimitedSessionsDay({ commit }, value) {
        commit('setLimitedSessionsDay', value)
    },
    setHasMeetings({ commit }, value) {
        commit('setHasMeetings', value)
    },
    setRoomNumber({ commit }, value) {
        commit('setRoomNumber', value)
    },
    setLangRoomNumber({ commit }, value) {
        commit('setLangRoomNumber', value)
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setLogo({ commit }, value) {
        commit('setLogo', value)
    },
    setRoomLogo({ commit }, value) {
        commit('setRoomLogo', value)
    },
    setHost({ commit }, value) {
        commit('setHost', value)
    },
    
    setTime_slot({ commit }, value) {
        commit('setTime_slot', value)
    },
    setTime_slotDay({ commit }, value) {
        commit('setTime_slotDay', value)
    },
    setHours_per_day({ commit }, value) {
        commit('setHours_per_day', value)
    },
    setStart_date({ commit }, value, status) {
        // debugger;
        commit('setStart_date', value, status)
    },
    setEnd_date({ commit }, value) {
        commit('setEnd_date', value)
    },
    setEnd_dateEdit({ commit }, value) {
        commit('setEnd_dateEdit', value)
    },
   setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setStart_timeDay({ commit }, value) {
        commit('setStart_timeDay', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setEnd_timeDay({ commit }, value) {
        commit('setEnd_timeDay', value)
    },
    setBreak_Start_time({ commit }, value) {
        commit('setBreak_Start_time', value)
    },
    setBreak_End_time({ commit }, value) {
        commit('setBreak_End_time', value)
    },
    setBreak_Start_timeDay({ commit }, value) {
        commit('setBreak_Start_timeDay', value)
    },
    setBreak_End_timeDay({ commit }, value) {
        commit('setBreak_End_timeDay', value)
    },
    setDocument({ commit }, value) {
        commit('setDocument', value)
    },
    setLangDescription({ commit }, value) {
        commit('setLangDescription', value)
    },

    setVideo({ commit }, value) {
        commit('setVideo', value)
    },
    setError({ commit, dispatch }, value) {
        let error = "";
        if (value == "size") {
            error = "Video size should not greater than 500MB";
        } else if (value == "type_pdf") {
            error = "Document must be type of PDF";
        } else if (value == "type_video") {
            error = "Video must be type of MP4";
        }
        //{"event_domain":['The event domain is invalid']}
        dispatch(
            'Alert/setAlert',
            { message: "Form data is invalid", errors: { "video": [error] }, color: 'danger' },
            { root: true })
    },

    setDocumentSlide1({ commit }, value) {
        commit('setDocumentSlide1', value)
    },
    setDocumentSlide2({ commit }, value) {
        commit('setDocumentSlide2', value)
    },
    setDocumentSlide3({ commit }, value) {
        commit('setDocumentSlide3', value)
    },
    setDocument1({ commit }, value) {
        commit('setDocument1', value)
    },
    setDocument2({ commit }, value) {
        commit('setDocument2', value)
    },
    setDocument3({ commit }, value) {
        commit('setDocument3', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setShowText({ commit }, value) {
        commit('setShowText', value)
    },
    setWallLeftColor({ commit, dispatch }, value) {
        commit('setWallLeftColor', value)
    },
    setWallRightColor({ commit, dispatch }, value) {
        commit('setWallRightColor', value)
    },
    setWallFrontColor({ commit, dispatch }, value) {
        commit('setWallFrontColor', value)
    },
    setWallBackColor({ commit, dispatch }, value) {
        commit('setWallBackColor', value)
    },
    setSkybox({ commit }, value) {
        commit('setSkybox', value)
    },
    setEnvironment({ commit }, value) {
        commit('setEnvironment', value)
    },
    setSkyboxPicture({ commit }, value) {
        commit('setSkyboxPicture', value)
    },
    setEnvironmentPicture({ commit }, value) {
        commit('setEnvironmentPicture', value)
    },
    setSkybox_is_custom({ commit }, value) {
        commit('setSkybox_is_custom', value)
    },
    setEnvironment_is_custom({ commit }, value) {
        commit('setEnvironment_is_custom', value)
    },
    setBanner({ commit }, value) {
        commit('setBanner', value)
    },
    setBannerLong1({ commit }, value) {
        commit('setBannerLong1', value)
    },
    setBannerLong2({ commit }, value) {
        commit('setBannerLong2', value)
    },
    setBannerLong3({ commit }, value) {
        commit('setBannerLong3', value)
    },
    setBannerLong8({ commit }, value) {
        commit('setBannerLong8', value)
    },
    setBannerLong4({ commit }, value) {
        commit('setBannerLong4', value)
    },
    setBannerMedium({ commit }, value) {
        commit('setBannerMedium', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNoBreak({ commit }, value) {
        commit('setIsNoBreak', value)
    },
    setIsNoBreakDay({ commit }, value) {
        commit('setIsNoBreakDay', value)
    },
    setInterpretation({ commit }, value) {
        commit('setInterpretation', value)
    },
    setIsBackgroundPicture({ commit }, value) {
        commit('setIsBackgroundPicture', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundStripesColor({ commit }, value) {
        commit('setBackgroundStripesColor', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescirption({ commit }, value) {
        commit('setLangDescirption', value)
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },
    setInterprefy({ commit }, value) {
        commit('setInterprefy', value)
    },
    setInterprefyToken({ commit }, value) {
        commit('setInterprefyToken', value)
    },
    setAwsInterpretation({ commit }, value) {
        commit('setAwsInterpretation', value)
    },
    setAwsEnableSpeechToSpeech({ commit }, value) {
        commit('setAwsEnableSpeechToSpeech', value)
    },
    setAwsEnableSpeechToText({ commit }, value) {
        commit('setAwsEnableSpeechToText', value)
    },
    setChat({ commit }, value) {
        commit('setChat', value)
    },
    setErrorTranslationTab({commit},value){
        commit('setErrorTranslationTab', value)
    },
    setErrorGeneralTab({commit},value){
        commit('setErrorGeneralTab', value)
    },
    setItem({commit},value){
        commit('setItem', value)
    },
    setMeetingId({commit}, value){
        commit('setMeetingId',value);
    }
   
}

const mutations = {
    setErrorGeneralTab(state,value){
        state.error_general_tab = value
    },
    setErrorTranslationTab(state, value) {
        state.error_translation_tab = value
    },
    
    setIsLiveStream(state, value) {
        state.item.is_live_stream = value
    },
    setStreamUrl(state, value) {
        state.item.stream_url = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setIsBackgroundPicture(state, value) {
        state.item.is_background_picture = value
       
    },
    setBackgroundColor(state, value) {
        state.item.background_color = value
    },
    setBackgroundStripesColor(state, value) {
        state.item.background_stripes_color = value
    },
    setRoomSettings(state, value) {
        console.log("Room Settings Before",value);
        state.item.room_settings = value
        console.log("lattest state room settings",state.item.room_settings)
    },
    setEventType(state, value) {
        state.event_type = value
    },
    setEventTimezone(state, value) {
        console.log("Event Time Zone",value);
        state.event_timezone = value
    },
    setItem(state, item) {
        console.log("set item****",item);
        state.item = item
    },
    setScreenSharing(state, value) {
        state.item.screen_sharing = value

    },
    setRoomType(state, value) {
        state.item.room_type = value

    },
    setLimitedSessions(state, value) {
        state.item.limited_sessions = value

    },
    setNoOfSessions(state, value) {
        state.item.no_of_sessions = value

    },
    setHasMeetings(state, data) {
        console.log("data has memting",data);
        //state.item.limited_sessions = value
        state.item.room_settings[data.i]['has_meetings'] = data.value ? true : false
        console.log(state.item.room_settings[data.i])
    },
    setLimitedSessionsDay(state, data) {
        //state.item.limited_sessions = value
        state.item.room_settings[data.i]['limited_sessions'] = data.value ? true : false
    },
    setNoOfSessionsDay(state, data) {
        //state.item.no_of_sessions = value
        state.item.room_settings[data.i]['no_of_sessions'] = data.value
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setRoomNumber(state, value) {
        state.item.room_no = value
    },
    
    setLangRoomNumber(state, value) {
        var name_lang = value.lang_name
        state.item['room_no_' + name_lang] = value.value
    },
    setDocumentSlide1(state, value) {
        state.item.document1_slides = value
    },
    setDocumentSlide2(state, value) {
        state.item.document2_slides = value
    },
    setDocumentSlide3(state, value) {
        state.item.document3_slides = value
    },

    setDocument1(state, value) {
        state.item.document1 = value
    },
    setDocument2(state, value) {
        state.item.document2 = value
    },
    setDocument3(state, value) {
        state.item.document3 = value
    },
    setBanner(state, value) {
        state.item.banner = value
    },
    setEvent(state, value) {
        console.log("This is set event",value);
        state.item.event_id = value
    },
    setHost(state, value) {
        state.item.host = value
    },
    setBooth(state, value) {
        state.item.booth = value
    },
    setTime_slot(state, value) {
        state.item.time_slot = value
    },
    setTime_slotDay(state, data) {
        state.item.room_settings[data.i]['time_slot'] = data.value
    },
    setHours_per_day(state, value) {
        state.item.hours_per_day = value
    },
    setStart_date(state, value) {
        state.item.start_date = value
    },
    setEnd_date(state, value) {
        state.item.end_date = value
        //debugger;
        if (state.item.start_date && state.item.end_date) {
            let start = new Date(state.item.start_date);
            let end = new Date(state.item.end_date);
            state.item.room_days = []
            state.item.room_settings = {}
            for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
                state.item.room_days.push(moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD'));
                state.item.room_settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] = state.item.settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] ? state.item.settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] : [];
            }
            console.log('room_days');
            console.log(state.item.room_days);
            //let dayslist = getDaysArray(new Date(state.item.start_date), new Date(state.item.end_date));
            //state.item.room_days.map((v) => v.toISOString().slice(0, 10)).join("");
            //state.item.room_days = arr
        }
    },
    setEnd_dateEdit(state, value) {
        state.item.end_date = value
        if (state.item.start_date && state.item.end_date) {
            let start = new Date(state.item.start_date);
            let end = new Date(state.item.end_date);
            state.item.room_days = []
            let room_settings = {}
            for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
                let found = false

                state.item.room_settings.map((setting, index) => {
                    if (setting.room_day === moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')) {
                        return found = true
                    }
                })
                if (found) {
                    console.log("Found");
                } else {
                    // state.item.room_days.push(moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD'));
                    state.item.room_settings.push({ room_day: moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD') });
                }

            }

        }
    },
    setEnd_dateEditForm(state, value) {
        state.item.end_date = value
        if (state.item.start_date && state.item.end_date) {
            let start = new Date(state.item.start_date);
            let end = new Date(state.item.end_date);
            state.item.room_days = []
            for (var dt = new Date(start), i = 0; dt <= end; dt.setDate(dt.getDate() + 1)) {
                if (state.item.room_settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')]) {
                    console.log("test");
                } else {
                    state.item.room_days.push(moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD'));
                    state.item.room_settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] = state.item.
                        room_settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] ? state.item.room_settings[moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY-MM-DD')] : { room_day: moment.utc(new Date(dt)).tz(state.event_timezone).format('YYYY- MM - DD') };
                }
                i++;
            }

            state.item.room_settings = Object.keys(state.item.room_settings).map(function (key) {
                return state.item.room_settings[key];
            });

            console.log(state.item.room_settings)
        }
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setStart_timeDay(state, data) {

        var start_time = state.item.room_settings[data.i].room_day + " " + data.time;
        var localTime = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
        var utcTime = moment(localTime).tz('UTC').format('HH:mm');
        //state.item.room_settings[data.i]['start_time'] = utcTime
        state.item.room_settings[data.i]['start_time'] = data.time
        console.log(state.item.room_settings)
    },
    setEnd_timeDay(state, data) {
        var end_time = state.item.room_settings[data.i].room_day + " " + data.time;
        var localTime = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
        var utcTime = moment(localTime).tz('UTC').format('HH:mm');
        // state.item.room_settings[data.i]['end_time'] = utcTime
        state.item.room_settings[data.i]['end_time'] = data.time


    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setBreak_Start_time(state, value) {
        console.log("Start break time",value);
        state.item.break_start_time = value
    },
    setBreak_End_time(state, value) {
        state.item.break_end_time = value
    },
    setBreak_Start_timeDay(state, data) {
        state.item.room_settings[data.i]['break_start_time'] = data.time
    },
    setBreak_End_timeDay(state, data) {
        state.item.room_settings[data.i]['break_end_time'] = data.time
    },
    setDocument(state, value) {
        if (value == '') {
            state.item.document_deleted = 1
        } else {
            state.item.document_deleted = 0
        }
        state.item.document = value
    },
    setLogo(state, value) {
      state.item.logo = value
    },
    setRoomLogo(state, value) {
     state.item.room_logo = value

    },
    setVideo(state, value) {
        state.item.video = value
    },
    setEventDaysAdd(state, value) {
        var s = value.start_date;
        var e = value.end_date;

        state.item.start_date = moment.utc(s).tz(state.event_timezone).format('YYYY-MM-DD')
        state.item.end_date = moment.utc(e).tz(state.event_timezone).format('YYYY-MM-DD')

        var getDaysArray = function (s, e) { for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) { a.push(moment.utc(new Date(d)).tz(state.event_timezone).format('YYYY-MM-DD')); } return a; };

        var daylist = getDaysArray(new Date(s), new Date(e));
        console.log("Event day************",daylist);
        state.event_days = daylist
        var route = window.location.pathname
        route = route.split('/')
        if (!route.includes('edit')) {
            if (!state.item.room_settings.length) {
                state.item.start_time = moment.utc(s).tz(state.event_timezone).format('HH:mm')
                state.item.end_time = moment.utc(e).tz(state.event_timezone).format('HH:mm')
         
            }
            daylist.map((day, i) => {
                state.item.room_settings.push({ room_day: day, has_meetings: false })

            })
        }
    },
    setEventDaysEdit(state, value) {
        var s = value.start_date;
        var e = value.end_date;

        state.item.start_date = moment.utc(s).tz(state.event_timezone).format('YYYY-MM-DD')
        state.item.end_date = moment.utc(e).tz(state.event_timezone).format('YYYY-MM-DD')

        var getDaysArray = function (s, e) { for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) { a.push(moment.utc(new Date(d)).tz(state.event_timezone).format('YYYY-MM-DD')); } return a; };

        var daylist = getDaysArray(new Date(s), new Date(e));
        console.log("Event day************",daylist);
        state.event_days = daylist
        
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    setEventusersAll(state, value) {
        state.eventusersAll = value
    },
    setBoothsAll(state, value) {
        state.boothsAll = value
    },
    setWallLeftColor(state, value) {
        state.item.wall_left_color = value
    },
    setWallRightColor(state, value) {
        state.item.wall_right_color = value
    },
    setWallFrontColor(state, value) {
        state.item.wall_front_color = value
    },
    setWallBackColor(state, value) {
        state.item.wall_back_color = value
    },
    setSkybox(state, value) {

        state.item.skybox = value
    },
    setEnvironment(state, value) {

        state.item.environment = value
    },
    setSkyboxPicture(state, value) {
        state.item.skybox_picture = value
        if (value == '') {
            state.item.skybox_picture_delete = 1
        } else {
            state.item.skybox_picture_delete = 0
        }
    },
    setEnvironmentPicture(state, value) {
        state.item.environment_picture = value
        if (value == '') {
            state.item.environment_picture_delete = 1
        } else {
            state.item.environment_picture_delete = 0
        }
    },
    setSkybox_is_custom(state, value) {
        state.item.skybox_is_custom = value
        console.log(value)

    },
    setEnvironment_is_custom(state, value) {
        state.item.environment_is_custom = value
        //console.log(value)
    },
    setDescription(state, value) {
        state.item.room_description = value
    },
    setShowText(state, value) {
        state.item.show_text = value

    },

    setLocalTime(state, type) {

        if (type == 'start_date') {
            var value = state.eventsAll.start_date;
        }
        if (type == 'end_date') {
            var value = state.eventsAll.end_date;
        }

        if (type == 'start_time') {
            var value = state.item.start_date + " " + state.item.start_time;
        }
        if (type == 'end_time') {
            var value = state.item.start_date + " " + state.item.end_time;
        }
        if (type == 'break_start_time') {
            console.log("Local");
            var value = state.item.start_date + " " + state.item.break_start_time;
            console.log("Local value",value);
          
        }
        if (type == 'break_end_time') {
            var value = state.item.start_date + " " + state.item.break_end_time;
        }

        if (type == 'start_date') {
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss');

            var formatedDate = moment(String(date)).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss')
            state.eventsAll.start_date = formatedDate;

        }
        if (type == 'end_date') {
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss');

            console.log('Local Value:' + date)
            var formatedDate = moment(String(date)).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss')
            //debugger;
            state.eventsAll.end_date = formatedDate;
        }
        //debugger;
        if (type == 'start_time') {
            console.log('timeZone ' + state.event_timezone, "start time value",value);
            // var date = moment.utc(value).tz(state.event_timezone).format('HH:mm');
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            // debugger;
            console.log('Local Value:' + date)
            var formatedDate = moment(String(date)).tz(state.event_timezone).format('HH:mm')
            //debugger;
            console.log('Timezone Value:' + formatedDate)
            state.item.start_time = formatedDate;

        }
        if (type == 'end_time') {
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm');

            console.log('Local Value:' + date)
            var formatedDate = moment(String(date)).tz(state.event_timezone).format('HH:mm')
            //debugger;
            state.item.end_time = formatedDate;
        }
        if (type == 'break_start_time') {
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var formatedDate = moment(String(date)).tz(state.event_timezone).format('HH:mm')
            state.item.break_start_time = formatedDate;

        }
        if (type == 'break_end_time') {
            var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm');

            var formatedDate = moment(String(date)).tz(state.event_timezone).format('HH:mm')

            state.item.break_end_time = formatedDate;
        }
    },
    setUTCTime(state, type) {

        // debugger;
        if (type == 'start_date') {

            var start_date = state.item.start_date
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(start_date, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD');

            // var ct = new Date(usaTime).toUTCString();
        }
        if (type == 'end_date') {
            var end_date = state.item.end_date
            var localTime = moment.tz(end_date, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD');


        }

        if (type == 'start_time') {


            var start_time = state.item.start_date && state.item.start_date.split(' ')[0] + " " + state.item.start_time
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('HH:mm');


            // var ct = new Date(usaTime).toUTCString();
        }
        if (type == 'end_time') {
            var end_time = state.item.end_date && state.item.end_date.split(' ')[0] + " " + state.item.end_time
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('HH:mm');


        }

        if (type == 'break_start_time') {

            console.log("Break type",type,state.item.break_start_time);
            var break_start_time = state.item.start_date && state.item.start_date.split(' ')[0] + " " + state.item.break_start_time
            console.log("break_start_timebreak_start_time",break_start_time);
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(break_start_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            console.log("localTime",localTime);
            var utcTime = moment(localTime).tz('UTC').format('HH:mm');
            console.log("utcTime",utcTime);


            // var ct = new Date(usaTime).toUTCString();
        }
        if (type == 'break_end_time') {
            var break_end_time = state.item.end_date && state.item.end_date.split(' ')[0] + " " + state.item.break_end_time
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(break_end_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('HH:mm');


        }

        if (type == 'start_date') {
            console.log(state.item.start_date);
            state.item.db_start_date = utcTime;
            //console.log(state.item.start_time);
        }
        if (type == 'end_date') {
            state.item.db_end_date = utcTime;
        }
        if (type == 'start_time') {
            console.log(state.item.start_time);
            state.item.db_start_time = utcTime;
            //console.log(state.item.start_time);
        }
        if (type == 'end_time') {
            state.item.db_end_time = utcTime;
        }
        if (type == 'break_start_time') {
            state.item.db_break_start_time = utcTime;
            //console.log(state.item.start_time);
        }
        if (type == 'break_end_time') {
            state.item.db_break_end_time = utcTime;
        }



    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNoBreak(state, value) {
        state.item.is_no_break = !!value;

    },
    setIsNoBreakDay(state, data) {
        //state.item.is_no_break = !!value;
        state.item.room_settings[data.i]['is_no_break'] = !!data.value
        if (data.value) {
            state.item.room_settings[data.i]['break_start_time'] = null
            state.item.room_settings[data.i]['break_end_time'] = null
        }

        console.log(state.item.room_settings)

    },
    setInterpretation(state, value) {
        state.item.interpretation = { ...state.item.interpretation, ...value };
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    },
    setLangDescirption(state, value) {
        state.lang_descirption = value
    },
    setTranslations(state, value) {
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null, "description": null }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;

    },
    setInterprefy(state, value) {
        state.item.interprefy = value
    },
    setInterprefyToken(state, value) {
        state.item.interprefy_token = value
    },
    setAwsInterpretation(state, value) {
        state.item.aws_interpretation = value
    },
    setAwsEnableSpeechToSpeech(state, value) {
        state.item.aws_enable_speech_to_speech = value
    },
    setAwsEnableSpeechToText(state, value) {
        state.item.aws_enable_speech_to_text = value
    },
    setChat(state, value) {
        state.item.chat = value
    },
    setMeetingId(state, value){
        state.meetingId = value
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}