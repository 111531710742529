<template>
  <div class="snippet start-end-date-snippet">
    <h3>Left Banners</h3>
    <hr>
    <h5>Left Banner 1 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_1_pic" name="left_standee_text_1" :value="false"
            :checked="!item.left_standee_text_1" @change="updateLeftStandeeText1(false)">
          <label for="left_standee_text_1_pic">Branding Pictures</label>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_1_txt" name="left_standee_text_1" :value="true"
            :checked="item.left_standee_text_1" @change="updateLeftStandeeText1(true)">
          <label for="left_standee_text_1_txt">Branding Text</label>
        </div>
      </div>
      <div>
        <div class="col-lg-12" v-if="item.left_standee_text_1">
          <label>Text</label>
          <div class="kt-input-icon">
            <textarea rows="3" maxlength="255" class="form-control" name="left_text_1" placeholder="Text.."
              :value="item.left_text_1" @input="updateLeftText1"></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-6">
              <label>Choose No. of pictures {{ item.left_pics_no_1 }}</label>
              <v-select placeholder="Schedule Type" label="title" @option:selected="updateLeftPicsNo1"
                :modelValue="item.left_pics_no_1" :reduce="(option) => option.id" :options="leftpicsno" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-12">
              <DropFiles :file="validate_file(item.left1_picture_1)" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_1, 270)" @onError="errCb"
                @onUploaded="updateLeft1Picture1" @onRemoved="removeLeft1Picture1" :accept="'image/jpeg'"
                module_type="event-hall" asset_type="left1_picture_1">
                <template #header>
                  <label>Left Banner 1 Picture 1 (Size should be 108x{{ getDimensions(item.left_pics_no_1, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>
            <div class="col-lg-12" v-if="item.left_pics_no_1 == 2 || item.left_pics_no_1 == 3">
              <DropFiles :file="validate_file(item.left1_picture_2)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_1, 270)" @onUploaded="updateLeft1Picture2"
                @onRemoved="removeLeft1Picture2" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left1_picture_2">
                <template #header>
                  <label>Left Banner 1 Picture 2 (Size should be 108x{{ getDimensions(item.left_pics_no_1, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>
            <div class="col-lg-12" v-if="item.left_pics_no_1 == 3">
              <DropFiles :file="validate_file(item.left1_picture_3)" :module_id="item._id"
                @onUploaded="updateLeft1Picture3" @onRemoved="removeLeft1Picture3" :accept="'image/jpeg'" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_1, 270)" module_type="event-hall"
                asset_type="left1_picture_3">
                <template #header>
                  <label>Left Banner 1 Picture 3 (Size should be 108x{{ getDimensions(item.left_pics_no_1, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br><br>
    <h5>Left Banner 2 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_2_pic" name="left_standee_text_2" :value="false"
            :checked="!item.left_standee_text_2" @change="updateLeftStandeeText2(false)">
          <label for="left_standee_text_2_pic">Branding Pictures</label>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_2_txt" name="left_standee_text_2" :value="true"
            :checked="item.left_standee_text_2" @change="updateLeftStandeeText2(true)">
          <label for="left_standee_text_2_txt">Branding Text</label>
        </div>
      </div>
      <div>
        <div class="col-lg-12" v-if="item.left_standee_text_2">
          <label>Text</label>
          <div class="kt-input-icon">
            <textarea rows="3" maxlength="255" class="form-control" name="left_text_1" placeholder="Text.."
              :value="item.left_text_2" @input="updateLeftText2"></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-6">
              <label>Choose No. of pictures</label>
              <v-select placeholder="Schedule Type" label="title" @option:selected="updateLeftPicsNo2"
                :modelValue="item.left_pics_no_2" :reduce="(option) => option.id" :options="leftpicsno" />
            </div>
          </div>
          <div class="form-group row">

            <div class="col-lg-12">
              <DropFiles :file="validate_file(item.left2_picture_1)" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_2, 270)" @onError="errCb"
                @onUploaded="updateLeft2Picture1" @onRemoved="removeLeft2Picture1" :accept="'image/jpeg'"
                module_type="event-hall" asset_type="left2_picture_1">
                <template #header>
                  <label>Left Banner 2 Picture 1 (Size should be 108x{{ getDimensions(item.left_pics_no_2, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_2 == 2 || item.left_pics_no_2 == 3">
              <DropFiles :file="validate_file(item.left2_picture_2)" @onError="errCb" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_2, 270)" @onUploaded="updateLeft2Picture2"
                @onRemoved="removeLeft2Picture2" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left2_picture_2">
                <template #header>
                  <label>Left Banner 2 Picture 2 (Size should be 108x{{ getDimensions(item.left_pics_no_2, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_2 == 3">
              <DropFiles :file="validate_file(item.left2_picture_3)" @onError="errCb" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_2, 270)" @onUploaded="updateLeft2Picture3"
                @onRemoved="removeLeft2Picture3" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left2_picture_3">
                <template #header>
                  <label>Left Banner 2 Picture 3 (Size should be 108x{{ getDimensions(item.left_pics_no_2, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br><br>
    <h5>Left Banner 3 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_3_pic" name="left_standee_text_3" :value="false"
            :checked="!item.left_standee_text_3" @change="updateLeftStandeeText3(false)">
          <label for="left_standee_text_3_pic">Branding Pictures</label>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_3_txt" name="left_standee_text_3" :value="true"
            :checked="item.left_standee_text_3" @change="updateLeftStandeeText3(true)">
          <label for="left_standee_text_3_txt">Branding Text</label>
        </div>
      </div>
      <div>
        <div class="col-lg-12" v-if="item.left_standee_text_3">
          <label>Text</label>
          <div class="kt-input-icon">
            <textarea rows="3" maxlength="255" class="form-control" name="left_text_1" placeholder="Text.."
              :value="item.left_text_1" @input="updateLeftText3"></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-6">
              <label>Choose No. of pictures</label>
              <v-select placeholder="Schedule Type" label="title" @option:selected="updateLeftPicsNo3"
                :modelValue="item.left_pics_no_3" :reduce="(option) => option.id" :options="leftpicsno" />
            </div>
          </div>
          <div class="form-group row">

            <div class="col-lg-12">
              <DropFiles :file="validate_file(item.left3_picture_1)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_3, 270)" @onUploaded="updateLeft3Picture1"
                @onRemoved="removeLeft3Picture1" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left3_picture_1">
                <template #header>
                  <label>Left Banner 3 Picture 1 (Size should be 108x{{ getDimensions(item.left_pics_no_3, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_3 == 2 || item.left_pics_no_3 == 3">
              <DropFiles :file="validate_file(item.left3_picture_2)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_3, 270)" @onUploaded="updateLeft3Picture2"
                @onRemoved="removeLeft3Picture2" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left3_picture_2">
                <template #header>
                  <label>Left Banner 3 Picture 2 (Size should be 108x{{ getDimensions(item.left_pics_no_3, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_3 == 3">
              <DropFiles :file="validate_file(item.left3_picture_3)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_3, 270)" @onUploaded="updateLeft3Picture3"
                @onRemoved="removeLeft3Picture3" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left3_picture_3">
                <template #header>
                  <label>Left Banner 3 Picture 3 (Size should be 108x{{ getDimensions(item.left_pics_no_3, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br><br>
    <h5>Left Banner 4 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_4_pic" name="left_standee_text_4" :value="false"
            :checked="!item.left_standee_text_4" @change="updateLeftStandeeText4(false)">
          <label for="left_standee_text_4_pic">Branding Pictures</label>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_4_txt" name="left_standee_text_4" :value="true"
            :checked="item.left_standee_text_4" @change="updateLeftStandeeText4(true)">
          <label for="left_standee_text_4_txt">Branding Text</label>
        </div>
      </div>
      <div>
        <div class="col-lg-12" v-if="item.left_standee_text_4">
          <label>Text</label>
          <div class="kt-input-icon">
            <textarea rows="3" maxlength="255" class="form-control" name="left_text_4" placeholder="Text.."
              :value="item.left_text_4" @input="updateLeftText4"></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-6">
              <label>Choose No. of pictures</label>
              <v-select placeholder="Schedule Type" label="title" @option:selected="updateLeftPicsNo4"
                :modelValue="item.left_pics_no_4" :reduce="(option) => option.id" :options="leftpicsno" />
            </div>
          </div>
          <div class="form-group row">

            <div class="col-lg-12">
              <DropFiles :file="validate_file(item.left4_picture_1)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_4, 270)" @onUploaded="updateLeft4Picture1"
                @onRemoved="removeLeft4Picture1" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left4_picture_1">
                <template #header>
                  <label>Left Banner 3 Picture 1 (Size should be 108x{{ getDimensions(item.left_pics_no_4, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_4 == 2 || item.left_pics_no_4 == 3">
              <DropFiles :file="validate_file(item.left4_picture_2)" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_4, 270)" @onError="errCb"
                @onUploaded="updateLeft4Picture2" @onRemoved="removeLeft4Picture2" :accept="'image/jpeg'"
                module_type="event-hall" asset_type="left4_picture_2">
                <template #header>
                  <label>Left Banner 3 Picture 2 (Size should be 108x{{ getDimensions(item.left_pics_no_4, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_4 == 3">
              <DropFiles :file="validate_file(item.left4_picture_3)" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_4, 270)" @onError="errCb"
                @onUploaded="updateLeft4Picture3" @onRemoved="removeLeft4Picture3" :accept="'image/jpeg'"
                module_type="event-hall" asset_type="left4_picture_3">
                <template #header>
                  <label>Left Banner 3 Picture 3 (Size should be 108x{{ getDimensions(item.left_pics_no_4, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br><br>
    <h5>Left Banner 5 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_5_pic" name="left_standee_text_5" :value="false"
            :checked="!item.left_standee_text_5" @change="updateLeftStandeeText5(false)">
          <label for="left_standee_text_5_pic">Branding Pictures</label>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="radio-custom">
          <input type="radio" id="left_standee_text_5_txt" name="left_standee_text_5" :value="true"
            :checked="item.left_standee_text_5" @change="updateLeftStandeeText5(true)">
          <label for="left_standee_text_5_txt">Branding Text</label>
        </div>
      </div>
      <div>
        <div class="col-lg-12" v-if="item.left_standee_text_5">
          <label>Text</label>
          <div class="kt-input-icon">
            <textarea rows="3" maxlength="255" class="form-control" name="left_text_5" placeholder="Text.."
              :value="item.left_text_5" @input="updateLeftText5"></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-6">
              <label>Choose No. of pictures</label>
              <v-select placeholder="Schedule Type" label="title" @option:selected="updateLeftPicsNo5"
                :modelValue="item.left_pics_no_5" :reduce="(option) => option.id" :options="leftpicsno" />
            </div>
          </div>
          <div class="form-group row">

            <div class="col-lg-12">
              <DropFiles :file="validate_file(item.left5_picture_1)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_5, 270)" @onUploaded="updateLeft5Picture1"
                @onRemoved="removeLeft5Picture1" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left5_picture_1">
                <template #header>
                  <label>Left Banner 5 Picture 1 (Size should be 108x{{ getDimensions(item.left_pics_no_5, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_5 == 2 || item.left_pics_no_5 == 3">
              <DropFiles :file="validate_file(item.left5_picture_2)" :module_id="item._id"
                :dimmention="'108X' + getDimensions(item.left_pics_no_5, 270)" @onError="errCb"
                @onUploaded="updateLeft5Picture2" @onRemoved="removeLeft5Picture2" :accept="'image/jpeg'"
                module_type="event-hall" asset_type="left5_picture_2">
                <template #header>
                  <label>Left Banner 5 Picture 2 (Size should be 108x{{ getDimensions(item.left_pics_no_5, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

            <div class="col-lg-12" v-if="item.left_pics_no_5 == 3">
              <DropFiles :file="validate_file(item.left5_picture_3)" :module_id="item._id" @onError="errCb"
                :dimmention="'108X' + getDimensions(item.left_pics_no_5, 270)" @onUploaded="updateLeft5Picture3"
                @onRemoved="removeLeft5Picture3" :accept="'image/jpeg'" module_type="event-hall"
                asset_type="left5_picture_3">
                <template #header>
                  <label>Left Banner 5 Picture 3 (Size should be 108x{{ getDimensions(item.left_pics_no_5, 270) }} pixel
                    and format JPG.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br><br>
    <h3>Right Banners</h3>
    <hr>
    <h5>Right Banner 1 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo1" :modelValue="item.right_pics_no_1"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right1_picture_1)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_1, 260)" @onUploaded="updateRight1Picture1"
            @onRemoved="removeRight1Picture1" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right1_picture_1">
            <template #header>
              <label>Right Banner 1 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_1, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_1 == 2 || item.right_pics_no_1 == 3">

          <DropFiles :file="validate_file(item.right1_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_1, 260)" @onUploaded="updateRight1Picture2"
            @onRemoved="removeRight1Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right1_picture_2">
            <template #header>
              <label>Right Banner 1 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_1, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
    <h5>Right Banner 2 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo2" :modelValue="item.right_pics_no_2"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right2_picture_1)" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_2, 260)" :module_id="item._id"
            @onUploaded="updateRight2Picture1" @onRemoved="removeRight2Picture1" :accept="'image/jpeg'"
            module_type="event-hall" asset_type="right2_picture_1">
            <template #header>
              <label>Right Banner 2 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_2, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_2 == 2 || item.right_pics_no_2 == 3">
          <DropFiles :file="validate_file(item.right2_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_2, 260)" @onUploaded="updateRight2Picture2"
            @onRemoved="removeRight2Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right2_picture_2">
            <template #header>
              <label>Right Banner 2 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_2, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
    <h5>Right Banner 3 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo3" :modelValue="item.right_pics_no_3"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right3_picture_1)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_3, 260)" @onUploaded="updateRight3Picture1"
            @onRemoved="removeRight3Picture1" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right3_picture_1">
            <template #header>
              <label>Right Banner 3 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_3, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_3 == 2 || item.right_pics_no_3 == 3">
          <DropFiles :file="validate_file(item.right3_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_3, 260)" @onUploaded="updateRight3Picture2"
            @onRemoved="removeRight3Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right3_picture_2">
            <template #header>
              <label>Right Banner 3 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_3, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
    <h5>Right Banner 4 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo4" :modelValue="item.right_pics_no_4"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right4_picture_1)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_4, 260)" @onUploaded="updateRight4Picture1"
            @onRemoved="removeRight4Picture1" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right4_picture_1">
            <template #header>
              <label>Right Banner 4 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_4, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_4 == 2 || item.right_pics_no_4 == 4">
          <DropFiles :file="validate_file(item.right4_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_4, 260)" @onUploaded="updateRight4Picture2"
            @onRemoved="removeRight4Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right4_picture_2">
            <template #header>
              <label>Right Banner 4 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_4, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
    <h5>Right Banner 5 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo5" :modelValue="item.right_pics_no_5"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right5_picture_1)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_5, 260)" @onUploaded="updateRight5Picture1"
            @onRemoved="removeRight5Picture1" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right5_picture_1">
            <template #header>
              <label>Right Banner 5 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_5, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_5 == 2 || item.right_pics_no_5 == 5">
          <DropFiles :file="validate_file(item.right5_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_5, 260)" @onUploaded="updateRight5Picture2"
            @onRemoved="removeRight5Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right5_picture_2">
            <template #header>
              <label>Right Banner 5 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_5, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
    <h5>Right Banner 6 </h5>
    <div class="form-group row d-flex justify-content-center">
      <div class="form-group row d-flex justify-content-center">
        <div class="col-lg-6">
          <label>Choose No. of pictures</label>
          <v-select placeholder="" label="title" @option:selected="updateRightPicsNo6" :modelValue="item.right_pics_no_6"
            :reduce="(option) => option.id" :options="rightpicsno" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-12">
          <DropFiles :file="validate_file(item.right6_picture_1)" :module_id="item._id" @onError="errCb"
            @onUploaded="updateRight6Picture1" @onRemoved="removeRight6Picture1" :accept="'image/jpeg'"
            :dimmention="'86X' + getDimensions(item.right_pics_no_6, 260)" module_type="event-hall"
            asset_type="right6_picture_1">
            <template #header>
              <label>Right Banner 6 Picture 1 (Size should be 86x{{ getDimensions(item.right_pics_no_6, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

        <div class="col-lg-12" v-if="item.right_pics_no_6 == 2 || item.right_pics_no_6 == 6">
          <DropFiles :file="validate_file(item.right6_picture_2)" :module_id="item._id" @onError="errCb"
            :dimmention="'86X' + getDimensions(item.right_pics_no_6, 260)" @onUploaded="updateRight6Picture2"
            @onRemoved="removeRight6Picture2" :accept="'image/jpeg'" module_type="event-hall"
            asset_type="right6_picture_2">
            <template #header>
              <label>Right Banner 6 Picture 2 (Size should be 86x{{ getDimensions(item.right_pics_no_6, 260) }} pixel
                and format JPG.)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>

      </div>
    </div>
    <br><br>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from "jquery";
import moment from "moment";
import InputSwitch from "@/components/Commons/InputSwitch";
import _ from 'lodash';
import ObjectID from 'bson-objectid';
export default {
  name: "StartEndDateSnippet",
  components: {
    //InputSwitchitem
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        height: 300,
      },
      moduleId: ObjectID(),
    }
  },
  computed: {
    ...mapGetters("EventHallsSingle", [
      "item",
      "leftpicsno",
      "rightpicsno"
    ])
  },
  methods: {
    ...mapActions("EventHallsSingle", [
      "setLeftStandeeText1",
      "setLeftPicsNo1",
      "setLeftText1",
      "setLeft1Picture1",
      "setLeft1Picture2",
      "setLeft1Picture3",

      "setLeftStandeeText2",
      "setLeftPicsNo2",
      "setLeftText2",
      "setLeft2Picture1",
      "setLeft2Picture2",
      "setLeft2Picture3",

      "setLeftStandeeText3",
      "setLeftPicsNo3",
      "setLeftText3",
      "setLeft3Picture1",
      "setLeft3Picture2",
      "setLeft3Picture3",

      "setLeftStandeeText4",
      "setLeftPicsNo4",
      "setLeftText4",
      "setLeft4Picture1",
      "setLeft4Picture2",
      "setLeft4Picture3",

      "setLeftStandeeText5",
      "setLeftPicsNo5",
      "setLeftText5",
      "setLeft5Picture1",
      "setLeft5Picture2",
      "setLeft5Picture3",


      "setRightPicsNo1",
      "setRight1Picture1",
      "setRight1Picture2",


      "setRightPicsNo2",
      "setRight2Picture1",
      "setRight2Picture2",

      "setRightPicsNo3",
      "setRight3Picture1",
      "setRight3Picture2",


      "setRightPicsNo4",
      "setRight4Picture1",
      "setRight4Picture2",



      "setRightPicsNo5",
      "setRight5Picture1",
      "setRight5Picture2",



      "setRightPicsNo6",
      "setRight6Picture1",
      "setRight6Picture2",


    ]),

    getDimensionsSize(no, size) {
      if (_.isInteger(no)) {
        return "108X" + size / no;
      }
      return "108X" + size;
    },
    getDimensions(no, size) {
      console.log("size",size, "no",no);
      if (_.isInteger(no)) {
        return size / no;
      }
      
      return size;
    },
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    updateLeftStandeeText1(val) {
      this.setLeftStandeeText1(val);
    },
    updateLeftPicsNo1(e) {
      this.setLeftPicsNo1(Number(e.id));
    },
    updateLeftText1(e) {
      this.setLeftText1(e.target.value);
    },
    updateLeft1Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left1_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft1Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft1Picture1(e) {
      this.setLeft1Picture1(null);
    },
    updateLeft1Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left1_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft1Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft1Picture2(e) {
      this.setLeft1Picture2(null);
    },
    updateLeft1Picture3(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left1_picture_3, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft1Picture3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft1Picture3(e) {
      this.setLeft1Picture3(null);
    },
    updateLeftStandeeText2(value) {
      this.setLeftStandeeText2(value);
    },
    updateLeftPicsNo2(e) {
      this.setLeftPicsNo2(e.id);
    },
    updateLeftText2(e) {
      this.setLeftText2(e.target.value);
    },
    updateLeft2Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left2_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft2Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft2Picture1(e) {
      this.setLeft2Picture1(null);
    },
    updateLeft2Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left2_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft2Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft2Picture2(e) {
      this.setLeft2Picture2(null);
    },
    updateLeft2Picture3(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left2_picture_3, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft2Picture3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft2Picture3(e) {
      this.setLeft2Picture3(null);
    },
    updateLeftStandeeText3(value) {
      this.setLeftStandeeText3(value);
    },
    updateLeftPicsNo3(e) {
      this.setLeftPicsNo3(e.id);
    },
    updateLeftText3(e) {
      this.setLeftText3(e.target.value);
    },
    updateLeft3Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left3_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft3Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft3Picture1(e) {
      this.setLeft3Picture1(null);
    },
    updateLeft3Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left3_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft3Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft3Picture2(e) {
      this.setLeft3Picture2(null);
    },
    updateLeft3Picture3(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left3_picture_3, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft3Picture3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft3Picture3(e) {
      this.setLeft3Picture3(null);
    },




    updateLeftStandeeText4(value) {
      this.setLeftStandeeText4(value);
    },
    updateLeftPicsNo4(e) {
      this.setLeftPicsNo4(e.id);
    },
    updateLeftText4(e) {
      this.setLeftText4(e.target.value);
    },
    updateLeft4Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left4_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft4Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft4Picture1(e) {
      this.setLeft4Picture1(null);
    },
    updateLeft4Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left4_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft4Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft4Picture2(e) {
      this.setLeft4Picture2(null);
    },
    updateLeft4Picture3(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left4_picture_3, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft4Picture3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft4Picture3(e) {
      this.setLeft4Picture3(null);
    },
    updateLeftStandeeText5(value) {
      this.setLeftStandeeText5(value);
    },
    updateLeftPicsNo5(e) {
      this.setLeftPicsNo5(e.id);
    },
    updateLeftText5(e) {
      this.setLeftText5(e.target.value);
    },
    updateLeft5Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left5_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft5Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft5Picture1(e) {
      this.setLeft5Picture1(null);
    },
    updateLeft5Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left5_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft5Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft5Picture2(e) {
      this.setLeft5Picture2(null);
    },
    updateLeft5Picture3(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.left5_picture_3, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLeft5Picture3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeLeft5Picture3(e) {
      this.setLeft5Picture3(null);
    },




    updateRightPicsNo1(e) {
      this.setRightPicsNo1(e.id);
    },
    updateRight1Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right1_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight1Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight1Picture1(e) {
      this.setRight1Picture1(null);
    },
    updateRight1Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right1_picture_2, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight1Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight1Picture2(e) {
      this.setRight1Picture2(null);
    },



    updateRightPicsNo2(e) {
      debugger
      this.setRightPicsNo2(e.id);
    },
    updateRight2Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right2_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight2Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight2Picture1(e) {
      this.setRight2Picture1(null);
    },
    updateRight2Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right2_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight2Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight2Picture2(e) {
      this.setRight2Picture2(null);
    },


    updateRightPicsNo3(e) {
      this.setRightPicsNo3(e.id);
    },
    updateRight3Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right3_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight3Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight3Picture1(e) {
      this.setRight3Picture1(null);
    },
    updateRight3Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right3_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight3Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });

    },
    removeRight3Picture2(e) {
      this.setRight3Picture2(null);
    },


    updateRightPicsNo4(e) {
      console.log("Update",e.id);
      this.setRightPicsNo4(e.id);
    },
    updateRight4Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right4_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight4Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2024.01.01',
      });

    },
    removeRight4Picture1(e) {
      this.setRight4Picture1(null);
    },
    updateRight4Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right4_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight4Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2024.01.01',
      });

    },
    removeRight4Picture2(e) {
      this.setRight4Picture2(null);
    },

    updateRightPicsNo5(e) {
      this.setRightPicsNo5(e.id);
    },
    updateRight5Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right5_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight5Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2025.01.01',
      });

    },
    removeRight5Picture1(e) {
      this.setRight5Picture1(null);
    },
    updateRight5Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right5_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight5Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2025.01.01',
      });

    },
    removeRight5Picture2(e) {
      this.setRight5Picture2(null);
    },

    updateRightPicsNo6(e) {
      this.setRightPicsNo6(e.id);
    },
    updateRight6Picture1(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right6_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight6Picture1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2026.01.01',
      });

    },
    removeRight6Picture1(e) {
      this.setRight6Picture1(null);
    },
    updateRight6Picture2(info, data) {
      if (!_.has(data.data, 'key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.right6_picture_1, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setRight6Picture2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2026.01.01',
      });

    },
    removeRight6Picture2(e) {
      this.setRight6Picture2(null);
    },
    errCb(e) {
      this.$flashMessage.show({
        group : 'event_hall_index',
        type  : 'error',
        title : 'Failed',
        time  : 1000,
        html  : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
      });


    }
  }
}
</script>

<style scoped></style>
