<template>
  <div class="tabs">
    <OverlayLoading :isLoading="loading" />
    <ul class="nav nav-tabs nav-justified">
      <li class="nav-item">
        <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`"
          data-bs-toggle="tab">Language
          <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>

        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General
          Settings
          <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
                   
        
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme
          Settings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`"
          data-bs-toggle="tab">Interpretation Settings</a>
      </li>

      <li class="nav-item">
            <a class="nav-link" :data-bs-target="'#survey-'+changingVariable" :href="`#changingVariable`"  data-bs-toggle="tab" @click="reRenderComponent()">Survey</a>
     
        </li>


    </ul>
    <div class="tab-content">
      <div id="language-settings" class="tab-pane active">
        <div class="snippet general-event-config-snippet">
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Language *</label>
              <v-select required :rules="requiredRule" name="language" class="" placeholder="Select language"
                label="language" @option:selected="updateLaguage" :options="languages"
                :modelValue="selectedLanguageKey" />
              <ValidationMessages :element="v$.item.translations" />

            </div>
            <div class="col-lg-6">
              <label>Title *</label>
              <div class="kt-input-icon">
                <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter language title"
                  :value="getLanguageTitle()" @input="updateLanguageTitle" />
              </div>
              <ValidationMessages :element="v$.item.translations" />

            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                <label>Description *</label>
                <div class="kt-input-icon">
                  <textarea rows="3" class="form-control" name="description" placeholder="Enter language description"
                    :value="getLanguageDescription()" @input="updateLanguageDescription"></textarea>
                </div>
                <ValidationMessages :element="v$.item.screen_logo" />
                <span class="error">{{ err_screen_logo }}</span>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="general-settings" class="tab-pane">
        <div class="snippet general-event-config-snippet">

          <div class="form-group row">
            <div class="col-lg-10">
              <label>Speaker *</label>
              <v-select placeholder="Select Speaker" label="name" :options="speakers" track-by="id"
                :modelValue="getSpeakerId(item.speaker)" :reduce="(option) => option.id"
                @option:selected="updateSpeaker" />
              <ValidationMessages :element="v$.item.speaker" />
            </div>
            <div class="col-lg-2 newspeaker">
              <v-dialog v-model="dialog" width="auto">
                <template v-slot:activator="{ props }">
                  <v-btn color="primary" v-bind="props">Add Speaker</v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-form v-model="valid">
                      <v-container>
                        <v-row>
                          <h4 class="ml-auto mr-auto text-uppercase">{{ createSpeakerBtnTxt }}</h4>
                          <div class="col-lg-12 mb-2">
                            <label>Salutation *</label>
                            <v-select name="salutaion" class="" placeholder="Select salutaion" label="salutaion"
                              @option:selected="updateNewSpeakerSalutation" :options="salutation"
                              v-model="selected_salutation" />
                          </div>
                          <div class="col-lg-12 mb-2">
                            <label>Email *</label>
                            <div class="kt-input-icon">
                              <input type="text" class="form-control" name="email" maxlength="200"
                                placeholder="Enter Email" @input="updateNewSpeakerEmail" />
                            </div>
                            <span v-if="newEmailExists == true" class="mini_txt">User Already Exist. Do you want to add
                              this user in event?</span>
                          </div>
                          <div class="col-lg-12 mb-2">
                            <label>First Name *</label>
                            <div class="kt-input-icon">
                              <input type="text" class="form-control" name="first_name" maxlength="200"
                                placeholder="Enter First Name" :value="newSpeaker.first_name"
                                @input="updateNewSpeakerFirstName" />
                            </div>
                          </div>
                          <div class="col-lg-12 mb-2">
                            <label>Last Name *</label>
                            <div class="kt-input-icon">
                              <input type="text" class="form-control" name="last_name" maxlength="200"
                                placeholder="Enter Last Name" :value="newSpeaker.last_name"
                                @input="updateNewSpeakerLastName" />
                            </div>
                          </div>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="wrapp">
                    <v-btn type="submit" @click="submitSpeakerForm" class="mt-2 add_spk_btn">{{ createSpeakerBtnTxt
                    }}</v-btn>
                    <v-btn text @click="dialog = false" class="add_spk_btn mt-2 orangebtn">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Start Time</label>
              <div class="input-daterange input-group">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </span>
                <input type="datetime-local" class="form-control" 
                :min="getEventDatetime(eventDetail.start_date)" 
                :max="getEventDatetime(eventDetail.end_date)" 
                name="start_date" :value="getDatetime(item.start_time)"
                  @change="updateStartTime" />
                <span class="input-group-text rounded-0">from</span>
              </div>
              <ValidationMessages :element="v$.item.start_time" />

            </div>
            <div class="col-lg-6">
              <label class>End Time</label>
              <div class="input-daterange input-group">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </span>
                <input 
                :min="getEventDatetime(eventDetail.start_date)" 
                :max="getEventDatetime(eventDetail.end_date)" 
                type="datetime-local" 
                class="form-control" 
                name="start_date" 
                :value="getDatetime(item.end_time)"
                  @change="updateEndTime" />
                <span class="input-group-text rounded-0">to</span>
              </div>
              <ValidationMessages :element="v$.item.end_time" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Exhibitor</label>
            <!--  <v-select :clearable="true"  placeholder="Select Exhibitor" label="title"
                :options="exhibitorsList" track-by="id" @option:selected="updateExhibitor"
                :modelValue="getSelectedExhibitor(exhibitorsList, item.exhibitor)" />-->

                <select class="form-control" @change="updateExhibitor">
                    <option value="" >Select</option>
                    <option v-for="option in exhibitorsList" :value="option.id" :key="option.id"
                    :selected="(item.exhibitor == option.id)">
                    {{ option.title }}
                    </option>
                </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-3">
              <label>Enable Rating ?</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx" class="custom-control-input"
                  @change="UpdateIsRating" />
              </div>
            </div>
            <div class="col-lg-3">
              <label>Screen Sharing ?</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="is_screen_sharing" :value="item.is_screen_sharing" id="customSwitchesEx"
                  class="custom-control-input" @change="UpdateIsScreenSharing" />
              </div>
            </div>
            <div class="col-lg-3">
              <label>Schedule Status?</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="is_schedule_status" :value="item.is_schedule_status" id="customSwitchesEx"
                  class="custom-control-input" @change="UpdateScheduleStatus" />
              </div>
            </div>
          </div>
          <div class="form-group row">

            <div class="col-lg-3">
              <label>Session Open Or Closed ?</label>
              <br />
              <div class="switch switch-sm switch-primcontrollanguage_ary">
                <input-switch name="session_open" :value="item.session_open" id="customSwitchesEx"
                  class="custom-control-input" @change="UpdateSessionOpen" />
                  <label>{{item.session_open?"Open":"Closed"}}</label>

              </div>
            </div>

            <div class="col-lg-3">
              <label>Max. No. Attendees </label>
              <div class="kt-input-icon">
                <select :disabled="session_open_disabled == false" class="form-control" @change="UpdateMaxNoAttendees">
                  <option value="0">Select max attendees </option>
                  <option value="10" :selected="item.max_no_attendees === 10">10</option>
                  <option value="20" :selected="item.max_no_attendees === 20">20</option>
                  <option value="30" :selected="item.max_no_attendees === 30">30</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3">
                            <label>Attendees </label>
                            <div class="kt-input-icon">
                                <v-select :disabled="session_open_disabled == false" 
                                placeholder="Select attendees"
                                    :modelValue="item.attendees"
                                    label="name" :options="speakers" track-by="id" :reduce="(option) => option.id"
                                    @option:selected="UpdateAttendees" @option:deselected="RemoveAttendees"
                                    :selectable="() => item.attendees.length < item.max_no_attendees"
                                     multiple
                                    persistent-hint />
                            </div>
                        </div>

          </div>
          <div class="form-group row">
            <div class="col-lg-3">
              <label>Live Streaming ?</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="is_live_streaming" :value="item.is_live_streaming" id="customSwitchesEx"
                  class="custom-control-input" @change="UpdateIsLiveStreaming" />
              </div>
            </div>
            <div class="col-lg-3">
              <label>Stream URL</label>
              <div class="kt-input-icon">
                <input type="text" :disabled="item.is_live_streaming?false:true" class="form-control" name="title" maxlength="200"
                  placeholder="Enter Stream URL" :value="item.stream_url" @input="updateStreamUrl" />
              </div>
            </div>
            <div class="col-lg-3">
              <label>Restric User to Join before session time ?</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="is_restricted" :value="item.is_restricted" id="customSwitchesEx"
                  class="custom-control-input" @change="UpdateIsRestricted" />
              </div>
            </div>
            <div class="col-lg-3">
              <label>Minutes</label>
              <div class="kt-input-icon">
                <input type="number" :disabled="item.is_restricted?false:true" class="form-control" name="title"
                  maxlength="200" min="0" placeholder="Enter Minutes" :value="item.minutes" @input="updateMinutes" />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <DropFiles :file="validate_file(item.presentation_video)" accept="video/mp4" :module_id="item._id"
                module_type="round-table" :dimmention="null" @onUploaded="updateVideo" @onRemoved="removeVideo"
                asset_type="presentation_video" @onError="errCb">
                <template #header>
                  <label>Presentation Video (Max size 500MB and format mp4.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>
            <div class="col-lg-6">
              <DropFiles :file="validate_file(item.presentation_documents)" accept="application/pdf" :module_id="item._id"
                module_type="round-table" :dimmention="null" @onUploaded="updateDocument" @onRemoved="removeDocument"
                asset_type="presentation_documents" @onError="errCb">
                <template #header>
                  <label>Presentation Document (Max size 30MB and format pdf.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <DropFiles @onError="screenLogoErrCb" :file="validate_file(item.screen_logo)" accept="image/jpeg"
                :module_id="item._id" module_type="round-table" dimmention="455X256" @onUploaded="updateScreenLogo"
                @onRemoved="removeScreenLogo" asset_type="screen_logo">
                <template #header>
                  <label>Screen Logo *(Size should be 455x256 pixel and format jpg.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
              <div class="validation-error" v-if="v$.item.screen_logo.required.$invalid">
                <p class="alert alert-danger">{{ v$.item.screen_logo.required.$message }}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <DropFiles @onError="errCb" :file="validate_file(item.sesssion_theme_logo)" accept="image/jpeg"
                :module_id="item._id" module_type="round-table" dimmention="512X512" @onUploaded="updateSesssionThemeLogo"
                @onRemoved="removeSesssionThemeLogo" asset_type="sesssion_theme_logo">
                <template #header>
                  <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                </template>
                <template #tooltip>
                  <VTooltip style="display:inline-block">
                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                    <template #popper>
                      <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                    </template>
                  </VTooltip>
                </template>
              </DropFiles>
            </div>
          </div>
        </div>
      </div>
      <div id="theme-settings" class="tab-pane">
        <div class="row">
          <div class="tabs tabs-vertical tabs-left">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item active" role="presentation">
                <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`"
                  data-bs-toggle="tab">Theme Contents</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="theme_content" class="tab-pane active show" role="tabpanel">
                <div class="snippet general-event-config-snippet">
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label>Background Color or Picture ?</label>
                      <br />
                      <div class="switch switch-sm switch-primary">
                        <input-switch name="is_background_picture" :value="item.is_background_picture"
                          id="customSwitchesEx" class="custom-control-input" @change="UpdateIsBackgroundPicture" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" v-if="item.is_background_picture">
                    <div class="col-lg-12">
                      <DropFiles @onError="errCb" :file="validate_file(item.background_picture)" accept="image/jpeg"
                        :module_id="item._id" module_type="round-table" dimmention="1920X470"
                        @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture"
                        asset_type="background_picture">
                        <template #header>
                          <label>Background Picture (Size should be 1920x470 pixel and format JPG.) </label>
                        </template>
                        <template #tooltip>
                          <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/pd_bg.png"/>
                            </template>
                          </VPopper>
                        </template>
                      </DropFiles>
                    </div>
                  </div>
                  <div class="form-group row backgroundcolor_option" v-else>
                    <div class="col-lg-6">
                      <label>Background Color</label>
                      <div class="input-group color" data-plugin-colorpicker>
                        <input type="color" class="form-control" name="background_color" :value="item.background_color"
                          @input="updateBackgroundColor" />
                      </div>
                    </div>

                  </div>

                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Bottom Background Color</label>
                      <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                      <div class="input-group color" data-plugin-colorpicker>
                        <input type="color" class="form-control" name="background_color_bottom"
                          :value="item.background_bottom_color" @input="updateBackgroundColorBottom" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <label>Notes Arc Color</label>
                      <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                      <div class="input-group color" data-plugin-colorpicker>
                        <input type="color" class="form-control" name="notes_arc_color" :value="item.notes_arc_color"
                          @input="updateNotesArcColor" />
                      </div>
                    </div>

                  </div>

                  <div class="form-group row">
                    <div class="col-lg-12">
                      <DropFiles @onError="errCb" :file="validate_file(item.banner)" accept="image/jpeg"
                        :module_id="item._id" module_type="round-table" dimmention="552X178"
                        @onUploaded="updateBannerPicutre" @onRemoved="removeBannerPicutre" asset_type="banner">
                        <template #header>
                          <label>Banner (Size should be 552x178 pixel and format JPG.)</label>
                        </template>
                        <template #tooltip>
                          <VTooltip style="display:inline-block">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #popper>
                              <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                            </template>
                          </VTooltip>
                        </template>
                      </DropFiles>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div id="interpretify-settings" class="tab-pane">
        <div class="snippet general-event-config-snippet">
          <div class="form-group row">
            <div class="col-lg-4">
              <label>Enable "Interprefy" Interpretation</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="interprefy" :value="item.interprefy" id="customSwitchesEx"
                  class="custom-control-input" @change="updateInterprefy" />
              </div>
            </div>
            <div class="col-lg-6">
              <label>Interprefy token</label>
              <div class="kt-input-icon">
                <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter event Interprefy Token"
                  :disabled="item.interprefy?false:true" 
                  @change="updateInterprefyToken"
                  :value="item.interprefy_token" />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-4">
              <label>Enable "AWS" Interpretation</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="interprefy" :value="item.aws_interpretation" id="customSwitchesEx"
                  class="custom-control-input" @change="updateAwsInterpretation" />
              </div>
            </div>
            <div class="col-lg-4">
              <label>Enable "Speech to Speech"</label>
              <div class="kt-input-icon">
                <input-switch name="speech_to_speech" :value="item.aws_enable_speech_to_speech" id="enableSpeechToSpeech"
                  class="custom-control-input" @change="updateAwsEnableSpeechToSpeech"
                  :styleDisable="this.aws_interprefy_disabled" />
              </div>
            </div>
            <div class="col-lg-4">
              <label>Enable "Speech to Text"</label>
              <div class="kt-input-icon">
                <input-switch name="speech_to_text" :value="item.aws_enable_speech_to_text" id="enableSpeechToText"
                  class="custom-control-input" @change="updateAwsEnableSpeechToText"
                  :styleDisable="this.aws_interprefy_disabled" />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-4">
              <label>Enable "Chat" Interpretation</label>
              <br />
              <div class="switch switch-sm switch-primary">
                <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx" class="custom-control-input"
                  @change="updateChat" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div    :id ="'survey-' +changingVariable" class="tab-pane">
          <surveyIndex :agenda_id="this.item._id" surveyNameFile="RoundTableSurvey" ></surveyIndex>
      </div>






      <div class="card-body text-right mt-3 mr-5">
        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm">Save</button>
        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      </div>
    </div>
    <FlashMessage group="roundtable_edit" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { upload } from "@/services/FileUploader";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';
import moment from 'moment';
import surveyIndex from "@/components/cruds/Survey/Index";
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'
const maxVideoFileSize = 524288000; // Max file size
const maxPdfFileSize =  32096088;  // Max file size

export default {
  name: 'RoundTableEditForm',
  data() {
    return {
      changingVariable: 0,
      moduleId: ObjectID(),
      dialog: false,
      translation: [],
      languageKey: null,
      stream_disabled: true,
      restric_user_disabled: true,
      interprefy_disabled: true,
      aws_interprefy_disabled: true,
      chat_disabled: true,
      salutation: ['Mr', 'Ms', 'Mrs'],
      selected_salutation: null,
      exhibitorsList: [],
      selectedLanguage: null,
      error_translation_tab: false,
      error_general_tab: false,
      error_theme_tab: false,
      error_interpretation_tab: false,
      err_translations: null,
      err_speaker: null,
      err_exhibitor: null,
      err_start_time: null,
      err_end_time: null,
      err_is_restricted: false,
      err_minutes: null,
      err_is_rating: null,
      err_is_screen_sharing: null,
      err_is_live_streaming: null,
      err_stream_url: null,
      err_schedule_status: null,
      err_video: null,
      err_documents: null,
      err_screen_logo: null,
      err_sesssion_logo: null,
      err_is_background_picture: null,
      err_background_picture: null,
      err_background_color: null,
      err_background_color_bottom: null,
      err_banner_picutre: null,
      err_interprefy: null,
      err_interprefy_token: null,
      err_aws_interpretation: null,
      err_aws_enable_speech_to_speech: null,
      err_aws_enable_speech_to_text: null,
      err_chat: null,

      questionList: true,
      setIsSurveyEdit: false,

    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      item: {

        translations: {
          required
        },

        speaker: {
          required,
        },
        start_time: {
          required,
        },
        end_time: {
          required,
        },
        screen_logo: {
          required,
        }
      },
      surveyitems: {
                title: {
                    required,
                }
            },
    };
  },

  props: {
    event: {
      type: Object
    },
    languages: {
      type: Array,
    },
    eventDetail:null
  },
  watch: {

    'newSpeaker.salutation': function (item) {

      if (item !== undefined) {
        this.selected_salutation = item;
      }
    },
    'exhibitors': function (items) {

      this.exhibitorsList = _.map(items, (item) => {

        const translate = _.get(_.head(item.translations), 'name');
        return {
          title: translate,
          id: item._id
        }
      })
    }
  },
  components: {
    InputSwitch,
    surveyIndex,    
  },
  computed: {

    
    ...mapGetters('RoundTablesSingle', [
      'error_translation_tab',
      'error_general_tab',
      'item',
      'loading',
      'newSpeaker',
      'createSpeakerBtnTxt',
      'newEmailExists',
      'eventID',
      'createDialog',
      'listExhibitors',
      'selectedLanguageKey',
      'selectedLanguageTitle',
      'selectedLanguageDescirption',
    ]),
    ...mapGetters('RoundTablesIndex', ['speakers']),
    ...mapGetters('ExhibitorsIndex', { exhibitors: "all" })


  },
  beforeUnmount() {
    this.resetState()
    delete this.$flashMessage.groups.roundtable_edit;
  },
  created() {
    this.setSpeakerEventId(this.event.event_id);
  //  let start_time = moment.utc(this.item.start_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
  //  this.setStartTime(start_time);
  //  let end_time = moment.utc(this.item.end_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
   // this.setEndTime(end_time);
    
    
  },
  mounted() {
    //this.fetchsurveydata(this.event._id); //questions data 
    this.updateTimeZone();
    this.setEventTd(this.item.event_id);
    this.fetchExhibitorsData(this.item.event_id);
    if(this.item.session_open){
      this.session_open_disabled = false;
   
    }
    //this.setLocalTime();
   
    // this.setEventTd(this.event.event_id);
    //this.fetchData(this.item.event_id);

  },
  methods: {   


    ...mapActions('RoundTablesIndex', {
      fetchAllData: 'fetchData',
    }),
    ...mapActions('RoundTablesSingle', [
      'setLocalTime',
      'updateData',
      'storeSpeakerData',
      'storeData',
      'fetchData',
      'checkEmailExists',
      'resetState',
      'setTranslations',
      'setEventTd',
      'setSpeaker',
      'setSpeakerSalutation',
      'setSpeakerEmail',
      'setSpeakerEventId',
      'setSpeakerFirstName',
      'setSpeakerLastName',
      'setSpeakerCretedBy',
      'setExhibitor',
      'setIsRestricted',
      'setMinutes',
      'setIsRating',
      'setIsScreenSharing',
      'setIsLiveStreaming',
      'setStreamUrl',
      'setScheduleStatus',
      'setIsBackgroundPicture',
      'setPresentationVideo',
      'setPresentationDocuments',
      'setScreenLogo',
      'setSesssionLogo',
      'setBackgroundPicture',
      'setSpeakerPicutre',
      'setBackgroundColor',
      'setInterprefy',
      'setInterprefyToken',
      'setAwsInterpretation',
      'setAwsEnableSpeechToSpeech',
      'setAwsEnableSpeechToText',
      'setChat',
      'setStartTime',
      'setEndTime',
      'setCreatedBy',
      'setLangKey',
      'setLangTitle',
      'setLangDescirption',
      'setSessionOpen',
      'setMaxNoAttendees',
      'setAttendees',
      'setSesssionThemeLogo',
      'setBackgroundColorBottom',
      'setNotesArcColor',
      'setBanner',
      'setErrorTranslationTab',
      'setErrorGeneralTab',
      'setTimeZone',
      'setPresentationDocumentsSlides'
      
    ]),
    ...mapActions('ExhibitorsIndex', { fetchExhibitorsData: "fetchData" }),
    getSelectedExhibitor(listExhibitors, exhibitorId) {
      const getSelectedExhibitor = _.find(listExhibitors, i => i.id === exhibitorId);
      return _.get(getSelectedExhibitor, 'title', '');
    },
    updateTimeZone(){
      this.setTimeZone(this.eventDetail.time_zone)
    },
    reRenderComponent() {
      this.changingVariable += 1
    },
    fileLoad(e) {
      const file = e.target.files[0];
      const id = uuidv4();
      upload(file, id, "users", null, file.type)
        .then((resp) => {
          console.log(resp)

        })
        .catch((e) => {
          console.error(e)
          this.$flashMessage.show({
            group: 'roundtable_edit',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        })
    },
    validate_file(fileInfo) {
      // console.log(fileInfo);
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    updateSpeaker(value) {
      this.setSpeaker(value.id);
    },
    async submitSpeakerForm() {
      this.storeSpeakerData()
        .then(async () => {
          this.$flashMessage
                        .show({
                            group   : 'roundtable_edit',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Speaker added successfully.</li></ul>',
                        });
                
          await this.fetchAllData(this.eventID);
          //this.resetState();
          this.dialog = false;
          this.setSpeakerFirstName(null);
          this.setSpeakerLastName(null);
        
        })
        .catch((error) => {
          console.log("submitSpeakerForm", error);
          let html = gettingErrorMessage(error);
          if (error.response.data.data.error) {
            this.$flashMessage.show({
              group: 'roundtable_edit',
              type: 'error',
              title: 'Failed',
              text: html || 'Something went wrong',
              html: html
            });
          }
        })
    },
    getSpeakerId(speaker) {
      return _.has(speaker, '_id') ? speaker._id : speaker;
    },
    async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
          let validateTranlation = await this.checkLanguageValidation();
          this.error_general_tab = false;
          this.error_translation_tab = false;
          this.error_theme_tab = false;
          this.error_interpretation_tab = false;
          this.setErrorGeneralTab(false)
          this.setErrorTranslationTab(false);

          let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "speaker"){
                generalError.push("<li>Speaker is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }

            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
                if (!_.isEmpty(validateTranlation)) {
           
                  this.error_translation_tab = true;
                  this.setErrorTranslationTab(true);
                  this.$flashMessage.show({
                      group: 'roundtable_edit',
                      type: 'error',
                      time:1000,
                      title: 'Failed',
                      html: `<ul>${validateTranlation.join("")}</ul>`
                  });
                return ;
              }          
            } 
            if (!isFormCorrect ) {
                
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'roundtable_edit',
                        type    : 'error',
                        time    : 1000,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }
       
      this.updateData()
        .then(async () => {

          this.$flashMessage.show({
            group : 'lp_index',
            type  : 'success',
            title : 'Success',
            time  : 1000,
            html  : '<ul><li>Roundtable has been updated successfully.</li></ul>'
          });

          await this.fetchAllData(this.item.event_id);
          this.resetState();
          this.dialog = false;
        })
        .catch((error) => {
          console.error("Error update roundtable", error)
          let html = gettingErrorMessage(error);

          this.$flashMessage.show({
            group: 'roundtable_edit',
            type: 'error',
            title: 'Error',
            text: html || 'Something went wrong.',
            html: html
          });
        })
    },
    updateLaguage(value) {

      this.err_translations = null;
      this.setLangKey(value);
      this.languageKey = value;
      this.setLangTitle(null);
      this.setLangDescirption(null);

      if (_.isUndefined(_.find(this.item.translations, {
        'key': value,
      }))) {
        let obj = {
          "key": `${value}`,
          "title": null,
          "description": null
        }
        this.item.translations.push(obj);
      } else {

        let selectedLang = _.find(this.item.translations, {
          'key': this.selectedLanguageKey,
        })
        if (!_.isUndefined(selectedLang)) {
          this.setLangTitle(selectedLang.title);
          this.setLangDescirption(selectedLang.description);
        }
      }

    },
    updateLanguageTitle(e) {
      this.err_translations = null;
      if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
        this.setTranslations({
          key: this.selectedLanguageKey,
          update_key: 'title',
          update_value: e.target.value
        });

      }
      this.setLangTitle(e.target.value);
    },
    updateLanguageDescription(e) {
      if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
        this.setTranslations({
          key: this.selectedLanguageKey,
          update_key: 'description',
          update_value: e.target.value
        });
      }
      this.setLangDescirption(e.target.value);
    },
    getLanguageTitle() {
      return this.selectedLanguageTitle;
    },
    getLanguageDescription() {
      return this.selectedLanguageDescirption;
    },
    updateNewSpeakerEmail(e) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        console.log("Email---", e.target.value, e);
        this.setSpeakerEmail(e.target.value);
        this.setSpeakerEventId(this.eventID);
        this.setSpeakerCretedBy("63da6ecb083ba9aebc3b35f5");
        this.checkEmailExists()
          .then()
          .catch((error) => {
            console.error(error)
            this.$flashMessage.show({
              group: 'roundtable_edit',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          })
      } else {
        this.setSpeakerFirstName(null);
        this.setSpeakerLastName(null);
      }
    },
    updateNewSpeakerSalutation(value) {
      this.setSpeakerSalutation(value);
    },
    updateNewSpeakerFirstName(e) {
      this.setSpeakerFirstName(e.target.value);
    },
    updateNewSpeakerLastName(e) {
      this.setSpeakerLastName(e.target.value);
    },
    updateExhibitor(e) {
      //this.setExhibitor(value.id);
      this.setExhibitor(e.target.value);
       
    },
    UpdateIsRestricted(e) {
      this.restric_user_disabled = true;
      if (e.target.checked) {
        this.restric_user_disabled = false;
      }

      this.setIsRestricted(e.target.checked);
    },
    UpdateQaType(e) {
      this.setQaType(e.target.checked);
    },
    UpdateIsRating(e) {
      this.setIsRating(e.target.checked);
    },
    UpdateIsScreenSharing(e) {
      this.setIsScreenSharing(e.target.checked);
    },
    UpdateIsLiveStreaming(e) {
      this.stream_disabled = true;
      if (e.target.checked) {
        this.stream_disabled = false;
      }
      this.setIsLiveStreaming(e.target.checked);
    },
    UpdateScheduleStatus(e) {
      this.setScheduleStatus(e.target.checked);
    },
    UpdateIsBackgroundPicture(e) {
      this.setIsBackgroundPicture(e.target.checked);
    },
    updateVideo(info, data) {
      if (!_.has(data, 'data.Key')) {
        return;
      }

      const extension = data.data.Key.split('.').pop();
      const key = _.get(this.item.presentation_video, 'Key', null);
      const size = _.get(info, 'file.size', 0);
      if(size>maxVideoFileSize){
        this.showCbError();
        return;
      }
      this.setPresentationVideo({
        old_key: key,
        key: data.data.Key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeVideo(e) {
      this.setPresentationVideo(null);
    },
    updateDocument(info, data) {
      //if (!_.has(data, 'data.key') || !_.has(data, 'data.Key')) {
        //return;
      //}
      const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
      const key   = _.get(this.item.presentation_documents, 'key', null);
      const size  = _.get(info, 'file.size', 0);
      if(size>maxPdfFileSize){
        this.showCbError();
        return;
      }
      this.setPresentationDocuments({
        old_key   : data.data.key?data.data.key:data.data.Key,
        key       : data.data.key?data.data.key:data.data.Key,
        temp_key  : info.id,
        size      : size.toString(),
        extension : extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeDocument(e) {
      this.setPresentationDocuments(null);
      this.setPresentationDocumentsSlides([]);
    },
    updateScreenLogo(info, data) {
      this.err_screen_logo = null;
      if (!_.has(data, 'data.key')) {
        return;
      }

      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.screen_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setScreenLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeScreenLogo(e) {
      this.err_screen_logo = null;
      this.setScreenLogo(null);
    },
    updateSesssionLogo(info, data) {
      // console.log(data);
      if (!_.has(data, 'data.key')) {

        return;
      }

      const extension = data.data.key.split('.').pop()
      const key = _.get(this.item.sesssion_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setSesssionLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeSesssionLogo(e) {
      this.setSesssionLogo(null);
    },
    updateSesssionThemeLogo(info, data) {
       console.log(data);
      if (!_.has(data, 'data.key')) {

        return;
      }

      const extension = data.data.key.split('.').pop()
      const key = _.get(this.item.sesssion_theme_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setSesssionThemeLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeSesssionThemeLogo(e) {
      this.setSesssionThemeLogo(null);
    },
    updateBackgroundPicture(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.background_picture, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setBackgroundPicture({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeBackgroundPicture(e) {
      this.setBackgroundPicture(null);
    },
    updateBannerPicutre(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }

      const extension = data.data.key.split('.').pop()
      const key = _.get(this.item.banner, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setBanner({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeBannerPicutre(e) {
      this.setBanner(null);
    },
    updateStreamUrl(e) {
      this.setStreamUrl(e.target.value);
    },
    updateMinutes(e) {
      this.setMinutes(e.target.value);
    },
    updateBackgroundColor(e) {
      this.setBackgroundColor(e.target.value);
    },
    updateBackgroundStripesColor(e) {
      this.setBackgroundStripesColor(e.target.value);
    },
    updateBackgroundColorBottom(e) {
      this.setBackgroundColorBottom(e.target.value);
    },
    updateNotesArcColor(e) {
      this.setNotesArcColor(e.target.value);
    },
    updateInterprefy(e) {
      this.interprefy_disabled = true;
        if (e.target.checked) {
            this.interprefy_disabled = false;
            this.setAwsInterpretation(false)
            this.aws_interprefy_disabled =true;
            this.setAwsEnableSpeechToSpeech(false);
            this.setAwsEnableSpeechToText(false);
        }
        this.setInterprefy(e.target.checked);
        
    },
    updateInterprefyToken(e) {
      this.setInterprefyToken(e.target.value);
    },
    updateAwsInterpretation(e) {
      this.aws_interprefy_disabled = true;
      if (e.target.checked) {
          this.aws_interprefy_disabled = false;
      }
      if(e.target.checked){
        this.setInterprefy(false);
        this.interprefy_disabled= true
      }
      //interprefy
      this.setAwsInterpretation(e.target.checked);
    },
    updateAwsEnableSpeechToSpeech(e) {
      this.setAwsEnableSpeechToSpeech(e.target.checked);
    },
    updateAwsEnableSpeechToText(e) {
      this.setAwsEnableSpeechToText(e.target.checked);
    },
    updateChat(e) {
      this.setChat(e.target.checked);
    },
    getDatetime(datetime) {
      //return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss');
      //return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
      return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
        
     // return moment.utc(datetime).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
      

    },
    updateStartTime(e) {
      this.setStartTime(e.target.value);
    },
    updateEndTime(e) {
      this.setEndTime(e.target.value);
    },
    cancelClick() {
      this.resetState()
    },
    errCb(e) {
      this.$flashMessage.show({
        group : 'roundtable_edit',
        type  : 'error',
        title : 'Failed',
        html  : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
      });

    },
    UpdateSessionOpen(e) {
      this.session_open_disabled = true;
      if (e.target.checked) {
        this.session_open_disabled = false;
      }
      this.setSessionOpen(e.target.checked);
    },
    UpdateMaxNoAttendees(e) {
      this.setMaxNoAttendees(e.target.value);
    },
    UpdateAttendees(e) {
      let attenArr = [];
      _.forEach(e, function (value) {
        attenArr.push(value.id)
      });

      this.setAttendees(attenArr);
    },
    RemoveAttendees(e) {
      let cloneAttendees = _.cloneDeep(this.item.attendees);
      const index = cloneAttendees.indexOf(e.id);
      if (index > -1) { // only splice array when item is found
        cloneAttendees.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setAttendees(cloneAttendees);
    },
    showCbError() {
      this.$flashMessage.show({
        group : 'roundtable_edit',
        type  : 'error',
        title : 'Failed',
        html  : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
      });
    },
    screenLogoErrCb(errList) {
      this.showCbError();
    },
    getEventDatetime(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm');
    },

  },
}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
  border-bottom: 0px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
}

.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.toggle label {
  color: #000 !important;
}

.drop-files {
  margin-top: 10px;
}

.newspeaker {
  margin-top: 20px;
}

.newspeaker button {
  width: 100%;
}

.add_spk_btn {
  background-color: #0088CC;
  color: #fff;
}

.mini_txt {
  font-size: 0.8em;
  color: #585252;
  font-style: italic;
  margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
  border-radius: 15px;
  padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
  border-left-color: #0088CC;
}

.orangebtn {
  background-color: orange;
}

.wrapp {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.surveytitlehd {
    background: #0088CC;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.surveytitlehd label {
    color: #fff !important;
    font-weight: bold
}

.surveytitlehd span {
    margin-left: 20px;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}
</style>