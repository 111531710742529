import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"

function initialState() {
    return {
        item: {
           event_id             : null,
           host_id              : null,
           is_owner             : false,
           is_moderator         : false,
           presentation_video   : null,
           presentation_file    : null,
           _id                  : null,
           presentation_slides  : [],
           
        },
        loading: false,
        is_new: false,
        is_editing: false,
   
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    roomsAll: state => state.roomsAll,
    eventusersAll: state => state.eventusersAll,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
   
}

const actions = {
    fetchSingleHost({ commit, state ,dispatch},data){
        let cloneObj  = _.cloneDeep(data);
        cloneObj.host_id = cloneObj.host_id._id;
        console.log("fetchSingleHostfetchSingleHostfetchSingleHost",data);
        commit('setItem',cloneObj)
    },
    destroyData({ commit, state ,dispatch},postData){
        commit('setLoading', true);
     
        let meetingId   = postData.meeting_id;
        let hostId      = postData.host_id;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/meetings/'+meetingId+'/host/'+hostId, {headers: headers})
        .then((response) => {
            commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    async storeData({ commit, state, dispatch },meetingId) {
        commit('setLoading', true)
        const params = _.cloneDeep(state.item);
        delete params._id;
        if (params.presentation_file) {
            params.presentation_file = {
                key         : params.presentation_file.key,
                old_key     : params.presentation_file.key,
                temp_key    : params.presentation_file.temp_key,
                size        : params.presentation_file.size,
                extension   : params.presentation_file.extension,
                uploadedBy  : params.presentation_file.uploadedBy,
                uploadedAt  : params.presentation_file.uploadedAt,
            }
        }

        if (params.presentation_video) {
            params.presentation_video = {
                key         : params.presentation_video.key,
                old_key     : params.presentation_video.key,
                temp_key    : params.presentation_video.temp_key,
                size        : params.presentation_video.size,
                extension   : params.presentation_video.extension,
                uploadedBy  : params.presentation_video.uploadedBy,
                uploadedAt  : params.presentation_video.uploadedAt,
            }
        }
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/meetings/'+meetingId+'/host', params, {headers: headers})
        .then((response) => {
            //commit('resetState')
            return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
       
    },
    async updateData({ commit, state, dispatch },postData) {
        commit('setLoading', true)
        console.log("Post DAta******",postData);
        let meetingId   = postData.meetingId;
        let hostId      = postData.host_id;
        const params = _.cloneDeep(state.item);
        delete params._id;


        if (_.has(state.item.presentation_file, 'key')) {
            let imgPath = _.replace(state.item.presentation_file.key, '/draft', '');
            params.presentation_file = {
                key: imgPath,
                old_key: (!_.isNull(state.item.presentation_file.key) && !_.isNull(state.item.presentation_file.key.match(/\/draft\//g))) ? state.item.presentation_file.key : null,
                size: params.presentation_file.size,
                extension: params.presentation_file.extension,
                uploadedBy: params.presentation_file.uploadedBy,
                uploadedAt: params.presentation_file.uploadedAt,
            }
        }


       

        if (_.has(state.item.presentation_video, 'key')) {
                    let imgPath = _.replace(state.item.presentation_video.key, '/draft', '');
                    params.presentation_video = {
                        key: imgPath,
                        old_key: (!_.isNull(state.item.presentation_video.key) && !_.isNull(state.item.presentation_video.key.match(/\/draft\//g))) ? state.item.presentation_video.key : null,
                        size: params.presentation_video.size,
                        extension: params.presentation_video.extension,
                        uploadedBy: params.presentation_video.uploadedBy,
                        uploadedAt: params.presentation_video.uploadedAt,
                    }
                }
        
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/meetings/'+meetingId+'/host/'+hostId, params, {headers: headers})
        .then((response) => {
           // commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    fetchData({ commit, dispatch }, params) {
        axios.get('/api/v1/meeting-room-hosts/' + params.id)
            .then(response => {

                let agendaData = response.data.data
                //debugger;
                //agendaData.map((obj, i) => {
                if (agendaData.event_users) {
                    agendaData.event_users.first_name = agendaData.event_users.first_name + " " + agendaData.event_users.last_name;
                }


                commit('setItem', agendaData)

                // commit('setItem', response.data.data)
            })

        dispatch('fetchRoomsAll', params.room_id)
        //dispatch('fetchOrdersAll', params.agenda_id)
        dispatch('fetchEventusersAll', params)
    },
    fetchRoomsAll({ commit }, room_id) {
        commit('setRoom', room_id)

    },

    fetchEventusersAll({ commit }, params) {
        axios.get('/api/v1/get_event_hosts/' + params.event_id)
            .then(response => {

                let eventUsers = response.data.data
                //debugger;
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })
                commit('setEventusersAll', eventUsers)

            })
    },
    
    setPresentationVideo({commit},value){
        commit('setPresentationVideo',value)
    },
    setPresentationFile({commit},value){
        commit('setPresentationFile',value)
    },
    setPresentationSlides({commit},value){
        commit('setPresentationSlides',value)
    },
    setIsModerator({commit},value){
        commit('setIsModerator',value)
    },
    setIsOwner({commit},value){
        commit('setIsOwner',value)
    },
    setHostId({commit},value){
        commit('setHostId',value)
    },
    setEventId({commit},value){
        commit('setEventId',value)
    },
    resetState({ commit },value) {
        commit('resetState',value)
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    }
}

const mutations = {
    setPresentationVideo(state,value){
        state.item.presentation_video = value
    },
    setPresentationFile(state,value){
        state.item.presentation_file = value
    },
    setPresentationSlides(state, value){
        state.item.presentation_slides = value
    },
    setIsModerator(state,value){
        state.item.is_moderator = value
    },
    setIsOwner(state,value){
        state.item.is_owner = value
    },
    setHostId(state, value) {
        console.log("Mutation host id",value);
        state.item.host_id = value;
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setItem(state, item) {
        state.item = item
    },
    resetState(state) {
        console.log("before*******",state);
        state = Object.assign(state, initialState())
        console.log("after*******",state);
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setEventId(state,value){
        state.item.event_id = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
