import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";
import moment from "moment";

function initialState() {
    return {
        item: {
            time_zone:null,
            event_id: null,            
            translations: [],
            moderator: null,
            second_moderator: null,
            exhibitor: null,
            start_time: null,
            end_time: null,
            is_restricted: false,
            minutes: 0,
            is_rating: false,
            is_screen_sharing: false,
            video: null,
            documents: null,
            is_live_streaming: false,
            stream_url: null,
            screen_logo: null,
            session_logo: null,
            is_background_picture: false,
            background_picture: null,
            background_color: "#ffffff",
            background_stripes_color: "#ffffff",
            stage_color1: "#ffffff",
            stage_color2: "#ffffff",
            background_color_bottom: "#ffffff",
            left1_pillar_color: "#ffffff",
            left1_pillar_color_bottom: "#ffffff",
            pillar_left1_logo1: null,
            pillar_left1_logo2: null,
            pillar_left1_logo3: null,
            left2_pillar_color: "#ffffff",
            left2_pillar_color_bottom: "#ffffff",
            pillar_left2_logo1: null,
            pillar_left2_logo2: null,
            pillar_left2_logo3: null,
            right1_pillar_color: "#ffffff",
            right1_pillar_color_bottom: "#ffffff",
            pillar_right1_logo1: null,
            pillar_right1_logo2: null,
            pillar_right1_logo3: null,
            right2_pillar_color: "#ffffff",
            right2_pillar_color_bottom: "#ffffff",
            pillar_right2_logo1: null,
            pillar_right2_logo2: null,
            pillar_right2_logo3: null,
            left1_pillar_header_color: "#fff",
            left1_pillar_bottom_color: "#fff",
            
            interpretation: {
                aws_interpretation:false,
                is_interprefy_type_enabled: false,
                interprefy_token: null,
                // is_aws_type_enabled: false,
                is_aws_speech_to_speech_enabled: false,
                is_aws_speech_to_text_enabled: false,

              
            },
           
            created_by : null,
        },
        eventsAll: {},
        eventlocationsAll: [],
        event_timezone: null,
        loading: false,
        is_new: false,
        is_editing: false,       
        panelistitems: [],
        filteredpanelistitems: [],
        surveyitems: [],
        lang_key : null,
        lang_title : null,
        lang_descirption : null,
        error_translation_tab:false,
        error_general_tab:false,
        time_zone:null,

        getpanelist:[],

        documents_slides:[],
        items: {
            
            existingpanelistid:null, 
            panelist: {
                event_id:           null,
                panelist_id:        null,
                presentation_order: 0,
                presentation_duration:0,
                presentation_video:null,
                presentation_file:null,
                created_by : 'Suresh',
                presentation_slides:null
            },
            
        }, 
        preorder:[],  
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    eventsAll: state => state.eventsAll,
    eventlocationsAll: state => state.eventlocationsAll,
    event_timezone: state => state.event_timezone,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,   
    panelistitems: state => state.panelistitems,
    filteredpanelistitems: state => state.filteredpanelistitems,
    surveyitems: state => state.surveyitems,
    selectedLanguageKey : state => state.lang_key,
    selectedLanguageTitle : state => state.lang_title,
    selectedLanguageDescirption : state => state.lang_descirption,
    error_translation_tab: state => state.error_translation_tab,
    error_general_tab: state => state.error_general_tab,    

    getpanelist:state=> state.getpanelist,
    existingpanelistid:state=> state.items.existingpanelistid,

    preorder:state => state.preorder,
    items: state => state.items
   // panelist_id:state=> state.items.panelist.panelist_id,
    
   
    
    

    
}

const actions = {
    setItem({commit}, item) {
        commit('setItem', item);
    },   
    async storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');
       

        const params = _.cloneDeep(state.item);
        console.log("paramsparams",params);
        //return;
        delete params.panelist;
        params.event_id = state.event_id;
       // delete params.aws_interpretation;
        delete params.left1_pillar_bottom_color;

        delete params.time_zone;
     


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
   

        return axios.post(constants.API_URL + '/api/panel-discussions', params, {headers: headers})
            .then((response) => {   
                commit('resetState')             
                return response;
               
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    updateData({ commit, state, dispatch}, id) {
        commit('setLoading', true)

        console.log("before start time",state.item.start_time);
        console.log("before end time",state.item.end_time);
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');


        console.log("after start time",state.item.start_time);
        console.log("after end time",state.item.end_time);
       


        let params = _.cloneDeep(state.item);

        delete params.time_zone;
     

        console.log('Agenda ID is ===============',params);


//console.log('************', params);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.panelist;

       
       

        // delete params.second_moderator;

        // delete params.interpretation.is_interprefy_type_enabled;
        // delete params.interpretation.is_aws_type_enabled;
        // delete params.interpretation.is_aws_speech_to_speech_enabled;
        // delete params.interpretation.is_aws_speech_to_text_enabled;

        _.map(params.translations, i => delete i._id);

       
        if (_.isObject(params.moderator)) {
            params.moderator = _.get(params.moderator, '_id', null);
           
        }

        if (_.isObject(params.exhibitor)) {
            params.exhibitor = _.get(params.exhibitor, '_id', null);
           
        }

        if (_.isObject(params.second_moderator)) {
            params.second_moderator = _.get(params.second_moderator, '_id', null);
           
        }



        if (params.video) {
            params.video = {
                key: params.video.key,
                temp_key: params.video.temp_key,
                old_key: params.video.key,
                size: params.video.size,
                extension: params.video.extension,
                uploadedBy: params.video.uploadedBy,
                uploadedAt: params.video.uploadedAt,
            }
        }

        if (params.documents) {
            params.documents = {
                key:  params.documents.key,               
                old_key: params.documents.key,
                size: params.documents.size,
                temp_key: params.documents.temp_key,
                extension: params.documents.extension,
                uploadedBy: params.documents.uploadedBy,
                uploadedAt: params.documents.uploadedAt,
            }
        }

        if (_.has(state.item.screen_logo, 'key')) {
            //let imgPath = _.replace(state.item.screen_logo.key, '/draft', '');
            params.screen_logo = {
                key     : params.screen_logo.key,
                old_key : params.screen_logo.key,
              
              //  key: imgPath,
              //  old_key: (!_.isNull(state.item.screen_logo.key) && !_.isNull(state.item.screen_logo.key.match(/\/draft\//g))) ? state.item.screen_logo.key : null,
                size: params.screen_logo.size,
                extension: params.screen_logo.extension,
                uploadedBy: params.screen_logo.uploadedBy,
                uploadedAt: params.screen_logo.uploadedAt,
            }
        }

        if (params.session_logo) {
            params.session_logo = {
                key: params.session_logo.key,
                old_key: params.session_logo.key,
                size: params.session_logo.size,
                temp_key: params.session_logo.temp_key,
                extension: params.session_logo.extension,
                uploadedBy: params.session_logo.uploadedBy,
                uploadedAt: params.session_logo.uploadedAt,
            }
        }

        if (params.background_picture) {
            params.background_picture = {
                key: params.background_picture.key,
                old_key: params.background_picture.key,
                size: params.background_picture.size,
                temp_key: params.background_picture.temp_key,
                extension: params.background_picture.extension,
                uploadedBy: params.background_picture.uploadedBy,
                uploadedAt: params.background_picture.uploadedAt,
            }
        }

        if (params.speaker_picutre) {
            params.speaker_picutre = {
                key: params.speaker_picutre.key,
                old_key: params.speaker_picutre.key,
                size: params.speaker_picutre.size,
                temp_key: params.speaker_picutre.temp_key,
                extension: params.speaker_picutre.extension,
                uploadedBy: params.speaker_picutre.uploadedBy,
                uploadedAt: params.speaker_picutre.uploadedAt,
            }
        }

        if (params.pillar_left1_logo1) {
            params.pillar_left1_logo1 = {
                key: params.pillar_left1_logo1.key,
                old_key: params.pillar_left1_logo1.key,
                size: params.pillar_left1_logo1.size,
                temp_key: params.pillar_left1_logo1.temp_key,
                extension: params.pillar_left1_logo1.extension,
                uploadedBy: params.pillar_left1_logo1.uploadedBy,
                uploadedAt: params.pillar_left1_logo1.uploadedAt,
            }
        }

        if (params.pillar_left1_logo2) {
            params.pillar_left1_logo2 = {
                key: params.pillar_left1_logo2.key,
                old_key: params.pillar_left1_logo2.key,
                size: params.pillar_left1_logo2.size,
                temp_key: params.pillar_left1_logo2.temp_key,
                extension: params.pillar_left1_logo2.extension,
                uploadedBy: params.pillar_left1_logo2.uploadedBy,
                uploadedAt: params.pillar_left1_logo2.uploadedAt,
            }
        }

        if (params.pillar_left1_logo3) {
            params.pillar_left1_logo3 = {
                key: params.pillar_left1_logo3.key,
                old_key: params.pillar_left1_logo3.key,
                size: params.pillar_left1_logo3.size,
                temp_key: params.pillar_left1_logo3.temp_key,
                extension: params.pillar_left1_logo3.extension,
                uploadedBy: params.pillar_left1_logo3.uploadedBy,
                uploadedAt: params.pillar_left1_logo3.uploadedAt,
            }
        }

        if (params.pillar_left2_logo1) {
            params.pillar_left2_logo1 = {
                key: params.pillar_left2_logo1.key,
                old_key: params.pillar_left2_logo1.key,
                size: params.pillar_left2_logo1.size,
                temp_key: params.pillar_left2_logo1.temp_key,
                extension: params.pillar_left2_logo1.extension,
                uploadedBy: params.pillar_left2_logo1.uploadedBy,
                uploadedAt: params.pillar_left2_logo1.uploadedAt,
            }
        }

        if (params.pillar_left2_logo2) {
            params.pillar_left2_logo2 = {
                key: params.pillar_left2_logo2.key,
                old_key: params.pillar_left2_logo2.key,
                size: params.pillar_left2_logo2.size,
                temp_key: params.pillar_left2_logo2.temp_key,
                extension: params.pillar_left2_logo2.extension,
                uploadedBy: params.pillar_left2_logo2.uploadedBy,
                uploadedAt: params.pillar_left2_logo2.uploadedAt,
            }
        }

        if (params.pillar_left2_logo3) {
            params.pillar_left2_logo3 = {
                key: params.pillar_left2_logo3.key,
                old_key: params.pillar_left2_logo3.key,
                size: params.pillar_left2_logo3.size,
                temp_key: params.pillar_left2_logo3.temp_key,
                extension: params.pillar_left2_logo3.extension,
                uploadedBy: params.pillar_left2_logo3.uploadedBy,
                uploadedAt: params.pillar_left2_logo3.uploadedAt,
            }
        }

        if (params.pillar_right1_logo1) {
            params.pillar_right1_logo1 = {
                key: params.pillar_right1_logo1.key,
                old_key: params.pillar_right1_logo1.key,
                size: params.pillar_right1_logo1.size,
                temp_key: params.pillar_right1_logo1.temp_key,
                extension: params.pillar_right1_logo1.extension,
                uploadedBy: params.pillar_right1_logo1.uploadedBy,
                uploadedAt: params.pillar_right1_logo1.uploadedAt,
            }
        }

        if (params.pillar_right1_logo2) {
            params.pillar_right1_logo2 = {
                key: params.pillar_right1_logo2.key,
                old_key: params.pillar_right1_logo2.key,
                size: params.pillar_right1_logo2.size,
                temp_key: params.pillar_right1_logo2.temp_key,
                extension: params.pillar_right1_logo2.extension,
                uploadedBy: params.pillar_right1_logo2.uploadedBy,
                uploadedAt: params.pillar_right1_logo2.uploadedAt,
            }
        }

        if (params.pillar_right1_logo3) {
            params.pillar_right1_logo3 = {
                key: params.pillar_right1_logo3.key,
                old_key: params.pillar_right1_logo3.key,
                size: params.pillar_right1_logo3.size,
                temp_key: params.pillar_right1_logo3.temp_key,
                extension: params.pillar_right1_logo3.extension,
                uploadedBy: params.pillar_right1_logo3.uploadedBy,
                uploadedAt: params.pillar_right1_logo3.uploadedAt,
            }
        }

        if (params.pillar_right2_logo1) {
            params.pillar_right2_logo1 = {
                key: params.pillar_right2_logo1.key,
                old_key: params.pillar_right2_logo1.key,
                size: params.pillar_right2_logo1.size,
                temp_key: params.pillar_right2_logo1.temp_key,
                extension: params.pillar_right2_logo1.extension,
                uploadedBy: params.pillar_right2_logo1.uploadedBy,
                uploadedAt: params.pillar_right2_logo1.uploadedAt,
            }
        }

        if (params.pillar_right2_logo2) {
            params.pillar_right2_logo2 = {
                key: params.pillar_right2_logo2.key,
                old_key: params.pillar_right2_logo2.key,
                size: params.pillar_right2_logo2.size,
                temp_key: params.pillar_right2_logo2.temp_key,
                extension: params.pillar_right2_logo2.extension,
                uploadedBy: params.pillar_right2_logo2.uploadedBy,
                uploadedAt: params.pillar_right2_logo2.uploadedAt,
            }
        }

        if (params.pillar_right2_logo3) {
            params.pillar_right2_logo3 = {
                key: params.pillar_right2_logo3.key,
                old_key: params.pillar_right2_logo3.key,
                size: params.pillar_right2_logo3.size,
                temp_key: params.pillar_right2_logo3.temp_key,
                extension: params.pillar_right2_logo3.extension,
                uploadedBy: params.pillar_right2_logo3.uploadedBy,
                uploadedAt: params.pillar_right2_logo3.uploadedAt,
            }
        }
       

               

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

      
        
        return axios.patch(constants.API_URL + '/api/panel-discussions/'+ id, params, {headers: headers})      
       
        .then((response) => {


          //  let panelroom = response[0].data.data;
          //  let panelist = response[1].data.data; 

                commit('setItem',  response.data.data);
              //  commit('setItem', panelist);
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },


    updatePanelistData({ commit, state, dispatch}, multidata) { 
             
      
        commit('setLoading', true)      
        let params = _.cloneDeep(state.getpanelist);


        params.event_id = multidata.eventid;   
        
        


        console.log('adddddddddddd',params);
        
        

        if (_.isObject(params.panelist_id)) {
            params.panelist_id = _.get(params.panelist_id, '_id', null);
           
        }  
        
        for(let par of params){
           
       
        
        if (par.presentation_file) {
            par.presentation_file = {
                key:  par.presentation_file.key,               
                old_key: par.presentation_file.key,
                size: par.presentation_file.size,
                temp_key: par.presentation_file.temp_key,
                extension: par.presentation_file.extension,
                uploadedBy: par.presentation_file.uploadedBy,
                uploadedAt: par.presentation_file.uploadedAt,
            }
        }


        if (par.presentation_video) {
            par.presentation_video = {
                key:  par.presentation_video.key,               
                old_key: par.presentation_video.key,
                size: par.presentation_video.size,
                temp_key: par.presentation_video.temp_key,
                extension: par.presentation_video.extension,
                uploadedBy: par.presentation_video.uploadedBy,
                uploadedAt: par.presentation_video.uploadedAt,
            }
        }       
    }

       
         console.log('Oldddddd ==============',state.items.existingpanelistid); 

                       

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

              
        axios.patch(constants.API_URL + '/api/panel-discussions/' + multidata.agenda_id + '/panelist/' , params, {headers: headers}) 
          
       .then((response) => {

                commit('setContainer', response.data.data);               
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },


    storeNewPanelistData({ commit, state, dispatch }, multidata) {
        commit('setLoading', true)        

        const params = _.cloneDeep(state.items.panelist);
       
       
        params.event_id = multidata.eventid;


        if (params.presentation_file) {
            params.presentation_file = {
                key:  params.presentation_file.key,               
                old_key: params.presentation_file.key,
                size: params.presentation_file.size,
                temp_key: params.presentation_file.temp_key,
                extension: params.presentation_file.extension,
                uploadedBy: params.presentation_file.uploadedBy,
                uploadedAt: params.presentation_file.uploadedAt,
            }
        }


        if (params.presentation_video) {
            params.presentation_video = {
                key:  params.presentation_video.key,               
                old_key: params.presentation_video.key,
                size: params.presentation_video.size,
                temp_key: params.presentation_video.temp_key,
                extension: params.presentation_video.extension,
                uploadedBy: params.presentation_video.uploadedBy,
                uploadedAt: params.presentation_video.uploadedAt,
            }
        }


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
   

        return axios.post(constants.API_URL + '/api/panel-discussions/'+multidata.agenda_id+'/panelist', params, {headers: headers})
            .then(response => {
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },






    fetchData({ state, commit, dispatch }, agenda_id) {          

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        let totalpanelistReq = axios.get(`${constants.API_URL}/api/event-user/event-id/${state.item.event_id}/Panelist`, {headers: headers});
       // let surveyReq = axios.get(`${constants.API_URL}/api/survey/6461f98182d98e95c2c85b00`, {headers: headers}); 
        let panelist = axios.get(`${constants.API_URL}/api/panel-discussions/${agenda_id}`, {headers: headers}); 
        let getfilteredpanelist = axios.get(`${constants.API_URL}/api/panel-discussions/event-users/${agenda_id}`, {headers: headers});
        
       

        Promise.all([totalpanelistReq, panelist, getfilteredpanelist]).then((response) => {
           
           
            
            let totalpanelistReq = response[0].data.data;              
            //let surveyReq = response[1].data.data.questions_detail;           
            let canBePanelist = []; 

            let panelist = response[1].data.data.panelist;  
            
            let filterpanelistlist = response[2].data.data;
            console.log("filter_panelist",filterpanelistlist);


            let canBeFilteredPanelist = []; 




                _.forEach(totalpanelistReq, function (value) {
                    canBePanelist.push({ id: `${value._id}`, name: `${value.first_name} ${value.last_name}` })
                }); 
                
                
                _.forEach(filterpanelistlist, function (value) {
                    canBeFilteredPanelist.push({ id: `${value._id}`, name: `${value.first_name} ${value.last_name}` })
                }); 
               
                commit('setPanelistItems', canBePanelist);  // panelist list  
               //sk commit('setSureyItems', surveyReq);
                commit('setFilteredPanelist', canBeFilteredPanelist); //filtered panelist list

                for(let pan of panelist){
                    pan.panelist_id = pan.panelist_id._id;
                }
                commit('setPanelist', panelist);

                //commit('setLocalTime', 'start_time')
                //commit('setLocalTime', 'end_time')

                dispatch('fetchPresentationOrderAll',panelist)
               // commit('setLocalTime', 'start_time')
               // commit('setLocalTime', 'end_time')
 
             

                return response;
            }).catch(error => {                
                 console.log("Error",error);
               


            })
    },


    fetchPresentationOrderAll({commit},panelist){
       
        let preOrderArr= [];

        let mapped_array = _.map(panelist, 'presentation_order');           
        let newArray = _.flatten(mapped_array);

        for(let i=1;i<30;i++){

          if(!newArray.includes(i)){
            preOrderArr.push({id:i,name:i});
          }
        }
        commit('setPreOrder',preOrderArr)
    },




    fetchEventlocationsAll({ commit }, event_id) {
        return axios.get('/api/v1/event/' + event_id + '/event-locations')
            .then(response => {
                commit('setEventlocationsAll', response.data.data)
                return response;
            })
    },
    fetchEventsAll({ commit }, event_id) {
        commit('setEvent', event_id)
        return axios.get('/api/v1/events/' + event_id)
            .then(response => {
                commit('setEventAll', response.data.data)
                return response;
            })
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setEvent_users({ commit }, value) {
        commit('setEvent_users', value)
    },
    setEvent_userss({ commit }, value) {
        commit('setEvent_userss', value)
    },
    setMinutes({ commit }, value) {
        commit('setMinutes', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setLogo({ commit }, value) {
        commit('setLogo', value)
    },
    setSessionTheme({ commit }, value) {
        commit('setSessionTheme', value)
    },
    setExhibitor({ commit }, value) {
        commit('setExhibitor', value)
    },
    setIs_rating({ commit }, value) {
        commit('setIs_rating', value)
    },
    setScreenSharing({ commit }, value) {
        commit('setScreenSharing', value)
    },
    setIsLiveStream({ commit }, value) {
        commit('setIsLiveStream', value)
    },
    setStreamUrl({ commit }, value) {
        commit('setStreamUrl', value)
    },
    setIs_restricted({ commit }, value) {
        commit('setIs_restricted', value)
    },
    setVideos({ commit }, value) {
        commit('setVideos', value)
    },
    setDocuments({ commit }, value) {
        commit('setDocuments', value)
    },
    setDocumentsSlides({ commit }, value){
        commit('setDocumentsSlides', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setStageColor1({ commit }, value) {
        commit('setStageColor1', value)
    },
    setStageColor2({ commit }, value) {
        commit('setStageColor2', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setBackgroundStripesColor({ commit }, value) {
        commit('setBackgroundStripesColor', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setRight1PillarColor({ commit }, value) {
        commit('setRight1PillarColor', value)
    },
    setRight2PillarColor({ commit }, value) {
        commit('setRight2PillarColor', value)
    },
    setLeft1PillarBottomColor({ commit }, value) {
        commit('setLeft1PillarBottomColor', value)
    },
    setLeft2PillarBottomColor({ commit }, value) {
        commit('setLeft2PillarBottomColor', value)
    },
    setLeft1PillarColor({ commit }, value) {
        commit('setLeft1PillarColor', value)
    },
    setLeft2PillarColor({ commit }, value) {
        commit('setLeft2PillarColor', value)
    },
    setRight1PillarBottomColor({ commit }, value) {
        commit('setRight1PillarBottomColor', value)
    },
    setRight2PillarBottomColor({ commit }, value) {
        commit('setRight2PillarBottomColor', value)
    },
    setPillarLeft1Logo1({ commit }, value) {
        commit('setPillarLeft1Logo1', value)
    },
    setPillarLeft1Logo2({ commit }, value) {
        commit('setPillarLeft1Logo2', value)
    },
    setPillarLeft1Logo3({ commit }, value) {
        commit('setPillarLeft1Logo3', value)
    },
    setPillarLeft2Logo1({ commit }, value) {
        commit('setPillarLeft2Logo1', value)
    },
    setPillarLeft2Logo2({ commit }, value) {
        commit('setPillarLeft2Logo2', value)
    },
    setPillarLeft2Logo3({ commit }, value) {
        commit('setPillarLeft2Logo3', value)
    },
    setPillarRight1Logo1({ commit }, value) {
        commit('setPillarRight1Logo1', value)
    },
    setPillarRight1Logo2({ commit }, value) {
        commit('setPillarRight1Logo2', value)
    },
    setPillarRight1Logo3({ commit }, value) {
        commit('setPillarRight1Logo3', value)
    },
    setPillarRight2Logo1({ commit }, value) {
        commit('setPillarRight2Logo1', value)
    },
    setPillarRight2Logo2({ commit }, value) {
        commit('setPillarRight2Logo2', value)
    },
    setPillarRight2Logo3({ commit }, value) {
        commit('setPillarRight2Logo3', value)
    },
    setInterpretation({ commit }, value) {
        commit('setInterpretation', value)
    },
    setIsBackgroundPicture({ commit }, value) {
        commit('setIsBackgroundPicture', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value)
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value)
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },
    setEventTd({ commit }, value) {
        commit('setEventTd', value)
    },
    setCreatedBy({ commit }, value) {
        commit('setCreatedBy', value)
    },
    setInterprefy({ commit }, value) {
        commit('setInterprefy', value)
    },
    setInterprefyToken({ commit }, value) {
        commit('setInterprefyToken', value)
    },
    setAwsInterpretation({ commit }, value) {
        commit('setAwsInterpretation', value)
    },
    setAwsEnableSpeechToSpeech({ commit }, value) {
        commit('setAwsEnableSpeechToSpeech', value)
    },
    setAwsEnableSpeechToText({ commit }, value) {
        commit('setAwsEnableSpeechToText', value)
    },
    setChat({ commit }, value) {
        commit('setChat', value)
    },    
     
    setPresentationOrder({ commit }, value) {
        commit('setPresentationOrder', value)
    },
    setPresentationOrderNew({ commit }, value) {
        commit('setPresentationOrderNew', value)
    },
    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescirption({ commit }, value) {
        commit('setLangDescirption', value)
    },
    setPanelistID({ commit }, value) {
        commit('setPanelistID', value)
    }, 
    setPanelistIDNew({ commit }, value) {
        commit('setPanelistIDNew', value)
    },   
    setErrorTranslationTab({commit},value){
        commit('setErrorTranslationTab', value)
  
    },
    setErrorGeneralTab({commit},value){
        commit('setErrorGeneralTab', value)
  
    },
    setTimeZone({ commit }, value){
        commit('setTimeZone', value)
    },

    setPanelist({ commit }, value){
        commit('setPanelist', value)
    },  
    setExistingpanelistID({ commit }, value) {
        commit('setExistingpanelistID', value)
    },  
    setDuration({ commit }, value) {
        commit('setDuration', value)
    },
    setDurationNew({ commit }, value) {
        commit('setDurationNew', value)
    },

    setLeft1PillarHeaderColor({ commit }, value) {
        commit('setLeft1PillarHeaderColor', value)
    },

    setLeft2PillarColorBottom({ commit }, value) {
        commit('setLeft2PillarColorBottom', value)
    },

    setRight1PillarColorBottom({ commit }, value) {
        commit('setRight1PillarColorBottom', value)
    },
    setRight2PillarColorBottom({ commit }, value) {
        commit('setRight2PillarColorBottom', value)
    },

    setPanelistVideo({ commit }, value) {
        commit('setPanelistVideo', value)
    },
    setPanelistVideoNew({ commit }, value) {
        commit('setPanelistVideoNew', value)
    },
    setPanelistDocument({ commit }, value) {
        commit('setPanelistDocument', value)
    },
    setPanelistDocumentNew({ commit }, value) {
        commit('setPanelistDocumentNew', value)
    },
    
   


}

const mutations = {

    setTimeZone(state,value) {
        state.item.time_zone = value
    },

    setPreOrder(state,value){
        state.preorder = value
    },

    setRight2PillarColorBottom(state, value) {
        state.item.right2_pillar_color_bottom = value
    },

    
    setRight1PillarColorBottom(state, value) {
        state.item.right1_pillar_color_bottom = value
    },

    setLeft2PillarColorBottom(state, value) {
        state.item.left2_pillar_color_bottom = value
    },

    setLeft1PillarHeaderColor(state, value) {
        state.item.left1_pillar_header_color = value
    },
  

    setExistingpanelistID(state, value) {
        state.items.existingpanelistid = value
    },

    setErrorGeneralTab(state, value) {
        state.error_general_tab = value
    },

    setErrorTranslationTab(state, value) {
        state.error_translation_tab = value
    },

    setPanelistID(state, value) {
      //  console.log('vvvvv',value)        
     // state.getpanelist[value.keyval].panelist_id._id = value.selval  
      state.getpanelist[value.keyval].panelist_id = value.selval  
    },

    setPanelistIDNew(state, value) {              
        state.items.panelist.panelist_id = value 
      },

    setPresentationOrder(state, value) {
       // state.items.panelist.presentation_order = value
        state.getpanelist[value.keyval].presentation_order = value.selval 
    },

    setPresentationOrderNew(state, value) {
         state.items.panelist.presentation_order = value         
     },


    setDuration(state,value) {
        // state.items.panelist.presentation_duration = value
        state.getpanelist[value.keyval].presentation_duration = value.selval
    },

    setDurationNew(state,value) {
         state.items.panelist.presentation_duration = value
        
    },


    setPanelistDocument(state,value) {   
        console.log("value===>",value);          
        if (_.inRange(value, 0, 20)) {
            state.getpanelist[value].presentation_file = null
            state.getpanelist[value].presentation_slides = [];
        }else{
        //state.items.panelist.presentation_file = value
        state.getpanelist[value.doc_array_index].presentation_file = value
        }
    },


    setPanelistDocumentNew(state,value) {  
        console.log("value",value);      
        state.items.panelist.presentation_file = value  

        console.log("value222",state.items);      
   
    },

    setPanelistVideo(state, value) {
        if (_.inRange(value, 0, 20)) {
            state.getpanelist[value].presentation_video = null
        }else{
            state.getpanelist[value.doc_array_index].presentation_video = value
        }
       // state.items.panelist.presentation_video = value
    },

    setPanelistVideoNew(state, value) {       
        state.items.panelist.presentation_video = value
    },
   
    setSureyItems(state, items) {
        state.surveyitems = items
    },   
    setPanelistItems(state, items) {
        state.panelistitems = items
    },
    setFilteredPanelist(state, items) {
        state.filteredpanelistitems = items
    },
    // setPanelist(state, value){
    //     state.getpanelist = value;
    // },
    setEventTimezone(state, item) {
        state.event_timezone = item;
    },
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setIsBackgroundPicture(state, value) {
        state.item.is_background_picture = value
    },
    setEvent_users(state, value) {
        state.item.moderator = value
    },
    setEvent_userss(state, value) {
        state.item.second_moderator = value
    },
    setMinutes(state, value) {
        state.item.minutes = value
    },
    setBackgroundColor(state, value) {
        state.item.background_color = value
    },
    setStageColor1(state, value) {
        state.item.stage_color1 = value
    },
    setStageColor2(state, value) {
        state.item.stage_color2 = value
    },
    setBackgroundStripesColor(state, value) {
        state.item.background_stripes_color = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setStart_time(state, value) {  
        console.log("set start time",value);  
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setEventAll(state, value) {
        state.eventsAll = value
    },
    setIs_rating(state, value) {
        state.item.is_rating = value
    },
    setScreenSharing(state, value) {
        state.item.is_screen_sharing = value
    },
    setIs_restricted(state, value) {
        state.item.is_restricted = value
    },
    setIsLiveStream(state, value) {
        state.item.is_live_streaming = value
    },
    setStreamUrl(state, value) {
        state.item.stream_url = value
    },
    setExhibitor(state, value) {        
        state.item.exhibitor = value
    },
    setLogo(state, value) {
        state.item.screen_logo = value
    },
    setSessionTheme(state, value) {
        state.item.session_logo = value
    },
    setVideos(state, value) {
        state.item.video = value
    },
    setDocuments(state, value) {
        state.item.documents = value
    },

    setDocumentsSlides(state, value) {
        state.item.documents_slides = value
    },
    setEventlocationsAll(state, value) {
        state.eventlocationsAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setUTCTime(state, type) {
        console.log("type==>",type);
        console.log("state.event_timezone",state.item.time_zone);
        console.log("state.item.start_time",state.item.start_time);
        if (type == 'start_time') {
           // var m = moment.tz(state.item.start_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
           // var utcTime = m.utc().format('YYYY-MM-DD HH:mm');
           // console.log("utcTime start time",utcTime);
           let dateInTimezone = moment.tz(state.item.start_time, state.item.time_zone);

           // Step 2: Convert the moment object to UTC
           let dateInUTC = dateInTimezone.utc();
        
           // Step 3: Save the UTC time and the original timezone
           var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
           console.log("utcTime startime",utcTime);
    
        }
        if (type == 'end_time') {
          /* var m = moment.tz(state.item.end_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
           var utcTime = m.utc().format('YYYY-MM-DD HH:mm');
           console.log("utcTime end time",utcTime);*/
           let dateInTimezone = moment.tz(state.item.end_time, state.item.time_zone);
           let dateInUTC = dateInTimezone.utc();
           var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
           console.log("utcTime endtime",utcTime);
    
         
        }
        if (type == 'start_time') {
            state.item.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setLocalTime(state, type) {
    
        if (type == 'end_time') {
         //   let dateFormat = moment(state.item.end_time).utc().format('YYYY-MM-DD HH:mm');
           // state.item.end_time = dateFormat;
           console.log("end time local before format",state.item.end_time );
           let dateFormat = moment.utc(state.item.end_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm:ss');
           console.log("end time local",dateFormat);
            state.item.end_time = dateFormat;
           
        }
        if (type == 'start_time') {
            //let dateFormat = moment(state.item.start_time).utc().format('YYYY-MM-DD HH:mm');
            //state.item.start_time = dateFormat;
            console.log("start time local before format",state.item.start_time,state.item.time_zone );
            //let dateFormat = moment.utc(state.item.start_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm:ss');
           // let dateFormat = moment.utc(state.item.start_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm:ss');
            let dateFormat = moment.utc(state.item.start_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
           
         
            console.log("dateFormat start time local",dateFormat)
            state.item.start_time = dateFormat;
           
        }

       
       /* if (type == 'start_time') {
            var value = state.item.start_time;
        }
        if (type == 'end_time') {
            var value = state.item.end_time;
        }
        console.log("One",type,value)
        var date = moment.utc(value).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
          
        console.log('Two:***********' ,type, date)
        var formatedDate = moment(String(date)).format('YYYY-MM-DD HH:mm');

        console.log('Three:***********' , type,formatedDate)
        // debugger
        console.log('Local Value:' + date)
        if (type == 'start_time') {

            state.item.start_time = formatedDate;

        }
        if (type == 'end_time') {
            state.item.end_time = formatedDate;
        }*/
    },


    
    setRight1PillarColor(state, value) {
        state.item.right1_pillar_color = value
    },
    setRight2PillarColor(state, value) {
        state.item.right2_pillar_color = value
    },
    setLeft1PillarBottomColor(state, value) {
        state.item.left1_pillar_color_bottom = value
    },
  
    setLeft1PillarColor(state, value) {
        state.item.left1_pillar_color = value
    },
    setLeft2PillarColor(state, value) {
        state.item.left2_pillar_color = value
    },
    setRight1PillarBottomColor(state, value) {
        state.item.right1_pillar_color_bottom = value
    },
    setRight2PillarBottomColor(state, value) {
        state.item.right2_pillar_color_bottom = value
    },
    setPillarLeft1Logo1(state, value) {
        state.item.pillar_left1_logo1 = value
    },
    setPillarLeft1Logo2(state, value) {
        state.item.pillar_left1_logo2 = value
    },
    setPillarLeft1Logo3(state, value) {
        state.item.pillar_left1_logo3 = value
    },
    setPillarLeft2Logo1(state, value) {
        state.item.pillar_left2_logo1 = value
    },
    setPillarLeft2Logo2(state, value) {
        state.item.pillar_left2_logo2 = value
    },
    setPillarLeft2Logo3(state, value) {
        state.item.pillar_left2_logo3 = value
    },
    setPillarRight1Logo1(state, value) {
        state.item.pillar_right1_logo1 = value
    },
    setPillarRight1Logo2(state, value) {
        state.item.pillar_right1_logo2 = value
    },
    setPillarRight1Logo3(state, value) {
        state.item.pillar_right1_logo3 = value
    },
    setPillarRight2Logo1(state, value) {
        state.item.pillar_right2_logo1 = value
    },
    setPillarRight2Logo2(state, value) {
        state.item.pillar_right2_logo2 = value
    },
    setPillarRight2Logo3(state, value) {
        state.item.pillar_right2_logo3 = value
    },
    setInterpretation(state, value) {
        state.item.interpretation = { ...state.item.interpretation, ...value };
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setTranslations(state, value) {
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null, "description": null }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;
    },
    setEventTd(state, value) {
        state.event_id = value
    },
    setCreatedBy(state, value) {
        state.item.created_by = value
    },
    setInterprefy(state, value) {
        state.item.interpretation.is_interprefy_type_enabled = value
    },
    setInterprefyToken(state, value) {
        state.item.interprefy_token = value
    },
    setAwsInterpretation(state, value) {
        state.item.interpretation.aws_interpretation = value
    },
    setAwsEnableSpeechToSpeech(state, value) {
        console.log('speech to speech status', value)
        state.item.interpretation.is_aws_speech_to_speech_enabled = value
    },
    setAwsEnableSpeechToText(state, value) {
        state.item.interpretation.is_aws_speech_to_text_enabled = value
    },
    setChat(state, value) {
        state.item.chat = value
    },    
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    },
    setLangDescirption(state, value) {
        state.lang_descirption = value
    },
    setPanelist(state, items){
        state.getpanelist = items;
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
