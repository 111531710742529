import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";
import moment from "moment";

function initialState() {
    return { 








        loading: false,
        is_new: false,
        is_editing: false, 
        getpanelist:[],
        items: {
            
            existingpanelistid:null, 
            panelist: {
                event_id: null,
                panelist_id:null,
                presentation_order:0,
                presentation_duration:0,
                presentation_video:null,
                presentation_file:null,
                created_by : 'Suresh',
                presentation_slides:null
            },
            
        },       
        panelistitems: [],        
    }
}

const getters = {
    items: state => state.items,
    loading: state => state.loading,   
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,    
    getpanelist:state=> state.getpanelist,
    panelistitems: state => state.panelistitems,
    panelist_id:state=> state.items.panelist.panelist_id,
    existingpanelistid:state=> state.items.existingpanelistid,     
    panelist:state => state.items.panelist,  
   
}

const actions = {
    setItem({commit}, value) {
        commit('setItem', value);
    },
    
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/' + event_id)
            .then(response => {
                commit('setEventTimezone', response.data.data[0].time_zone);
                moment.tz.setDefault(state.event_timezone);
                return response;
            })
    },

    destroyPanelistData({ commit, state }, data) {

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };        

    

        axios.delete(constants.API_URL + '/api/panel-discussions/'+ data.panelid + '/panelist/' + data.panelistid, {headers: headers})
            .then(response => {


                commit('resetState');

                commit('setItem', state.items.filter((item) => {
                    return item.panelistd != data.panelistd
                }))
                return response;

               // commit('setItem', response);               
            })




            .catch(error => {
                console.log("error messga",error);
               // let message = error.response.data.message || error.message
               // console.log(message)
            })
    },
    
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)  
        
        

       

        const params = _.cloneDeep(state.item);
        delete params.panelist;
        params.event_id = state.event_id;


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
   

        return axios.post(constants.API_URL + '/api/panel-discussions', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    


    // storeNewPanelistData({ commit, state, dispatch }, multidata) {
    //     commit('setLoading', true)        

    //     const params = _.cloneDeep(state.items.panelist);
       
       
    //     params.event_id = multidata.eventid;


    //     if (params.presentation_file) {
    //         params.presentation_file = {
    //             key:  params.presentation_file.key,               
    //             old_key: params.presentation_file.key,
    //             size: params.presentation_file.size,
    //             temp_key: params.presentation_file.temp_key,
    //             extension: params.presentation_file.extension,
    //             uploadedBy: params.presentation_file.uploadedBy,
    //             uploadedAt: params.presentation_file.uploadedAt,
    //         }
    //     }


    //     if (params.presentation_video) {
    //         params.presentation_video = {
    //             key:  params.presentation_video.key,               
    //             old_key: params.presentation_video.key,
    //             size: params.presentation_video.size,
    //             temp_key: params.presentation_video.temp_key,
    //             extension: params.presentation_video.extension,
    //             uploadedBy: params.presentation_video.uploadedBy,
    //             uploadedAt: params.presentation_video.uploadedAt,
    //         }
    //     }


    //     const headers = {
    //         'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     };
   

    //     return axios.post(constants.API_URL + '/api/panel-discussions/'+multidata.agenda_id+'/panelist', params, {headers: headers})
    //         .then(response => {
    //             return response
    //         })
    //         .finally(() => {
    //             commit('setLoading', false)
    //         })
    // },


   
    // updatePanelistData({ commit, state, dispatch}, multidata) { 
             
      
    //     commit('setLoading', true)      
    //     let params = _.cloneDeep(state.container);


    //     params.event_id = multidata.eventid;     


    //     console.log('adddddddddddd',params);
        
        

    //     if (_.isObject(params.panelist_id)) {
    //         params.panelist_id = _.get(params.panelist_id, '_id', null);
           
    //     }  
        
        
    //     if (params.presentation_file) {
    //         params.presentation_file = {
    //             key:  params.presentation_file.key,               
    //             old_key: params.presentation_file.key,
    //             size: params.presentation_file.size,
    //             temp_key: params.presentation_file.temp_key,
    //             extension: params.presentation_file.extension,
    //             uploadedBy: params.presentation_file.uploadedBy,
    //             uploadedAt: params.presentation_file.uploadedAt,
    //         }
    //     }


    //     if (params.presentation_video) {
    //         params.presentation_video = {
    //             key:  params.presentation_video.key,               
    //             old_key: params.presentation_video.key,
    //             size: params.presentation_video.size,
    //             temp_key: params.presentation_video.temp_key,
    //             extension: params.presentation_video.extension,
    //             uploadedBy: params.presentation_video.uploadedBy,
    //             uploadedAt: params.presentation_video.uploadedAt,
    //         }
    //     }

       

    //     // console.log('Agenda ID is', agenda_id);
    //     // console.log('panelist params ==============',params);  
    //     // console.log('SIRRRRRRF ==============',params.panelist.panelistid); 
    //      console.log('Oldddddd ==============',state.items.existingpanelistid); 

                       

    //     const headers = {
    //         'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     };

              
    //     axios.patch(constants.API_URL + '/api/panel-discussions/' + multidata.agenda_id + '/panelist/' + state.items.existingpanelistid , params, {headers: headers}) 
    //    // axios.patch(constants.API_URL + '/api/panel-discussions/' + multidata.agenda_id + '/panelist/65659530277d017b952c626b', params, {headers: headers})       
    //    .then((response) => {

    //             commit('setContainer', response.data.data);               
    //            return response;
    //         })
    //         .finally(() => {
    //             commit('setLoading', false)
    //         })
    // },




    fetchData({ state, commit, dispatch }, agenda_id) {          

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       
         let panelist = axios.get(`${constants.API_URL}/api/panel-discussions/${agenda_id}`, {headers: headers});
        

         Promise.all([panelist]).then((response) => {

            let totalpanelistReq = response[0].data.data.panelist;
          
           
           
          
            
                commit('setPanelist', totalpanelistReq); 
               // commit('setLocalTime', 'start_time')
               // commit('setLocalTime', 'end_time')
              


                return response;
                
            }).catch(error => {                
                 console.log("Error",error);
               


            })
    },





    
    fetchEventlocationsAll({ commit }, event_id) {
        return axios.get('/api/v1/event/' + event_id + '/event-locations')
            .then(response => {
                commit('setEventlocationsAll', response.data.data)
                return response;
            })
    },
    fetchEventsAll({ commit }, event_id) {
        commit('setEvent', event_id)
        return axios.get('/api/v1/events/' + event_id)
            .then(response => {
                commit('setEventAll', response.data.data)
                return response;
            })
    },    
    
    setPanelist({ commit }, value){
        commit('setPanelist', value)
    },  
    setPresentationOrder({ commit }, value) {
        commit('setPresentationOrder', value)
    },    
    setPanelistID({ commit }, value) {
        commit('setPanelistID', value)
    },  
    
    resetState({ commit }) {
        commit('resetState')
    },
    
    setExistingpanelistID({ commit }, value) {
        commit('setExistingpanelistID', value)
    },
    setDuration({ commit }, value) {
        commit('setDuration', value)
    },
    setPanelistVideo({ commit }, value) {
        commit('setPanelistVideo', value)
    },
    setPanelistDocument({ commit }, value) {
        commit('setPanelistDocument', value)
    },

    setIsNew({ commit }, value) {
        commit('setIsNew', value)
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value)
    },
    resetPanelistState({ commit }) {
        commit('resetPanelistState')
    },

 
    
    
    
   


}

const mutations = {   



    setPanelistDocument(state, value) {
        
        state.items.panelist.presentation_file      = value
        state.items.panelist.presentation_slides    = value;
    },

    setPanelistVideo(state, value) {
        state.items.panelist.presentation_video = value
    },

    setDuration(state,value) {
        state.items.panelist.presentation_duration = value
    },

    setExistingpanelistID(state, value) {
        state.items.existingpanelistid = value
    },

    setPanelistID(state, value) {
       console.log('ttttt',state.items)
        state.items.panelist[0].panelist_id._id = '65549cbcc857da419e06c8ab'
        
    },

    setPresentationOrder(state, value) {
        state.items.panelist.presentation_order = value
    },
       
    setPanelistItems(state, items) {
        state.panelistitems = items
    },
    setPanelist(state, items){
        state.getpanelist = items;
    },
    setEventTimezone(state, item) {
        state.event_timezone = item;
    },   
    setLoading(state, loading) {
        state.loading = loading
    },
    resetPanelistState(state) {
        state = Object.assign(state, initialState())
    },
    setUTCTime(state, type) {

        if (type == 'start_time') {

            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(state.item.start_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm:ss');

            // var ct = new Date(usaTime).toUTCString();
        }
        if (type == 'end_time') {
            var localTime = moment.tz(state.item.end_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm:ss');


        }


        if (type == 'start_time') {
            console.log(state.item.start_time);
            state.item.start_time = utcTime;
            console.log(state.item.start_time);
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }

    },
    setLocalTime(state, type) {
        console.log('live--p--end---date',state.item.end_time);
        if (type == 'end_time') {
            let dateFormat = moment(state.item.end_time).utc().format('YYYY-MM-DD HH:mm');
            state.item.end_time = dateFormat;
            console.log('live--p--end---date',state.item.end_time);
        }
        if (type == 'start_time') {
            let dateFormat = moment(state.item.start_time).utc().format('YYYY-MM-DD HH:mm');
            state.item.start_time = dateFormat;
            console.log('live--p--start---date',state.item.start_time);
        }
    },
    
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },    
    setCreatedBy(state, value) {
        state.item.created_by = value
    }, 
    setItem(state, item) {
        state.items = item
    },   
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
