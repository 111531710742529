<template>
<div class="tabs">
    <OverlayLoading :isLoading="loading" />
    <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
            <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language
                <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
           
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme Settings</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`" data-bs-toggle="tab">Interpretation Settings</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="'#survey-'+changingVariable" :href="`#changingVariable`"  data-bs-toggle="tab" @click="reRenderComponent()">Survey</a>
        </li>
        
    
    </ul>
    <div class="tab-content">
        <div id="language-settings" class="tab-pane active">
            <div class="snippet general-event-config-snippet">
                <div class="form-group row">
                    <div class="col-lg-6">

                     
                        <label>Language *</label>
                        <v-select required :rules="requiredRule" name="language" class="" placeholder="Select language"
                label="language" @option:selected="updateLaguage" :options="languages"
                :modelValue="selectedLanguageKey" />
          
                        <ValidationMessages :element="v$.item.translations" />

                    </div>
                    <div class="col-lg-6">
                        <label>Title *</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter language title" :value="getLanguageTitle()" @input="updateLanguageTitle" />
                            
                        </div>
                        <ValidationMessages :element="v$.item.translations" />

                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Description *</label>
                            <div class="kt-input-icon">
                                <textarea rows="3" class="form-control" name="description" placeholder="Enter language description" :value="getLanguageDescription()" @input="updateLanguageDescription"></textarea>
                            </div>
                            <ValidationMessages :element="v$.item.translations" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="general-settings" class="tab-pane">
            <div class="snippet general-event-config-snippet">
                <div class="form-group row">
                    <div class="col-lg-10">
                        <label>Speaker *</label>
                        <v-select placeholder="Select Speaker" label="name" :options="speakers" track-by="id" :modelValue="getSpeakerId(item.speaker)" :reduce="(option) => option.id" @option:selected="updateSpeaker" />
                        <ValidationMessages :element="v$.item.speaker" />
                    </div>
                    <div class="col-lg-2 newspeaker">
                        <v-dialog v-model="dialog" width="auto">
                            <template v-slot:activator="{ props }">
                                <v-btn color="primary" v-bind="props">Add Speaker</v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-form v-model="valid">
                                        <v-container>
                                            <v-row>
                                                <h4 class="ml-auto mr-auto text-uppercase">{{ createSpeakerBtnTxt }}</h4>
                                                <div class="col-lg-12 mb-2">
                                                    <label>Salutation *</label>
                                                    <v-select name="salutaion" class="" placeholder="Select salutaion" label="salutaion" @option:selected="updateNewSpeakerSalutation" :options="salutation" v-model="selected_salutation" />
                                                </div>
                                                <div class="col-lg-12 mb-2">
                                                    <label>Email *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="email" maxlength="200" placeholder="Enter Email" @input="updateNewSpeakerEmail" />
                                                    </div>
                                                    <span v-if="newEmailExists == true" class="mini_txt">User Already Exist. Do you want to add this user in event?</span>
                                                </div>
                                                <div class="col-lg-12 mb-2">
                                                    <label>First Name *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="first_name" maxlength="200" placeholder="Enter First Name" :value="newSpeaker.first_name" @input="updateNewSpeakerFirstName" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 mb-2">
                                                    <label>Last Name *</label>
                                                    <div class="kt-input-icon">
                                                        <input type="text" class="form-control" name="last_name" maxlength="200" placeholder="Enter Last Name" :value="newSpeaker.last_name" @input="updateNewSpeakerLastName" />
                                                    </div>
                                                </div>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions class="wrapp">
                                    <v-btn type="submit" @click="submitSpeakerForm" class="mt-2 add_spk_btn">{{createSpeakerBtnTxt }}</v-btn>
                                    <v-btn text @click="dialog = false" class="add_spk_btn mt-2 orangebtn">Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Start Time</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <input 
                            :min="getEventDatetime(eventDetail.start_date)" 
                            :max="getEventDatetime(eventDetail.end_date)" 
                            type="datetime-local" 
                            class="form-control" 
                            name="start_date" 
                            :value="getDatetime(item.start_time)" 
                            @change="updateStartTime" />
                            <span class="input-group-text rounded-0">from</span>
                        </div>
                        <ValidationMessages :element="v$.item.start_time" />

                    </div>
                    <div class="col-lg-6">
                        <label class>End Time</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <input 
                            :min="getEventDatetime(eventDetail.start_date)" 
                            :max="getEventDatetime(eventDetail.end_date)" 
                            type="datetime-local" 
                            class="form-control" 
                            name="end_date" 
                            :value="getDatetime(item.end_time)" 
                            @change="updateEndTime" />
                            <span class="input-group-text rounded-0">to</span>
                        </div>
                        <ValidationMessages :element="v$.item.end_time" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Exhibitor</label>
                        <v-select class="form-control" placeholder="Select Exhibitor" label="title" :options="listExhibitors" track-by="id" :modelValue="getSelectedExhibitor(listExhibitors,item.exhibitor)" :reduce="(option) => option.id" @option:selected="updateExhibitor" />

                    </div>
                    <div class="col-lg-6">
                        <label>Location </label>
                        <div class="kt-input-icon">
                            <input @input="updateLocation" type="text" class="form-control" name="location" maxlength="200" placeholder="Enter event location" :value="item.location" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3">
                        <label>Q&A public or private for audience?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="qa_type" :value="item.qa_type" id="customSwitchesEx" class="custom-control-input" @change="UpdateQaType" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Enable Rating ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsRating" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Screen Sharing ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_screen_sharing" :value="item.is_screen_sharing" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsScreenSharing" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Schedule Status?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="schedule_status" :value="item.schedule_status" id="customSwitchesEx" class="custom-control-input" @change="UpdateScheduleStatus" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3">
                        <label>Live Streaming ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_live_streaming" :value="item.is_live_streaming" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsLiveStreaming" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Stream URL</label>
                        <VPopper hover style="display:inline-block">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                                <img src="/assets/img/live_stream_url.jpg" />
                            </template>
                        </VPopper>

                        <div class="kt-input-icon">
                            <input type="text" :disabled="item.is_live_streaming?false:true" class="form-control" name="title" maxlength="200" placeholder="Enter Stream URL" :value="item.stream_url" @input="updateStreamUrl" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Restric User to Join before session time ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="is_restricted" :value="item.is_restricted" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsRestricted" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Minutes</label>
                        <div class="kt-input-icon">
                            <input type="text" :disabled="item.is_restricted?false:true"  class="form-control" name="title" maxlength="200" min="0" placeholder="Enter Minutes" :value="item.minutes" @input="updateMinutes" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <DropFiles :file="validate_file(item.video)" accept="video/mp4" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateVideo" @onRemoved="removeVideo" asset_type="video" @onError="errCb">
                            <template #header>
                                <label>Presentation Video (Max size 500MB and format mp4.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                    </div>
                    <div class="col-lg-6">
                        <DropFiles :file="validate_file(item.documents)" accept="application/pdf" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateDocument" @onRemoved="removeDocument" asset_type="documents" @onError="errCb">
                            <template #header>
                                <label>Presentation Document (Max size 30MB and format pdf.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <DropFiles @onError="errCb" :file="validate_file(item.screen_logo)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateScreenLogo" @onRemoved="removeScreenLogo" asset_type="screen_logo">
                            <template #header>
                                <label>Screen Logo *(Size should be 455x256 pixel and format jpg.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                    </div>
                    <div class="col-lg-6">
                        <DropFiles @onError="errCb" :file="validate_file(item.sesssion_logo)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" :dimmention="null" @onUploaded="updateSesssionLogo" @onRemoved="removeSesssionLogo" asset_type="sesssion_logo">
                            <template #header>
                                <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                    </div>
                </div>
            </div>
        </div>
        <div id="theme-settings" class="tab-pane">
            <div class="row">
                <div class="tabs tabs-vertical tabs-left">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item active" role="presentation">
                            <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`" data-bs-toggle="tab">Theme Contents</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_left_1`" :href="`#pillar_left_1`" data-bs-toggle="tab">Pillar Left 1</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_left_2`" :href="`#pillar_left_2`" data-bs-toggle="tab">Pillar Left 2</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_right_1`" :href="`#pillar_right_1`" data-bs-toggle="tab">Pillar Right 1</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :data-bs-target="`#pillar_right_2`" :href="`#pillar_right_2`" data-bs-toggle="tab">Pillar Right 2</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="theme_content" class="tab-pane active show" role="tabpanel">
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Background Color or Picture ?</label>
                                        <br />
                                        <div class="switch switch-sm switch-primary">
                                            <input-switch name="is_background_picture" :value="item.is_background_picture" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsBackgroundPicture" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="item.is_background_picture">
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.background_picture)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="1920X470" @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture" asset_type="background_picture">
                                            <template #header>
                                                <label>Background Picture (Size should be 1920x470 pixel and format JPG.) </label>
                                                <VPopper hover style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/live_stream_url.jpg" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                            <template #tooltip>
                                                <VTooltip style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #popper>
                                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                    </template>
                                                </VTooltip>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                                <div class="form-group row backgroundcolor_option" v-else>
                                    <div class="col-lg-6">
                                        <label>Background Color</label>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_color" :value="item.background_color" @input="updateBackgroundColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Background Stripes Color</label>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_stripes_color" :value="item.background_stripes_color" @input="updateBackgroundStripesColor" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Stage Color 1</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="stage_color1" :value="item.stage_color1" @input="updateStageColor1" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Stage Color 2</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="stage_color2" :value="item.stage_color2" @input="updateStageColor2" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Bottom Background Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="background_color_bottom" :value="item.background_color_bottom" @input="updateBackgroundColorBottom" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <DropFiles @onError="errCb" :file="validate_file(item.speaker_picutre)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="59X214" @onUploaded="updateSpeakerPicutre" @onRemoved="removeSpeakerPicutre" asset_type="speaker_picutre">
                                            <template #header>
                                                <label>Speaker Picture (Size should be 59x214 pixel and format PNG.)</label>
                                                <VPopper hover style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_speaker_picture.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                            <template #tooltip>
                                                <VTooltip style="display:inline-block">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #popper>
                                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                    </template>
                                                </VTooltip>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pillar_left_1" class="tab-pane" role="tabpanel">
                            <p>Pillar Left 1</p>
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="left1_pillar_header_color" :value="item.left1_pillar_header_color" @input="updateLeft1PillarHeaderColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="left1_pillar_bottom_color" :value="item.left1_pillar_bottom_color" @input="updateleft1PillarBottomColor" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo1" @onRemoved="removePillarLeft1Logo1" asset_type="pillar_left1_logo1">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo2" @onRemoved="removePillarLeft1Logo2" asset_type="pillar_left1_logo2">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft1Logo3" @onRemoved="removePillarLeft1Logo3" asset_type="pillar_left1_logo3">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pillar_left_2" class="tab-pane" role="tabpanel">
                            <p>Pillar Left 2</p>
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="left2_pillar_color" :value="item.left2_pillar_color" @input="updateLeft2PillarColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="left2_pillar_color_bottom" :value="item.left2_pillar_color_bottom" @input="updateLeft2PillarColorBottom" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo1" @onRemoved="removePillarLeft2Logo1" asset_type="pillar_left2_logo1">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo2" @onRemoved="removePillarLeft2Logo2" asset_type="pillar_left2_logo2">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarLeft2Logo3" @onRemoved="removePillarLeft2Logo3" asset_type="pillar_left2_logo3">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pl_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pillar_right_1" class="tab-pane" role="tabpanel">
                            <p>Pillar Right 1</p>
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right1_pillar_color" :value="item.right1_pillar_color" @input="updateRight1PillarColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right1_pillar_color_bottom" :value="item.right1_pillar_color_bottom" @input="updateRight1PillarColorBottom" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo1" @onRemoved="removePillarRight1Logo1" asset_type="pillar_right1_logo1">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo2" @onRemoved="removePillarRight1Logo2" asset_type="pillar_right1_logo2">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight1Logo3" @onRemoved="removePillarRight1Logo3" asset_type="pillar_right1_logo3">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pillar_right_2" class="tab-pane" role="tabpanel">
                            <p>Pillar Right 2</p>
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Pillar Header Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right2_pillar_color" :value="item.right2_pillar_color" @input="updateRight2PillarColor" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Pillar Bottom Color</label>
                                        <VPopper arrow hover style="display:inline-block" class="dark">
                                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                                            <template #content>
                                                Select color for the background
                                            </template>
                                        </VPopper>
                                        <div class="input-group color" data-plugin-colorpicker>
                                            <input type="color" class="form-control" name="right2_pillar_color_bottom" :value="item.right2_pillar_color_bottom" @input="updateRight2PillarColorBottom" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo1" @onRemoved="removePillarRight2Logo1" asset_type="pillar_right2_logo1">
                                            <template #header>
                                                <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo2" @onRemoved="removePillarRight2Logo2" asset_type="pillar_right2_logo2">
                                            <template #header>
                                                <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                    <div class="col-lg-12">
                                        <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="live-presentation" dimmention="122X90" @onUploaded="updatePillarRight2Logo3" @onRemoved="removePillarRight2Logo3" asset_type="pillar_right2_logo3">
                                            <template #header>
                                                <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                            </template>
                                            <template #tooltip>
                                                <VPopper hover style="display:inline-block" class="tooltip_img">
                                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                    <template #content>
                                                        <img src="/assets/img/lp_pr_1_logo.png" />
                                                    </template>
                                                </VPopper>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="interpretify-settings" class="tab-pane">
            <div class="snippet general-event-config-snippet">
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label>Enable "Interprefy" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.interprefy" id="customSwitchesEx" class="custom-control-input" @change="updateInterprefy" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label>Interprefy token</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter event title" :disabled="interprefy_disabled == true" @change="updateInterprefyToken" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label>Enable "AWS" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.aws_interpretation" id="customSwitchesEx" class="custom-control-input" @change="updateAwsInterpretation" />
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label>Enable "Speech to Speech"</label>
                        <div class="kt-input-icon">
                            <input-switch name="speech_to_speech" :value="item.aws_enable_speech_to_speech" id="enableSpeechToSpeech" class="custom-control-input" @change="updateAwsEnableSpeechToSpeech" :styleDisable="this.aws_interprefy_disabled" />
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label>Enable "Speech to Text"</label>
                        <div class="kt-input-icon">
                            <input-switch name="speech_to_text" :value="item.aws_enable_speech_to_text" id="enableSpeechToText" class="custom-control-input" @change="updateAwsEnableSpeechToText"  :styleDisable="this.aws_interprefy_disabled"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label>Enable "Chat" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx" class="custom-control-input" @change="updateChat" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div    :id ="'survey-' +changingVariable" class="tab-pane">
            <surveyIndex :agenda_id="item._id" surveyNameFile="LivePresentationsSurvey" ></surveyIndex>
        </div>

        <div class="card-body text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm">Save</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
        </div>
    </div>
    <FlashMessage group="live_presentation_edit" />
</div>
</template>

<script>
const maxVideoFileSize = 524288000; // Max file size

import {
    mapGetters,
    mapActions
} from 'vuex';
import {
    upload
} from "@/services/FileUploader";
import {
    v4 as uuidv4
} from 'uuid';
import _ from "lodash";
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';
import moment from 'moment';

import {
    gettingErrorMessage
} from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'

import surveyIndex from "@/components/cruds/Survey/Index";
export default {
    name: 'LivePresentationEditForm',
    data() {
        return {
            changingVariable: 0,
            agenda_id : this.event._id,
            moduleId: ObjectID(),
            dialog: false,
            translation: [],
            languageKey: null,
            stream_disabled: true,
            restric_user_disabled: true,
            interprefy_disabled: true,
            aws_interprefy_disabled: true,
            chat_disabled: true,
            salutation: ['Mr', 'Ms', 'Mrs'],
            selected_salutation: null,
            exhibitorsList: [],
            selectedLanguage: null,

            questionList: true,
            setIsSurveyEdit: false,

        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            item: {

                translations: {
                    required
                },

                speaker: {
                    required,
                },
                start_time: {
                    required,
                },
                end_time: {
                    required,
                }
            }
        };
    },

    props: {
        event: {
            type: Object,
            default () {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                }
            }
        },
        eventDetail:null,
        languages: {
            type: Array,
        }
    },
    watch: {

        'newSpeaker.salutation': function (item) {

            if (item !== undefined) {
                this.selected_salutation = item;
            }
        },
        'exhibitors': function (items) {
            this.exhibitorsList = _.map(items, (item) => {
                const translate = _.get(_.head(item.translations), 'name');
                return {
                    title: translate,
                    //_id: item._id,
                    id: item._id
                }
            })
        },

    },
    components: {
        InputSwitch,
        surveyIndex
  },
    computed: {
        ...mapGetters('LivePresentationsSingle', ['error_translation_tab','error_general_tab','item', 'loading', 'newSpeaker', 'createSpeakerBtnTxt', 'newEmailExists', 
            'eventID', 'createDialog', 'listExhibitors',     'selectedLanguageKey',
            'selectedLanguageTitle',
            'selectedLanguageDescirption',
      ]),
        ...mapGetters('LivePresentationsIndex', ['speakers', 'event_timezone']),
        ...mapGetters('ExhibitorsIndex', {
            exhibitors: "all"
        }),

        
    },
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.live_presentation_edit;
    },
    created() {
        this.setSpeakerEventId(this.event.event_id);
     //   let start_time = moment.utc(this.item.start_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
     //   this.setStartTime(start_time);
     //   let end_time = moment.utc(this.item.end_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
     //   this.setEndTime(end_time);
    },
    mounted() {
        this.setEventTd(this.event.event_id);
        this.fetchData(this.event._id);
        this.updateTimeZone();
    },
    methods: {
       
        ...mapActions('LivePresentationsIndex', {
            fetchAllData: 'fetchData'
        }),
        ...mapActions('LivePresentationsSingle', [
            'storeSpeakerData',
            'updateData',
            'fetchData',
            'checkEmailExists',
            'resetState',
            'setTranslations',
            'setEventTd',
            'setSpeaker',
            'setSpeakerSalutation',
            'setSpeakerEmail',
            'setSpeakerFirstName',
            'setSpeakerLastName',
            'setSpeakerEventId',
            'setSpeakerCretedBy',
            'setExhibitor',
            'setIsRestricted',
            'setMinutes',
            'setQaType',
            'setIsRating',
            'setIsScreenSharing',
            'setIsLiveStreaming',
            'setStreamUrl',
            'setScheduleStatus',
            'setIsBackgroundPicture',
            'setVideo',
            'setDocument',
            'setScreenLogo',
            'setSesssionLogo',
            'setBackgroundPicture',
            'setSpeakerPicutre',
            'setPillarLeft1Logo1',
            'setPillarLeft1Logo2',
            'setPillarLeft1Logo3',
            'setPillarLeft2Logo1',
            'setPillarLeft2Logo2',
            'setPillarLeft2Logo3',
            'setPillarRight1Logo1',
            'setPillarRight1Logo2',
            'setPillarRight1Logo3',
            'setPillarRight2Logo1',
            'setPillarRight2Logo2',
            'setPillarRight2Logo3',
            'setBackgroundColor',
            'setStageColor1',
            'setStageColor2',
            'setBackgroundStripesColor',
            'setLeft1PillarHeaderColor',
            'setLeft1PillarBottomColor',
            'setLeft2PillarColor',
            'setLeft2PillarColorBottom',
            'setRight1PillarColor',
            'setRight1PillarColorBottom',
            'setRight2PillarColor',
            'setRight2PillarColorBottom',
            'setInterprefy',
            'setInterprefyToken',
            'setAwsInterpretation',
            'setAwsEnableSpeechToSpeech',
            'setAwsEnableSpeechToText',
            'setChat',
            'setStartTime',
            'setEndTime',
            'setLocation',
            'setTimeZone',
            'setErrorTranslationTab',
            'setErrorGeneralTab',
            'setLangKey',
            'setLangTitle',
            'setLangDescirption',
            'setBackgroundColorBottom',
            'setDocumentsSlides'
     
       
        ]),
        reRenderComponent() {
          this.changingVariable += 1
        },
        getSelectedExhibitor(listExhibitors, exhibitorId) {
            const getSelectedExhibitor = _.find(listExhibitors, i => i.id === exhibitorId);
            return _.get(getSelectedExhibitor, 'title', '');
        },
        fileLoad(e) {
            const file = e.target.files[0];
            const id = uuidv4();
            upload(file, id, "users", null, file.type)
                .then((resp) => {
                    console.log(resp)

                })
                .catch((e) => {
                    console.error(e)
                    this.$flashMessage.show({
                        group: 'live_presentation_edit',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong.'
                    });
                })
        },
        validate_file(fileInfo) {
            // console.log(fileInfo);
            if (_.has(fileInfo, 'key'))
                return fileInfo;
            return null;
        },
        updateSpeaker(value) {
            this.setSpeaker(value.id);
        },
        async submitSpeakerForm() {
            this.storeSpeakerData()
                .then(async () => {
                    this.$flashMessage
                        .show({
                            group: 'live_presentation_edit',
                            type: 'success',
                            title: 'Success',
                            text: 'Speaker added successfully.',
                        });
                    await this.fetchAllData(this.eventID);
                    //this.resetState();
                    this.dialog = false;
                })
                .catch((error) => {
                    console.log("submitSpeakerForm", error);
                    let html = gettingErrorMessage(error);
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'live_presentation_edit',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }
                })
        },
        getSpeakerId(speaker) {
            return _.has(speaker, '_id') ? speaker._id : speaker;
        },
        async submitForm() {
            const isFormCorrect = await this.v$.$validate();
          let validateTranlation = await this.checkLanguageValidation();
          this.error_general_tab = false;
          this.error_translation_tab = false;
          this.error_theme_tab = false;
          this.error_interpretation_tab = false;
          this.setErrorGeneralTab(false)
          this.setErrorTranslationTab(false);

          let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "speaker"){
                generalError.push("<li>Speaker is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }

            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
                if (!_.isEmpty(validateTranlation)) {
           
                  this.error_translation_tab = true;
                  this.setErrorTranslationTab(true);
                  this.$flashMessage.show({
                      group: 'live_presentation_edit',
                      type: 'error',
                    //  time:1000,
                      title: 'Failed',
                      html: `<ul>${validateTranlation.join("")}</ul>`
                  });
                return ;
              }          
            } 
            if (!isFormCorrect ) {
                
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'live_presentation_edit',
                        type    : 'error',
                      //  time    : 1000,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }

            this.updateData()
                .then(async () => {
                    this.$flashMessage.show({
                        group   : 'lp_index',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1200,
                        html    : '<ul><li>Live Presentations has been updated successfully</li></ul>'

                    });

                    await this.fetchAllData(this.eventID);
                    this.resetState();
                    this.dialog = false;
                })
                .catch((error) => {
                    console.error("Error update live presentations", error)
                    let html = gettingErrorMessage(error);

                    this.$flashMessage.show({
                        group: 'live_presentation_edit',
                        type: 'error',
                        title: 'Error',
                        text: html || 'Something went wrong.',
                        html: html
                    });
                })
        },

        updateLaguage(value) {

this.err_translations = null;
this.setLangKey(value);
this.languageKey = value;
this.setLangTitle(null);
this.setLangDescirption(null);

if (_.isUndefined(_.find(this.item.translations, {
  'key': value,
}))) {
  let obj = {
    "key": `${value}`,
    "title": null,
    "description": null
  }
  this.item.translations.push(obj);
} else {

  let selectedLang = _.find(this.item.translations, {
    'key': this.selectedLanguageKey,
  })
  if (!_.isUndefined(selectedLang)) {
    this.setLangTitle(selectedLang.title);
    this.setLangDescirption(selectedLang.description);
  }
}

},
updateLanguageTitle(e) {
this.err_translations = null;
if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
  this.setTranslations({
    key: this.selectedLanguageKey,
    update_key: 'title',
    update_value: e.target.value
  });

}
this.setLangTitle(e.target.value);
},
updateLanguageDescription(e) {
if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
  this.setTranslations({
    key: this.selectedLanguageKey,
    update_key: 'description',
    update_value: e.target.value
  });
}
this.setLangDescirption(e.target.value);
},
        updateNewSpeakerEmail(e) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                console.log("Email---", e.target.value, e);
                this.setSpeakerEmail(e.target.value);
                this.setSpeakerEventId(this.eventID);
                this.setSpeakerCretedBy("63da6ecb083ba9aebc3b35f5");
                this.checkEmailExists()
                    .then()
                    .catch((error) => {
                        console.error(error)
                        this.$flashMessage.show({
                            group: 'live_presentation_edit',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong.'
                        });
                    })
            } else {
                this.setSpeakerFirstName(null);
                this.setSpeakerLastName(null);
            }
        },
        updateNewSpeakerSalutation(value) {
            this.setSpeakerSalutation(value);
        },
        updateNewSpeakerFirstName(e) {
            this.setSpeakerFirstName(e.target.value);
        },
        updateNewSpeakerLastName(e) {
            this.setSpeakerLastName(e.target.value);
        },
        updateExhibitor(value) {
            this.setExhibitor(value.id);
        },
        UpdateIsRestricted(e) {
            this.restric_user_disabled = true;
            if (e.target.checked) {
                this.restric_user_disabled = false;
            }

            this.setIsRestricted(e.target.checked);
        },
        UpdateQaType(e) {
            this.setQaType(e.target.checked);
        },
        updateLocation(e) {
            console.log("e.target.value", e.target.value)
            this.setLocation(e.target.value)
        },
        UpdateIsRating(e) {
            this.setIsRating(e.target.checked);
        },
        UpdateIsScreenSharing(e) {
            this.setIsScreenSharing(e.target.checked);
        },
        UpdateIsLiveStreaming(e) {
            this.stream_disabled = true;
            if (e.target.checked) {
                this.stream_disabled = false;
            }
            this.setIsLiveStreaming(e.target.checked);
        },
        UpdateScheduleStatus(e) {
            this.setScheduleStatus(e.target.checked);
        },
        UpdateIsBackgroundPicture(e) {
            this.setIsBackgroundPicture(e.target.checked);
        },
        updateVideo(info, data) {
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
       

            const key = _.get(this.item.video, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
            }
            this.setVideo({
                old_key     :  data.data.key?data.data.key:data.data.Key,
                temp_key    : info.id,
                key         : data.data.key?data.data.key:data.data.Key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : info.user.first_name + ' ' + info.user.last_name,
                uploadedAt  : '2023.01.01',
            });
        },
        removeVideo(e) {
            this.setVideo(null);
        },
        updateDocument(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.documents, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setDocument({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeDocument(e) {
            this.setDocument(null);
            this.setDocumentsSlides([]);
        },
        updateScreenLogo(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.screen_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setScreenLogo({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeScreenLogo(e) {
            this.setScreenLogo(null);
        },
        updateSesssionLogo(info, data) {
            // console.log(data);
            if (!_.has(data, 'data.key')) {

                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.sesssion_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setSesssionLogo({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeSesssionLogo(e) {
            this.setSesssionLogo(null);
        },
        updateBackgroundPicture(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            console.log("i**************nfo", info)
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.background_picture, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setBackgroundPicture({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeBackgroundPicture(e) {
            this.setBackgroundPicture(null);
        },
        updateSpeakerPicutre(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.speaker_picutre, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setSpeakerPicutre({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeSpeakerPicutre(e) {
            this.setSpeakerPicutre(null);
        },
        updatePillarLeft1Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            console.log("i**************nfo", info)
            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft1Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo1(e) {
            this.setPillarLeft1Logo1(null);
        },
        updatePillarLeft1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft1Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo2(e) {
            this.setPillarLeft1Logo2(null);
        },
        updatePillarLeft1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft1Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft1Logo3(e) {
            this.setPillarLeft1Logo3(null);
        },
        updatePillarLeft2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft2Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo1(e) {
            this.setPillarLeft2Logo1(null);
        },
        updatePillarLeft2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft2Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo2(e) {
            this.setPillarLeft2Logo2(null);
        },
        updatePillarLeft2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_left2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarLeft2Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarLeft2Logo3(e) {
            this.setPillarLeft2Logo3(null);
        },
        updatePillarRight1Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight1Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo1(e) {
            this.setPillarRight1Logo1(null);
        },
        updatePillarRight1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight1Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo2(e) {
            this.setPillarRight1Logo2(null);
        },
        updatePillarRight1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight1Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight1Logo3(e) {
            this.setPillarRight1Logo3(null);
        },
        updatePillarRight2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight2Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight2Logo1(e) {
            this.setPillarRight2Logo1(null);
        },
        updatePillarRight2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight2Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight2Logo2(e) {
            this.setPillarRight2Logo2(null);
        },
        updatePillarRight2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.pillar_right2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPillarRight2Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });

            this.$forceUpdate();
        },
        removePillarRight2Logo3(e) {
            this.setPillarRight2Logo3(null);
        },
        updateStreamUrl(e) {
            this.setStreamUrl(e.target.value);
        },
        updateMinutes(e) {
            this.setMinutes(e.target.value);
        },
        updateBackgroundColor(e) {
            this.setBackgroundColor(e.target.value);
        },
        updateBackgroundStripesColor(e) {
            this.setBackgroundStripesColor(e.target.value);
        },
        updateStageColor1(e) {
            this.setStageColor1(e.target.value);
        },
        updateStageColor2(e) {
            this.setStageColor2(e.target.value);
        },
        updateBackgroundColorBottom(e) {
            this.setBackgroundColorBottom(e.target.value);
        },
        updateLeft1PillarHeaderColor(e) {
            this.setLeft1PillarHeaderColor(e.target.value);
        },
        updateleft1PillarBottomColor(e) {
            this.setLeft1PillarBottomColor(e.target.value);
        },
        updateLeft2PillarColor(e) {
            this.setLeft2PillarColor(e.target.value);
        },
        updateLeft2PillarColorBottom(e) {
            this.setLeft2PillarColorBottom(e.target.value);
        },
        updateRight1PillarColor(e) {
            this.setRight1PillarColor(e.target.value);
        },
        updateRight1PillarColorBottom(e) {
            this.setRight1PillarColorBottom(e.target.value);
        },
        updateRight2PillarColor(e) {
            this.setRight2PillarColor(e.target.value);
        },
        updateRight2PillarColorBottom(e) {
            this.setRight2PillarColorBottom(e.target.value);
        },
        updateInterprefy(e) {
            this.interprefy_disabled = true;
            if (e.target.checked) {
                this.interprefy_disabled = false;
                this.setAwsInterpretation(false)
                this.aws_interprefy_disabled =true;
                this.setAwsEnableSpeechToSpeech(false);
                this.setAwsEnableSpeechToText(false);
            }
            this.setInterprefy(e.target.checked);
        },
        updateInterprefyToken(e) {
            this.setInterprefyToken(e.target.value);
        },
        updateAwsInterpretation(e) {
            this.aws_interprefy_disabled = true;
            if (e.target.checked) {
                this.aws_interprefy_disabled = false;
            }
            if(e.target.checked){
              this.setInterprefy(false);
              this.interprefy_disabled= true
            }
            //interprefy
            this.setAwsInterpretation(e.target.checked);
        },
        updateAwsEnableSpeechToSpeech(e) {
            this.setAwsEnableSpeechToSpeech(e.target.checked);
        },
        updateAwsEnableSpeechToText(e) {
            this.setAwsEnableSpeechToText(e.target.checked);
        },
        updateChat(e) {
            this.setChat(e.target.checked);
        },
        updateStartTime(e) {
            console.log("updateStartTime", e.target.value);

            this.setStartTime(e.target.value);
        },
        updateEndTime(e) {
            this.setEndTime(e.target.value);
        },
        cancelClick() {
            this.resetState()
        },
        errCb(e) {
            this.$flashMessage.show({
                group: 'live_presentation_edit',
                type: 'error',
                title: 'Failed',
                time:   1000,
                html: "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
            });

        },
        updateTimeZone() {
            console.log("Call");
            this.setTimeZone(this.event_timezone)
        },
        async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },
        getLanguageTitle() {
            return this.selectedLanguageTitle;
        },
        getLanguageDescription() {
            return this.selectedLanguageDescirption;
        },
        getDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
            //return moment.utc(datetime).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
     
        },
        getEventDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm');
        },
    }

}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
    border-bottom: 0px solid #ccc;
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }
}

.setting_wrapper {
    float: right;

    a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;
    }
}

.toggle label {
    color: #000 !important;
}

.drop-files {
    margin-top: 10px;
}

.newspeaker {
    margin-top: 20px;
}

.newspeaker button {
    width: 100%;
}

.add_spk_btn {
    background-color: #0088CC;
    color: #fff;
}

.mini_txt {
    font-size: 0.8em;
    color: #585252;
    font-style: italic;
    margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
    border-radius: 15px;
    padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
    border-left-color: #0088CC;
}

.orangebtn {
    background-color: orange;
}

.wrapp {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.dark {
    --popper-theme-text-color: #ffffff;
    --popper-theme-padding: 15px;
    --popper-theme-background-color: #333333;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img {
    position: absolute;
    top: -53px;
    left: 50px;
    width: 300px;
    height: auto;
}

.surveytitlehd {
    background: #0088CC;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.surveytitlehd label {
    color: #fff !important;
    font-weight: bold
}

.surveytitlehd span {
    margin-left: 20px;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}
</style>
